import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getPAymentTypePDVAsync = createAsyncThunk(
  "get/payment-type-pdv",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.paymentTypesPdv +
          `?paginate=false&store_id=${storeActivedId}`,
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getPaymentMechanismsAsync = createAsyncThunk(
  "get/payment-mechanisms",
  async (_, thunkAPI) => {
    try {
      const res = await api.get(globals.api.paymentMechanisms);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getBrPaymentTypesAsync = createAsyncThunk(
  "get/br-payment-types",
  async (_, thunkAPI) => {
    try {
      const res = await api.get(globals.api.brPaymentTypes);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getBrPaymentTypesProvidersAsync = createAsyncThunk(
  "get/br-payment-types-providers",
  async (_, thunkAPI) => {
    try {
      const res = await api.get(globals.api.brPaymentTypesProviders);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createPAymentTypePDVAsync = createAsyncThunk(
  "create/payment-type-pdv",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.paymentTypesPdv, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updatePAymentTypePDVAsync = createAsyncThunk(
  "update/payment-type-pdv",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.paymentTypesPdv + `/${data.uuid}`,
        data,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deletePAymentTypePDVAsync = createAsyncThunk(
  "delete/payment-type-pdv",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.paymentTypesPdv + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
