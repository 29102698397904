import { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ProdutoContext } from "../../../../context/product";
import styles from "../FormProduto/styles.module.scss";
import GetEndPoint from "../../../../Services/GetEndPoint";
import ReactSelect from "../../../data/reactSelect";
import OptionsReactSelect from "../../../../interfaces/OptionsReactSelect";
import Layout from "../../../data/Layout";
import { useAppSelector } from "../../../../redux/store";
import { SiUnity } from "react-icons/si";
import ModalUnit from "../FormProduto/ModalUnit";
import * as S from "../styled";
import ReactSelectFornecedor from "../../../data/reactSelectFornecedor";

export default function UnidadeOperacao() {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const [show, setShow] = useState<boolean>(false);

  const {
    unit,
    setUnit,
    typeOper,
    setTypeOper,
    validUnit,
   
  } = useContext(ProdutoContext);

  const [typeProductPurpose, setProductPurpose] = useState<
    OptionsReactSelect[]
  >([]);
  const [measureUnitsList, setMeasureUnitList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    GetEndPoint.productPurpose(setProductPurpose, storeMe?.storeActive?.id);
    GetEndPoint.productMeasureUnits(
      setMeasureUnitList,
      storeMe?.storeActive?.id,
    );
    setTypeOper(1);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!unit) {
      setUnit(
        measureUnitsList?.find((item: any) => item?.label === "Unidade")?.value,
      );
    }
  }, [measureUnitsList]);

  return (
    <>
      <ModalUnit
        show={show}
        setShow={setShow}
        setLoading={setLoading}
        setMeasureUnitList={setMeasureUnitList}
      />
      <Layout title="Unidade e Operação">
        <S.Wrapper>
          <ReactSelectFornecedor
            label="Uni. Médida"
            isClearable
            options={measureUnitsList}
            state={unit}
            setState={setUnit}
            placeholder="Unidade de medida principal"
            isInvalid={validUnit}
            setShow={setShow}
            icon={<SiUnity />}
            isLoading={loading}
          />

          <ReactSelect
            label="Tip. operação"
            isClearable
            options={typeProductPurpose}
            state={typeOper}
            setState={setTypeOper}
            placeholder="Tipo de operação"
            isLoading={loading}
          />
        </S.Wrapper>
      </Layout>
    </>
  );
}
