import { Button, Col, Modal, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../../../redux/store";

import { notify, notifyError } from "../../../../../notify/notify-component";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../../reactHookForm/SelectHook";
import { CheckboxHook } from "../../../../../reactHookForm/inputCheckbox";
import { IPaymentEntry } from "../../../../../../redux/features/paymentType/interface";
import { IChartAccount } from "../../../../../../redux/features/chartAccount/interface";
import { IObj } from "../typeOptionsGroup/obj";
import { paymantSchema } from "../../../../../../schemas/paymantSchema";
import { chartAccountSchema } from "../../../../../../redux/features/chartAccount/schema";
import {
  createChartAccountsync,
  getChartAccountAsync,
  updateChartAccountsync,
} from "../../../../../../redux/features/chartAccount/services";
import * as S from "../../../styled";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setListPDV: any;
  listPDV: any[];
  listEditeItem: IChartAccount | undefined;
  setListEditeItem: Dispatch<SetStateAction<IChartAccount | undefined>>;
  setKeyActived: any;
}

type FormData = Yup.InferType<typeof chartAccountSchema>;

interface IInput {
  id: number;
  uuid: string;
  store_id: number;
  parent_id: any;
  description: string;
  entry_type: number;
  is_active: boolean;
  created_at: string;
  updated_at: string;
}

export default function ModalRevenue({
  show,
  setShow,
  setLoading,
  setListPDV,
  listPDV,
  listEditeItem,
  setListEditeItem,
  setKeyActived,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const { revenue } = useAppSelector(
    ({ chartAccountReducer }) => chartAccountReducer.value,
  );

  const objChartAccount = revenue.map((item: IChartAccount) => ({
    label: item.description,
    value: item.id,
  }));

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(chartAccountSchema),
  });

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const onSubmit: SubmitHandler<IInput> = async (data: IInput) => {
    setLoading(true);
    const newData = {
      ...data,
      store_id: storeMe?.storeActive?.id,
      entry_type: 1,
      parent_id: data?.parent_id?.value,
    };

    if (listEditeItem) {
      const { parent_id, ...listEditeItemUp } = listEditeItem;
      const editData = {
        ...listEditeItemUp,
        ...newData,
        ...(parent_id !== 0 && { parent_id: parent_id }),
      };
      dispatch(updateChartAccountsync(editData)).then((result: any) => {
        if (updateChartAccountsync.fulfilled.match(result)) {
          dispatch(getChartAccountAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });

      resetItens();
      setShow(false);
      setLoading(false);
      setKeyActived("revenue");
      return;
    }

    dispatch(createChartAccountsync(newData)).then((result: any) => {
      if (createChartAccountsync.fulfilled.match(result)) {
        dispatch(getChartAccountAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    setKeyActived("revenue");
    setLoading(false);
    resetItens();
    setShow(false);
  };

  const inputError = [errors];

  const resetItens = () => {
    setValue("parent_id", "");
    setValue("description", "");
    setValue("is_active", true);
    reset({});
    setListEditeItem(undefined);
  };

  useEffect(() => {
    if (listEditeItem && show) {
      setValue("description", listEditeItem?.description);
      setValue(
        "parent_id",
        objChartAccount.find(
          (item: any) => item.value === listEditeItem?.parent_id,
        ),
      );
      setValue("is_active", listEditeItem?.is_active);
    } else {
      resetItens();
      setValue("is_active", true);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{listEditeItem ? "Editar " : "Adicionar "} Plano de conta</h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <p style={{ maxWidth: "25rem", color: "gray" }}>
              Plano de contas das receitas são utilizadas para organizar seus
              recebimentos em alguns relatórios financeiros, e o ajudam a
              analisar quais categorias geram maior lucro para sua empresa.
            </p>
          </Row>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Tipo"
              name="entry_type"
              defaultValue="Receita"
              type="text"
              disabled={true}
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="Plano"
              name="parent_id"
              placeholder="Plano de Contas"
              isClearable={true}
              options={objChartAccount}
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Descrição"
              name="description"
              placeholder="Descrição"
              type="text"
            />
          </S.Wrapper>

          <CheckboxHook
            register={register}
            errors={inputError}
            label="Ativo"
            name="is_active"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
