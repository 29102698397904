import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISelfServiceCategories, ISelfServiceProduct, ISelfServiceResponseProduct } from './interface';
import { getCategoriesAsync, getProductByCategoryAsync } from './services';

interface InitialState {
  value: ISelfServiceState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface ISelfServiceState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  products: ISelfServiceProduct[];
  categories: ISelfServiceCategories[]
  perPage: number;
  totalItems: number;
  page: number;
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    products: [],
    categories: [],
    stateForm: { state: "default" },
    perPage: 15,
    totalItems: 0,
    page: 1,
  } as ISelfServiceState,
};

export const selfServiceState = createSlice({
  name: "self-service",
  initialState,
  reducers: {
    setStateForm: (state: InitialState, action) => {
      state.value.stateForm = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getProductByCategoryAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getProductByCategoryAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ISelfServiceResponseProduct>) => {
        state.value.loading = false;
        state.value.products = action.payload?.data;
        state.value.perPage = action.payload.meta?.per_page
        state.value.totalItems = action.payload.meta?.total;
        state.value.page = action.payload.meta?.current_page;
      },
    );
    builder.addCase(
      getProductByCategoryAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );

    builder.addCase(getCategoriesAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCategoriesAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ISelfServiceCategories[]>) => {
        state.value.loading = false;
        state.value.categories = action.payload;
      },
    );
    builder.addCase(
      getCategoriesAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );
  
  },
});

export const { setStateForm } = selfServiceState.actions;

export default selfServiceState.reducer;
