import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILocalStock } from "./interface";
import { getLocalStockAsync } from "./services";

interface InitialState {
  value: LocalStockState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface LocalStockState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  localStock: ILocalStock[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    localStock: [],
    stateForm: { state: "default" },
  } as LocalStockState,
};

export const localStock = createSlice({
  name: "localStock",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getLocalStockAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getLocalStockAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ILocalStock[]>) => {
        state.value.loading = false;
        state.value.localStock = action.payload;
      },
    );
    builder.addCase(
      getLocalStockAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );
  },
});

export const {} = localStock.actions;

export default localStock.reducer;
