import { Button, Col, Modal, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as S from "./styled";
import { useAppSelector } from "../../../../../redux/store";
import tableStyleDefaultBolder from "../../../../../Services/tableStyleDefaultBolder";
import DataTable from "react-data-table-component";
import api from "../../../../../Services/api";
import Globals from "../../../../../Globals.json";
import CustomLoader from "../../../../data/customLoader";
import { IoIosAddCircle } from "react-icons/io";
import { paginationOptions } from "../../../../data/PagineteInfo";
import { notify, notifyError } from "../../../../notify/notify-component";
import { FaCopy } from "react-icons/fa";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setAssociatedComplement: any;
  associatedComplement: any;
}

export default function ModalCopy({
  show, setShow, associatedComplement, setAssociatedComplement,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const [productsList, setProductsList] = useState<Array<any>>([]);

  const columns: any = [
    {
      name: "Código",
      sortable: true,
      width: "100px",
      center: true,
      selector: (row: any) => row.code,
    },
    {
      name: "Produto",
      minWidth: "190px",
      sortable: true,
      selector: (row: any) => row.sale_name?.toLowerCase(),
    },
    {
      name: "Copiar",
      cell: (row: any) => (
        <div className="actions">
          <FaCopy
            size={20}
            data-toggle="tooltip"
            data-placement="top"
            title="Adicionar"
            className="action"
            onClick={() => handleAdd(row.relationships.complements)}
            style={{ marginRight: 16 }}
            color="#3C7EDF"
          />
        </div>
      ),
      button: true,
    },
  ];

  const handleClose = () => {
    setShow(false);
  };

  const getData = () => {
    const uri = `?paginate=false&append=stock_qty&orderBy=created_at&order=desc&
      store_id=${storeMe?.storeActive?.id}&filter[has_associated]=true`;
    api
      .get(Globals.api.products + `${uri}`)
      .then((response) => {
        const data = response.data;
        setProductsList(data.data);
      })
      .catch((error: any) => {
        notifyError(error);
      });
  };

  const handleAdd = (complements: any) => {
    if (associatedComplement?.length > 0) {
      for (let index = 0; index < complements?.length; index++) {
        const alreadyExists = associatedComplement?.find(
          (item: any) => item?.id === complements[index]?.id,
        );

        if (alreadyExists) {
          return;
        } else {
          setAssociatedComplement((prevent: any) => [
            ...prevent,
            complements[index],
          ]);
          handleClose();
        }
      }
    } else {
      setAssociatedComplement(complements);
      handleClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Copiar associado do produto</h3>
        </Modal.Title>
      </Modal.Header>
      <div>
        <Modal.Body>
          <DataTable
            columns={columns}
            data={
              productsList.filter(
                (item: any) => item?.relationships.associeted?.length > 0,
              ) || []
            }
            striped
            pagination
            paginationDefaultPage={1}
            paginationPerPage={10}
            customStyles={tableStyleDefaultBolder}
            paginationComponentOptions={paginationOptions}
            progressComponent={<CustomLoader />}
            noDataComponent="Nenhuma informação foi encontrada."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
