// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContact {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.rowContatc {
  display: flex;
  height: 25px;
}
.rowContatc div {
  width: 300px;
  border: 2px solid rgba(0, 0, 0, 0.144);
}

.buttonTables button {
  background-color: inherit;
  border: none;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  width: 30px;
}

.container-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.rowInputs {
  padding: 5px;
}

.pre-table-items {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.btnAddToTable {
  margin-top: 15px;
}

.trTable {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #181c32 !important;
}

.buttonOptionsPessoa {
  margin-top: 30px;
  background-color: inherit;
  border: none;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  width: 30px;
}

.btn-flex-end {
  display: flex;
  justify-content: flex-end;
}

button {
  margin-right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Estoque/AjusteEstoque/CadAjusteEstoque/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,sCAAA;AACJ;;AAIE;EACE,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AADJ;;AAKA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;AAFF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,mBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAGA;EACE,2BAAA;EACA,0BAAA;EACA,yBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,yBAAA;AAAF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".tableContact {\n  display: flex;\n  flex-direction: column;\n  padding: 8px 0;\n}\n\n.rowContatc {\n  display: flex;\n  height: 25px;\n\n  div {\n    width: 300px;\n    border: 2px solid rgba(0, 0, 0, 0.144);\n  }\n}\n\n.buttonTables {\n  button {\n    background-color: inherit;\n    border: none;\n    outline: none;\n    margin-right: 10px;\n    cursor: pointer;\n    width: 30px;\n  }\n}\n\n.container-data {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n.rowInputs {\n  padding: 5px;\n}\n\n.pre-table-items {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  flex-direction: row;\n}\n\n.btnAddToTable {\n  margin-top: 15px;\n}\n.trTable {\n  font-weight: 600 !important;\n  font-size: 14px !important;\n  color: #181c32 !important;\n}\n\n.buttonOptionsPessoa {\n  margin-top: 30px;\n  background-color: inherit;\n  border: none;\n  outline: none;\n  margin-right: 10px;\n  cursor: pointer;\n  width: 30px;\n}\n\n.btn-flex-end {\n  display: flex;\n  justify-content: flex-end;\n}\nbutton {\n  margin-right: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
