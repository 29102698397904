import { useState, useContext } from "react";
import { FormMovimentacao } from "../../../Components/Estoque/TipoMovimentacao/CadTipoMovimentacao/tipo-movimentacao";
import ListaTipoMovimentacao from "../../../Components/Estoque/TipoMovimentacao/ListaTipoMovimentacao";
import IEstoque from "../../../Components/Estoque/Interface/IStock";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { ThemeContext } from "../../../context/theme-context";

export default function CadTiposMovimentacoes() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditTipoMovimentacao, setToEditTipoMovimentacao] =
    useState<IEstoque>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: any) => {
    setEditando(false);
    setCad(e);
  };

  const handleClearCancelBtn = (e: any) => {
    setToEditTipoMovimentacao(undefined);
  };

  const handleEdit = (props: IEstoque) => {
    setToEditTipoMovimentacao(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaTipoMovimentacao
            Title="Tipos de Movimentação"
            Path="movements/types"
            OnAddNewCli={(e: any) => {
              handleNew(e);
            }}
            onEditItem={(item: IEstoque) => handleEdit(item)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormMovimentacao
            Title={
              toEditTipoMovimentacao
                ? "Editar Tipo de Movimentação"
                : "Novo Tipo de Movimentação"
            }
            Path="quote"
            OnAddNewMovementType={(eMovementType: any) => {
              handleNew(eMovementType);
            }}
            OnClear={(eMovementType: any) => {
              handleClearCancelBtn(eMovementType);
            }}
            tipoMovimentacao={toEditTipoMovimentacao}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
