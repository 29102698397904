import { useContext, useState } from "react";
import * as S from "./styles";
import { ThemeContext } from "../../context/theme-context";
import Logo from "../images/ERP_Cloud_Preto.png";
import { FaStore, FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useAppSelector } from "../../redux/store";
import { VscListSelection } from "react-icons/vsc";
import { FaCircleUser } from "react-icons/fa6";
import { useHistory } from "react-router";

export function Header() {
  const history = useHistory();
  const { collapsed } = useContext(ThemeContext);
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [menuStore, setMenuStore] = useState<boolean>(false);
  const [menuMessage, setMenuMessage] = useState<boolean>(false);
  const [menuUser, setMenuUser] = useState<boolean>(false);

  function signOut() {
    localStorage.removeItem("@token");
    localStorage.removeItem("user");
    localStorage.removeItem("actvedStore");
    history.replace("/");
  }

  const handleMenuStore = () => {
    setMenuStore(!menuStore);
    setMenuMessage(false);
    setMenuUser(false);
  };

  const handleMenuMessage = () => {
    setMenuMessage(!menuMessage);
    setMenuStore(false);
    setMenuUser(false);
  };

  const handleMenuUser = () => {
    setMenuUser(!menuUser);
    setMenuMessage(false);
    setMenuStore(false);
  };

  const handleMenuFalse = () => {
    setMenuStore(false);
    setMenuMessage(false);
    setMenuUser(false);
  };

  return (
    <S.Display>
      <S.Content
        collapsed={collapsed}
        menuStore={menuStore}
        menuMessage={menuMessage}
        menuUser={menuUser}
      >
        <div className="wrapper">
          <figure>
            <img alt="Logo" src={Logo} />
          </figure>
          <div className="grid-icons">
            <div className="grid-store">
              <span className="company-name">
                {storeMe?.storeActive?.trading_name}
              </span>
              <FaStore
                size={18}
                color="#110F23"
                style={{ cursor: "pointer" }}
                onClick={handleMenuStore}
              />
              {menuStore && (
                <div
                  className="overlay-notification-modal"
                  onClick={handleMenuFalse}
                ></div>
              )}
              <div className="grid-companies">
                <h3>
                  <VscListSelection />
                  Selecione a Loja
                </h3>

                {storeMe?.user?.relationships?.stores?.map((item: any) => (
                  <div key={item?.id}>
                    <span>{item?.trading_name}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="grid-message">
              <div className="numeric">2</div>
              <MdEmail
                size={20}
                color="#110F23"
                style={{ cursor: "pointer" }}
                onClick={handleMenuMessage}
              />
              {menuMessage && (
                <div
                  className="overlay-notification-modal"
                  onClick={handleMenuFalse}
                ></div>
              )}
              <div className="grid-companies">
                <h3>
                  <VscListSelection />
                  Mensagens
                </h3>
                <div>
                  <span>Notificação 1</span>
                </div>
                <div>
                  <span>Notificação 1</span>
                </div>
              </div>
            </div>

            <div className="grid-user">
              <FaUser
                size={18}
                color="#110F23"
                style={{ cursor: "pointer" }}
                onClick={handleMenuUser}
              />
              {menuUser && (
                <div
                  className="overlay-notification-modal"
                  onClick={handleMenuFalse}
                ></div>
              )}
              <div className="grid-companies">
                <h3>
                  <FaCircleUser size={18} />
                  {storeMe.user.name}
                </h3>

                <button onClick={signOut}>Sair</button>
              </div>
            </div>
          </div>
        </div>
      </S.Content>
    </S.Display>
  );
}
