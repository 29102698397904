import { Dispatch, SetStateAction, useEffect } from "react";
import * as S from "./styles";
import InputMask from "react-input-mask";
import { Regex } from "../../../utils/Regex";
import { CheckCNPJ } from "../../../utils";
import { userDataByCNPJ } from "../../../Services/utilitiesService";
import { StateForm } from "../../../interfaces/state-form";
import { Services } from "../../../Services/newService";
import { ObjType } from "../../../utils/objetos/objt";

interface IProps {
  autoComplete?: "on" | "off";
  type?: "string" | "number" | "date" | "text" | "email" | "password";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  as?: any;
  rows?: number;
  name: string;
  mask?: string;
  defaultValue?: any;
  register: any;
  watch: any;
  setValue: any;
  setError: any;
  errors?: any;
  setStateForm: Dispatch<SetStateAction<StateForm>>;
  reset?: any;
  isEditable?: boolean;
  dataEdit?: any;
}

export function InputHookCnpj({
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  size,
  as,
  rows,
  name,
  mask,
  defaultValue,
  register,
  watch,
  setValue,
  setError,
  errors,
  setStateForm,
  reset,
  isEditable,
  dataEdit,
}: IProps) {
  const cnpjValue = watch("cnpj") ?? "";

  async function CallCNPJ(cnpj: any) {
    try {
      if (isEditable) return;
      setStateForm({ state: "loading" });

      const clearCNPJ = Regex.removeCharacters(cnpjValue);

      const existCnpj: any = await Services.checkIfCnpjExists(clearCNPJ);

      if (existCnpj?.data.data.length > 0) {
        setError("cnpj", {
          type: "custom",
          message: "Este CNPJ já esta cadastrado",
        });
        setStateForm({ state: "default" });
        return;
      }

      const { data } = await userDataByCNPJ(cnpj || 0);

      if (data.status === false) {
        setError("cnpj", {
          type: "custom",
          message: "CNPJ não encontrado.",
        });

        setValue("name", "");
        setValue("company_name", "");
        setValue("trading_name", "");
        setValue("email", "");
        setValue("state_registration", "");
        setValue("status", "");
        setValue("isento_ie", "");
        setValue("type_id", "");
        setValue("cep", "");
        setValue("address_number", "");
        setValue("phone", "");
      }

      if (data.status === true) {
        setValue("name", data?.result?.nome);
        setValue("company_name", data?.result?.nome);
        setValue("trading_name", data?.result?.fantasia);
        setValue("email", data?.result?.email);
        setValue("state_registration", data?.result?.numero_de_inscricao);
        setValue("status", data?.result?.situacao === "ATIVA" ? 1 : 2);
        setValue("isento_ie", data?.result?.numero_de_inscricao ? 1 : 2);
        setValue(
          "type_id",
          data?.result?.tipo === "MATRIZ" ? ObjType[0] : ObjType[1],
        );
        setValue("cep", data?.result?.cep);
        setValue("address_number", data?.result?.numero);
        setValue("phone", data?.result?.telefone);
        setError("cnpj", {
          type: "custom",
          message: CheckCNPJ(cnpjValue) ? "" : "",
        });
      } else {
        !CheckCNPJ(cnpjValue) &&
          setError("cnpj", {
            type: "custom",
            message: "CNPJ não encontrado.",
          });
      }
      setStateForm({ state: "default" });
    } catch (err: any) {
      setStateForm({ state: "default" });
      return;
    }
  }

  useEffect(() => {
    if (dataEdit.cnpj === cnpjValue) return;

    const clearCNPJ = Regex.removeCharacters(cnpjValue);

    if (clearCNPJ?.length === 14) {
      !CheckCNPJ(cnpjValue) &&
        setError("cnpj", {
          type: "custom",
          message: "CNPJ inválido.",
        });

      (async () => {
        await CallCNPJ(clearCNPJ);
      })();
    }

    if (clearCNPJ?.length === 0) {
      setValue("name", "");
      setValue("email", "");
      setValue("cep", "");
      setValue("phone", "");
    }
  }, [cnpjValue, setError, setValue]);

  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  return (
    <label>
      <div className="label">{label}</div>
      <S.Input
        errorToDisplay={
          errorToDisplay && errors[0][errorToDisplay]?.message.length > 0
        }
      >
        {mask ? (
          <InputMask
            className="inputMask"
            {...register(name)}
            mask={mask}
            defaultValue={defaultValue}
            name={name}
            type="text"
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            step={step}
            min={min}
            max={max}
          />
        ) : (
          <S.FormInput
            {...register(name)}
            defaultValue={defaultValue}
            name={name}
            as={as}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            maxLength={maxLength}
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            rows={rows}
          />
        )}
        {errorToDisplay && (
          <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
        )}
      </S.Input>
    </label>
  );
}
