import { createSlice } from "@reduxjs/toolkit";
import {IProduct} from "./interface";

interface InitialState {
  value: ProductState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface ProductState {
  loading: boolean;
  fetchError: string;
  products: IProduct[];
  
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    products: [],
 
  } as ProductState,
};

export const product = createSlice({
  name: "product",
  initialState,
  reducers: {
  
  },
});

export const {} = product.actions;

export default product.reducer;
