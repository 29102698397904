import globals from '../Globals.json';
import api from './api';

export const getEmployees = () => {
  return api.get(`${globals.api.people}/type/employee`);
};

export const getReceiver = (store_id) => {
  return api.get(`${globals.api.people}?paginate=false&store_id=${store_id}`);
};

//?paginate=false
