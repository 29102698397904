import { createContext, useState, useEffect } from 'react';
import { bool, boolean } from 'yup';

const themes = {
  dark: {},
  light: {},
};

const initialState = {
  dark: false,
  theme: themes.light,
  toggle: () => {},
  handleCollapsed: () => {},
  collapsed: false,
};
const ThemeContext = createContext(initialState);

function ThemeProvider({ children }) {
  const [dark, setDark] = useState(true); // Default theme is light
  const [collapsed, setCollapsed] = useState(true);

  // On mount, read the preferred theme from the persistence
  useEffect(() => {
    //const isDark = localStorage.getItem('dark') === 'true'
    localStorage.setItem('dark', JSON.stringify(true));
    setDark(true);
  }, [dark]);

  // To toggle between dark and light modes
  const toggle = () => {
    //const isDark = !dark
    const isDark = false;
    localStorage.setItem('dark', JSON.stringify(isDark));
    setDark(isDark);
  };

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const theme = dark ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider
      value={{ theme, dark, toggle, handleCollapsed, collapsed }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
