import { ITransacao } from "../Components/Financeiro/Interface/ITransacao";
import { formaDateToSort, formatDateToSort } from "./formatDateToSort";

export class TableRanking {
  static byDocument(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.document < b.document) {
        return state ? 1 : -1;
      } else if (a.document > b.document) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byDescription(state: any, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      const categoryA = a.description || "";
      const categoryB = b.description || "";
      return state
        ? categoryB.localeCompare(categoryA)
        : categoryA.localeCompare(categoryB);
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byDueDate(state: boolean, setNewTable: any, stateNewTable: any[]) {
    if (state === true) {
      if (stateNewTable.length < 0) return;
      const sortedItems = [...stateNewTable].sort((a: any, b: any) => {
        const aa = formatDateToSort(a.due_date);
        const bb = formatDateToSort(b.due_date);
        return bb < aa ? -1 : bb > aa ? 1 : 0;
      });
      setNewTable([]);
      setNewTable(sortedItems);
    } else {
      if (stateNewTable.length < 0) return;
      const sortedItems = [...stateNewTable].sort((a: any, b: any) => {
        const aa = formatDateToSort(a.due_date);
        const bb = formatDateToSort(b.due_date);
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      setNewTable([]);
      setNewTable(sortedItems);
    }
  }

  static byIssueDate(state: boolean, setNewTable: any, stateNewTable: any[]) {
    if (state === true) {
      if (stateNewTable.length < 0) return;
      const sortedItems = [...stateNewTable].sort((a: any, b: any) => {
        const aa = formatDateToSort(a.created_at);
        const bb = formatDateToSort(b.created_at);
        return bb < aa ? -1 : bb > aa ? 1 : 0;
      });
      setNewTable([]);
      setNewTable(sortedItems);
    } else {
      if (stateNewTable.length < 0) return;
      const sortedItems = [...stateNewTable].sort((a: any, b: any) => {
        const aa = formatDateToSort(a.created_at);
        const bb = formatDateToSort(b.created_at);
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      setNewTable([]);
      setNewTable(sortedItems);
    }
  }

  static byAmmount(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.amount < b.amount) {
        return state ? 1 : -1;
      } else if (a.amount > b.amount) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byStockFinancialAmmount(
    state: boolean,
    setNewTable: any,
    stateNewTable: any[],
  ) {
    const filteredItems = stateNewTable.filter(
      (item) => item.relationships.financial_transaction?.amount !== null,
    );

    const sortedItems = [...filteredItems].sort((a, b) => {
      if (
        a.relationships.financial_transaction?.amount &&
        b.relationships.financial_transaction?.amount
      ) {
        if (
          a.relationships.financial_transaction.amount <
          b.relationships.financial_transaction.amount
        ) {
          return state ? 1 : -1;
        } else if (
          a.relationships.financial_transaction.amount >
          b.relationships.financial_transaction.amount
        ) {
          return state ? -1 : 1;
        }
      }
      return 0;
    });

    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byStockFinancialId(
    state: boolean,
    setNewTable: any,
    stateNewTable: any[],
  ) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.id < b.id) {
        return state ? 1 : -1;
      } else if (a.id > b.id) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byPaidAmount(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.amount_paid < b.amount_paid) {
        return state ? 1 : -1;
      } else if (a.amount_paid > b.amount_paid) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byPendingAmount(
    state: boolean,
    setNewTable: any,
    stateNewTable: any[],
  ) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.pending_amount < b.pending_amount) {
        return state ? 1 : -1;
      } else if (a.pending_amount > b.pending_amount) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byStatus(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const status = state ? 1 : 2;

    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.status === status && b.status === status) {
        return a.id - b.id;
      } else if (a.status === status) {
        return -1;
      } else if (b.status === status) {
        return 1;
      }
      return 0;
    });

    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byRevenue(state: boolean, setNewTable: any, stateNewTable: any[]) {
    if (state === true) {
      if (stateNewTable.length < 0) return;
      const sortedItems = [...stateNewTable].sort((a: any, b: any) => {
        const aa = formatDateToSort(a.relationships?.entries[0]?.created_at);
        const bb = formatDateToSort(b.relationships?.entries[0]?.created_at);
        return bb < aa ? -1 : bb > aa ? 1 : 0;
      });
      setNewTable([]);
      setNewTable(sortedItems);
    } else {
      if (stateNewTable.length < 0) return;
      const sortedItems = [...stateNewTable].sort((a: any, b: any) => {
        const aa = formatDateToSort(a.relationships?.entries[0]?.created_at);
        const bb = formatDateToSort(b.relationships?.entries[0]?.created_at);
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      setNewTable([]);
      setNewTable(sortedItems);
    }
  }

  static byCode(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (parseInt(a.code) < parseInt(b.code)) {
        return state ? 1 : -1;
      } else if (parseInt(a.code) > parseInt(b.code)) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byProduct(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      const categoryA = a.sale_name || "";
      const categoryB = b.sale_name || "";
      return state
        ? categoryB.localeCompare(categoryA)
        : categoryA.localeCompare(categoryB);
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byCost(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.cost_price < b.cost_price) {
        return state ? 1 : -1;
      } else if (a.cost_price > b.cost_price) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static bySale(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      if (a.sale_price < b.sale_price) {
        return state ? 1 : -1;
      } else if (a.sale_price > b.sale_price) {
        return state ? -1 : 1;
      }
      return 0;
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byCategory(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      const categoryA = a.relationships?.category?.name || "";
      const categoryB = b.relationships?.category?.name || "";
      return state
        ? categoryB.localeCompare(categoryA)
        : categoryA.localeCompare(categoryB);
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }

  static byUnit(state: boolean, setNewTable: any, stateNewTable: any[]) {
    const sortedItems = [...stateNewTable].sort((a, b) => {
      const categoryA = a.relationships?.sale_measure_unit?.name || "";
      const categoryB = b.relationships?.sale_measure_unit?.name || "";
      return state
        ? categoryB.localeCompare(categoryA)
        : categoryA.localeCompare(categoryB);
    });
    setNewTable([]);
    setNewTable(sortedItems);
  }
}
