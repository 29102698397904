import * as Yup from "yup";

// Defina o schema básico
export const peopleSchema = Yup.object().shape({
  //cpf_cnpj: Yup.string().required("Por favor, insira um cnpj ou cpf."),
  name: Yup.string().required("Por favor, insira um nome."),
  //email: Yup.string().required("Por favor, insira o email."),
});

// Função de validação dinâmica
