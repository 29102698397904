import { InputHook } from "../../../../reactHookForm/InputHook";
import { Btn } from "../../../../data/Btn";
import DataTable from "react-data-table-component";
import { paginationOptions } from "../../../../data/PagineteInfo";
import LayoutTableNotShadow from "../../../../data/LayoutTableNotShadow";
import { BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { generateId } from "../../../../../Services/generateId";
import React, { SetStateAction, useState } from "react";
import { Services } from "../../../../../Services/newService";
import { notify } from "../../../../notify/notify-component";
import { InputHookCepPeople } from "../../../../reactHookForm/InputHookCepPeople";
import ModalBootstrap from "../../../../data/Modal";
import CustomLoader from "../../../../data/customLoader";
import tableStyleDefaulInternal from "../../../../../Services/tableStyleDefaultInternal";
import * as S from "./styled";

interface IProps {
  register: any;
  errors: any;
  watch: any;
  setValue: any;
  setError: any;
  setStateForm: React.Dispatch<React.SetStateAction<any>>;
  data: any;
  arrayAdress: any[];
  setArrayAdress: React.Dispatch<SetStateAction<any[]>>;
  isEditable: boolean;
}

export function AdressData({
  register,
  errors,
  setError,
  setValue,
  watch,
  setStateForm,
  data,
  arrayAdress,
  setArrayAdress,
  isEditable,
}: IProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uuidAddress, setUuidAddress] = useState<any>();
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const columns = [
    {
      name: "Cep",
      grow: 1,
      selector: (row: any) => row?.address_zip_code,
    },
    {
      name: "Endereço",
      grow: 2,
      selector: (row: any) => row?.address_street,
    },
    {
      name: "Número",
      grow: 1,
      center: true,
      selector: (row: any) => row?.address_number,
    },
    {
      name: "Complemento",
      grow: 2,
      selector: (row: any) => row?.address_complement,
    },
    {
      name: "Bairro",
      grow: 2,
      selector: (row: any) => row?.address_district,
    },
    {
      name: "Cidade",
      grow: 2,
      selector: (row: any) => row?.city,
    },
    {
      name: "Estado",
      grow: 1,
      center: true,
      selector: (row: any) => row?.uf,
    },
    {
      name: "Ação",
      grow: 1,
      center: true,
      cell: (row: any) => (
        <div
          className="actions"
          style={{ display: "flex", gap: "15px", marginLeft: "14px" }}
        >
          <FaEdit
            data-toggle="tooltip"
            data-placement="top"
            title="Editar Endereço"
            className="action"
            onClick={() => handleEdit(row)}
            size={17}
            color="#50cd89"
          />
          <BsTrash
            size={16}
            data-toggle="tooltip"
            data-placement="top"
            title="Deletar Endereço"
            className="action"
            onClick={() => handleModalId(row.id)}
            style={{ marginRight: 15 }}
            color="#0095e8"
          />
        </div>
      ),
      button: true,
    },
  ];

  const handleAdress = () => {
    setIsEdit(false);
    if (!watch("cep"))
      return setError("cep", {
        type: "custom",
        message: "Por favor informe o cep.",
      });
    if (!watch("address_street"))
      return setError("address_street", {
        type: "custom",
        message: "Por favor informe o endereço.",
      });
    if (!watch("address_number"))
      return setError("address_number", {
        type: "custom",
        message: "Por favor informe o número.",
      });
    if (!watch("address_district"))
      return setError("address_district", {
        type: "custom",
        message: "Por favor informe o bairro.",
      });

    const newAdress = {
      id: generateId(),
      address_zip_code: watch("cep"),
      address_street: watch("address_street"),
      address_number: watch("address_number"),
      address_complement: watch("address_complement"),
      address_district: watch("address_district"),
      address_default: false,
      address_neighborhood: watch("address_district"),
      city: watch("city"),
      uf: watch("state"),
      additional_data: "n/a",
    };

    arrayAdress && setArrayAdress([...arrayAdress, newAdress]);
    !arrayAdress && setArrayAdress([newAdress]);

    setValue("cep", "");
    setError("cep", {
      type: "custom",
      message: "",
    });
    setValue("address_street", "");
    setError("address_street", {
      type: "custom",
      message: "",
    });
    setValue("address_number", "");
    setError("address_number", {
      type: "custom",
      message: "",
    });
    setValue("address_complement", "");
    setError("address_complement", {
      type: "custom",
      message: "",
    });
    setValue("address_district", "");
    setError("address_district", {
      type: "custom",
      message: "",
    });
    setValue("city", "");
    setError("city", {
      type: "custom",
      message: "",
    });
    setValue("state", "");
    setError("state", {
      type: "custom",
      message: "",
    });
  };

  const handleEdit = (data: any) => {
    setIsEdit(true);
    setUuidAddress(data?.id);
    setValue("cep", data.address_zip_code);
    setValue("address_street", data.address_street);
    setValue("address_number", data.address_number);
    setValue("address_complement", data.address_complement);
    setValue("address_district", data.address_district);
    setValue("city", data.city);
    setValue("state", data.state);

    const removeAddress = arrayAdress.filter(
      (item: any) => item?.id !== data?.id,
    );

    setArrayAdress([...removeAddress]);
  };

  const handleDelete = async (id: string) => {
    if (isEditable) {
      setStateForm({ state: "loading" });
      const newAddressApi: any = await Services.peopleDeleteAddress(
        data?.uuid,
        id,
        setStateForm,
      );
      setStateForm({ state: "default" });
      notify("Endereço deletado");
      const newAddress = arrayAdress.filter(
        (item: any) => item?.id !== newAddressApi?.data?.data?.id,
      );
      setArrayAdress(newAddress);
    } else {
      const newAddress = arrayAdress.filter((item: any) => item?.id !== id);
      setArrayAdress(newAddress);
    }
  };

  const handleAdressIsEditable = async () => {
    setIsEdit(false);
    if (!watch("cep"))
      return setError("cep", {
        type: "custom",
        message: "Por favor informe o cep.",
      });
    if (!watch("address_street"))
      return setError("address_street", {
        type: "custom",
        message: "Por favor informe o endereço.",
      });
    if (!watch("address_number"))
      return setError("address_number", {
        type: "custom",
        message: "Por favor informe o número.",
      });
    if (!watch("address_district"))
      return setError("address_district", {
        type: "custom",
        message: "Por favor informe o bairro.",
      });

    setStateForm({ state: "loading" });

    const newAdress = {
      id: uuidAddress,
      address_zip_code: watch("cep"),
      address_street: watch("address_street"),
      address_number: watch("address_number"),
      address_complement: watch("address_complement"),
      address_district: watch("address_district"),
      address_default: false,
      address_neighborhood: watch("address_district"),
      city: watch("city"),
      uf: watch("state"),
    };
    if (isEditable && isEdit) {
      const response: any = await Services.peoplePutAddress(
        newAdress,
        data?.uuid,
        setStateForm,
      );
      setArrayAdress([...arrayAdress, response?.data?.data]);
      setStateForm({ state: "default" });
      notify("Endereço atualizado.");
    } else {
      const newAdressApi: any = await Services.addNewAddress(
        data?.uuid,
        newAdress,
        setStateForm,
      );
      arrayAdress && setArrayAdress([...arrayAdress, newAdressApi?.data?.data]);
      setStateForm({ state: "default" });
      notify("Endereço criado.");
    }

    setValue("cep", "");
    setError("cep", {
      type: "custom",
      message: "",
    });
    setValue("address_street", "");
    setError("address_street", {
      type: "custom",
      message: "",
    });
    setValue("address_number", "");
    setError("address_number", {
      type: "custom",
      message: "",
    });
    setValue("address_complement", "");
    setError("address_complement", {
      type: "custom",
      message: "",
    });
    setValue("address_district", "");
    setError("address_district", {
      type: "custom",
      message: "",
    });
    setValue("city", "");
    setError("city", {
      type: "custom",
      message: "",
    });
    setValue("state", "");
    setError("state", {
      type: "custom",
      message: "",
    });
  };

  const handleModalId = (id: string) => {
    setId(id);
    setModalConfirmation(true);
  };

  const handleDeleteAdress = () => {
    setModalConfirmation(false);
    handleDelete(id);
  };

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o endereço?"
        handleAction={handleDeleteAdress}
      />
      <S.Wrapper>
        <div className="min-screen">
          <InputHookCepPeople
            register={register}
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={errors}
            setStateForm={setStateForm}
            label="Cep"
            name="cep"
            type="number"
            mask="99999-999"
            placeholder="Cep"
            dataEdit={data}
            defaultValue={data.cep}
          />
          <InputHook
            register={register}
            errors={errors}
            label="Endereço"
            name="address_street"
            type="text"
            placeholder="Endereço"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Número"
            name="address_number"
            type="text"
            placeholder="Número"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Complemento"
            name="address_complement"
            type="text"
            placeholder="Complemento"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Bairro"
            name="address_district"
            type="text"
            placeholder="Bairro"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Cidade"
            name="city"
            type="text"
            placeholder="Cidade"
            disabled
          />
          <InputHook
            register={register}
            errors={errors}
            label="Estado"
            name="state"
            type="text"
            placeholder="Estado"
            disabled
          />
        </div>

        <div className="max-screen">
          <InputHookCepPeople
            register={register}
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={errors}
            setStateForm={setStateForm}
            label="Cep"
            name="cep"
            type="number"
            mask="99999-999"
            placeholder="Cep"
            dataEdit={data}
            defaultValue={data.cep}
          />
          <InputHook
            register={register}
            errors={errors}
            label="Endereço"
            name="address_street"
            type="text"
            placeholder="Endereço"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Número"
            name="address_number"
            type="text"
            placeholder="Número"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Complemento"
            name="address_complement"
            type="text"
            placeholder="Complemento"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Bairro"
            name="address_district"
            type="text"
            placeholder="Bairro"
          />
          <InputHook
            register={register}
            errors={errors}
            label="Cidade"
            name="city"
            type="text"
            placeholder="Cidade"
            disabled
          />
          <InputHook
            register={register}
            errors={errors}
            label="Estado"
            name="state"
            type="text"
            placeholder="Estado"
            disabled
          />
        </div>
        <div className="btnAdd">
          <Btn
            text={isEdit ? "Atualizar Endereço" : "Adicionar"}
            type="button"
            variante="primary"
            onClick={isEditable ? handleAdressIsEditable : handleAdress}
          />
        </div>
      </S.Wrapper>

      <LayoutTableNotShadow>
        <DataTable
          columns={columns}
          data={arrayAdress || []}
          striped
          paginationPerPage={25}
          customStyles={tableStyleDefaulInternal}
          paginationComponentOptions={paginationOptions}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada"
        />
      </LayoutTableNotShadow>
    </>
  );
}
