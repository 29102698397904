import { useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { ThemeContext } from "../../../context/theme-context";
import { FormCfops } from "../../../Components/Configuracao/Cfops/FormCfops";
import { ListCfops } from "../../../Components/Configuracao/Cfops/ListCfops";

interface ICfops {}

export interface IListCfops {
  title: string;
  onEditItem: (item: ICfops) => void;
  OnAddNewCli: (e: boolean) => void;
}

export interface IFormCfops {
  title: string;
  OnAddNewStore: (e: boolean) => void;
  cfopData?: any;
  editando: boolean;
  selectOptions?: any;
}

export interface ISelectOptions {}

export default function CadCfops() {
  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditPerfil, setToEditPerfil] = useState<{}>({});
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setCad(e);
    setEditando(false);
  };

  const handleEdit = (props: ICfops) => {
    setToEditPerfil(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListCfops
            title="CFOP"
            OnAddNewCli={(e: any) => {
              handleNew(e);
            }}
            onEditItem={(item: ICfops) => handleEdit(item)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormCfops
            title={editando ? "Editar CFOP" : "Novo CFOP"}
            OnAddNewStore={(efinancial: boolean) => {
              handleNew(efinancial);
            }}
            cfopData={toEditPerfil}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
