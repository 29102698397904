import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import ListaOrcamentos from "../../../Components/Estoque/Orcamento/ListaOrcamentos";
import "./styles.scss";
import IEstoque from "../../../Components/Estoque/Interface/IStock";
import { Orcamento } from "../../../Components/Estoque/Orcamento/CadOrcamento/orcamento";
import { ThemeContext } from "../../../context/theme-context";

export default function CadOrcamentos() {
  const { collapsed } = useContext(ThemeContext);
  const [quote, setQuote] = useState<boolean>(false);
  const [toEditOrcamentos, setToEditOrcamentos] = useState<IEstoque>();
  const [editando, setEditando] = useState<boolean>(false);
  const [onlyView, setOnlyView] = useState<boolean>(false);

  const handleNew = (e: any) => {
    setEditando(false);
    setOnlyView(false);
    setQuote(e);
  };

  const handleClearCancelBtn = (e: any) => {
    setToEditOrcamentos(undefined);
  };

  const handleEdit = (props: IEstoque) => {
    setOnlyView(false);
    setToEditOrcamentos(props);
    setQuote(true);
    setEditando(true);
  };

  const handleView = (props: IEstoque) => {
    setToEditOrcamentos(props);
    setQuote(true);
    setEditando(true);
    setOnlyView(true);
  };

  return (
    <>
      {!quote ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaOrcamentos
            Title="Orçamentos"
            Path="quotes"
            OnAddNewQuote={(e: any) => {
              handleNew(e);
            }}
            onEditItem={(item: IEstoque) => handleEdit(item)}
            onViewItem={(item: IEstoque) => handleView(item)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <Orcamento
            Title="Orçamento"
            Path="quote"
            OnAddNewQuote={(eQuote: any) => {
              handleNew(eQuote);
            }}
            OnClear={(eQuote: any) => {
              handleClearCancelBtn(eQuote);
            }}
            orcamento={toEditOrcamentos}
            editando={editando}
            onlyView={onlyView}
          />
        </Container>
      )}
    </>
  );
}
