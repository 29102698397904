import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../Services/api';
import globals from '../../../../Globals.json';

export const getProductByCategoryAsync = createAsyncThunk(
  "self-services-get-product",
  async (path: { activedStoreId: number, filter?: string, page:number }, thunkAPI) => {
    const uri = path?.filter ? `?page=${path?.page}&store_id=${path?.activedStoreId}&filter${path?.filter}` 
      : `?page=${path?.page}&store_id=${path?.activedStoreId}`
    
    try {
      const res = await api.get(
        globals.api.products + uri,
      );
   
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCategoriesAsync = createAsyncThunk(
  "self-services-get-categories",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.categories + `?paginate=false&store_id=${activedStoreId}`,
      );
      
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editeProductSelfServiceAsync = createAsyncThunk(
  "self-services-edit-product",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.products + `/${data?.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

