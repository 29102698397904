// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  margin-top: 1px;
}
.controls {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 30px 0 30px;
}
details {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0.5em 0.5em 0;
}

summary {
  display: flex !important;
  align-items: center;
  border-radius: 5px;
  height: 60px;
  background-color: #ffffff;
  margin: -0.5em -0.5em 0;
  padding: 0.5em 3em;
}
summary i {
  margin-top: -15px;
  margin-left: 10px;
  color: #1e1e2d;
  font-size: 2em;
}

details[open] {
  background-color: #ffffff;
  padding: 0.5em;
}

details[open] summary {
  margin-bottom: 0.5em;
}

summary::marker {
  content: '+';
}

.card {
  margin-bottom: 20px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Cadastro/Produtos/FormProduto/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".row {\n  margin-top: 1px;\n}\n.controls {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px;\n  margin: 0 30px 0 30px;\n}\ndetails {\n  background-color: #ffffff;\n  border-radius: 5px;\n  padding: 0.5em 0.5em 0;\n}\n\nsummary {\n  display: flex !important;\n  align-items: center;\n  border-radius: 5px;\n  height: 60px;\n  background-color: #ffffff;\n  margin: -0.5em -0.5em 0;\n  padding: 0.5em 3em;\n}\nsummary i {\n  margin-top: -15px;\n  margin-left: 10px;\n  color: #1e1e2d;\n  font-size: 2em;\n}\n\ndetails[open] {\n  background-color: #ffffff;\n  padding: 0.5em;\n}\n\ndetails[open] summary {\n  margin-bottom: 0.5em;\n}\n\nsummary::marker {\n  content: '+';\n}\n\n.card {\n  margin-bottom: 20px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
