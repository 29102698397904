// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fundo {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  z-index: 2000;
  justify-content: center;
  align-items: center;
}

.form {
  top: 100px;
}

.spinner {
  width: 4.6rem !important;
  height: 4.6rem !important;
  border: 0.55em solid !important;
  border-right-color: transparent !important;
  justify-content: center !important;
  align-items: center !important;
}

.label {
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,wBAAA;EACA,yBAAA;EACA,+BAAA;EACA,0CAAA;EACA,kCAAA;EACA,8BAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AACF","sourcesContent":[".fundo {\n  width: 100vw;\n  height: 100vh;\n  background: rgb(0, 0, 0, 0.3);\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  display: flex;\n  z-index: 2000;\n  justify-content: center;\n  align-items: center;\n}\n\n.form {\n  top: 100px;\n}\n\n.spinner {\n  width: 4.6rem !important;\n  height: 4.6rem !important;\n  border: 0.55em solid !important;\n  border-right-color: transparent !important;\n  justify-content: center !important;\n  align-items: center !important;\n}\n\n.label {\n  font-size: 1.2rem;\n  font-weight: bold;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
