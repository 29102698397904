import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const createProductAsync = createAsyncThunk(
  "create/product",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.products, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editeProductAsync = createAsyncThunk(
  "put/product",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.products + `/${data?.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

