import * as Yup from "yup";

export const storeSchema = Yup.object().shape({
  cnpj: Yup.string().required("Por favor, informe um cnpj."),
  company_name: Yup.string().nullable().required("Por favor, informe um nome."),
  email: Yup.string().required("Por favor, informe o email."),
  cep: Yup.string().required("Por favor, informe o cep."),
  address_street: Yup.string().required("Por favor, informe o endereço."),
  address_number: Yup.string().required("Por favor, informe o número."),
  address_district: Yup.string().required(
    "Por favor, informe o nome do bairro.",
  ),
  state: Yup.string().nullable().required("Por favor, selecione o estado."),
  city: Yup.string().nullable().required("Por favor, informe a cidade."),
  mobile: Yup.string()
    .nullable()
    .required("Por favor, informe o número do celular."),
  type_id: Yup.mixed().test(
    "is-string",
    "Por favor, selecione o tipo da empresa.",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    },
  ),
});
