import { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { FormAjusteEstoque } from "../../../Components/Estoque/AjusteEstoque/CadAjusteEstoque/ajuste-estoque";
import { ThemeContext } from "../../../context/theme-context";
import IEstoque from "../../../Components/Estoque/Interface/IStock";
import ListaAjusteEstoque from "../../../Components/Estoque/AjusteEstoque/ListaAjusteEstoque";

export interface IListaMovimEstoque {
  title: string;
  OnAddNewCli: (e: boolean) => void;
  onEditItem: (item: IEstoque) => void;
  OnAddNewEstoqueMovimentacao: (e: boolean) => void;
}

export interface IFormMovimentacaoEstoque {
  title: string;
  OnAddNewEstoqueMovimentacao: (e: boolean) => void;
  estoque?: IEstoque;
  editando: boolean;
}

export default function CadAjustes() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditMovimentacao, setToEditMovimentacao] = useState<IEstoque>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setCad(e);
  };

  const handleEdit = (props: IEstoque) => {
    setToEditMovimentacao(props);
    setCad(true);
    setEditando(true);
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaAjusteEstoque
            title="Ajuste de Estoque"
            onEditItem={(item: IEstoque) => handleEdit(item)}
            OnAddNewCli={(e: boolean) => handleNew(e)}
            OnAddNewEstoqueMovimentacao={(e: boolean) => handleNew(e)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormAjusteEstoque
            title={
              toEditMovimentacao
                ? "Editar Ajuste de Estoque"
                : "Novo Ajuste de Estoque"
            }
            OnAddNewEstoqueMovimentacao={(e: boolean) => {
              handleNew(e);
              setToEditMovimentacao(undefined);
            }}
            estoque={toEditMovimentacao}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
