import { useState, useContext } from "react";
import ListaFormaPagamento from "../../../Components/Financeiro/FormaPagamento/ListaFormaPagamento";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import IFormaPagamento from "../../../Components/Financeiro/Interface/IFormaPagamento";
import { ThemeContext } from "../../../context/theme-context";

export default function CadFormaPagamento() {
  const { collapsed } = useContext(ThemeContext);
  useState<IFormaPagamento>();
  const [menuSelected, setMenuSelected] = useState<string>("");

  return (
    <>
      <Container
        className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
      >
        <ListaFormaPagamento
          Title="Forma "
          Path=""
          menuSelected={menuSelected}
          setMenuSelected={setMenuSelected}
        />
      </Container>
    </>
  );
}
