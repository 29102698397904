import { useContext, useRef, useState } from "react";

import * as S from "./styles";
import type { Menu } from "./itemsMenu";
import { Link, useHistory } from "react-router-dom";
import { ThemeContext } from "../../../context/theme-context";
import { ExtratoContext } from "../../Financeiro/ExtratoBancario/context/extrato-bancario-context";
import { MovimentacaoContext } from "../../Financeiro/MovimentacaoBancaria/context/movimentacao-bancario-context";

interface MenuItemProps {
  item: Menu;
}

export function MenuItem({ item }: MenuItemProps) {
  const { collapsed } = useContext(ThemeContext);
  const { handleShowExtrato } = useContext(ExtratoContext);
  const { handleShowMovimentacao } = useContext(MovimentacaoContext);

  const router = useHistory();
  const regex = /^\/dashboard\/([^/]+)/;
  const match: any = router && router?.location?.pathname?.match(regex);
  const pathname = match ? match[1] : "dashboard";
  const refMenuItem = useRef<HTMLDivElement>(null);

  const [subMenu, setSubMenu] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({ top: 0 });

  const handleMouseEnter = () => {
    const boundingRect = refMenuItem.current?.getBoundingClientRect();
    if (boundingRect) {
      setHoverPosition({
        top: boundingRect.top,
      });
    }
    setHover(true);
  };

  return (
    <S.MenuItem
      ref={refMenuItem}
      collapsed={collapsed}
      pathname={pathname === item.titulo.toLowerCase() ? true : false}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setHover(false)}
    >
      {item.url ? (
        <Link
          to={item.url}
          className={`${collapsed ? "drop title border-title" : "menu-open"}`}
        >
          <div className="title-icon">
            {item.icon}
            <span>{item.titulo}</span>
          </div>
        </Link>
      ) : collapsed ? (
        <button
          className={`${
            collapsed ? "drop title border-title menu-hover" : "menu-open"
          }`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="title-icon">
            {item.icon}
            <span>{item.titulo}</span>
          </div>
        </button>
      ) : (
        <button
          className={`${collapsed ? "drop border-title" : "menu-open"}`}
          onClick={() => setSubMenu(!subMenu)}
        >
          <div className="title-icon">
            {item.icon}
            <span>{item.titulo}</span>
          </div>
          {item.titulo && (
            <svg
              id="SVGDoc"
              width="13"
              height="7"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 13 7"
              style={{
                transform: subMenu ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            >
              <g>
                <g>
                  <path
                    d="M11.37588,0.80859v0l1.2833,0c0.11123,0 0.17266,0.09776 0.10459,0.16377l-5.84541,5.72305c-0.04945,0.04863 -0.11277,0.08797 -0.18513,0.11504c-0.07236,0.02707 -0.15185,0.04113 -0.2324,0.04113c-0.08055,0 -0.16004,-0.01407 -0.2324,-0.04113c-0.07236,-0.02707 -0.13568,-0.06641 -0.18513,-0.11504l-5.84544,-5.72305c-0.068,-0.06728 -0.0066,-0.16377 0.1046,-0.16377l1.2833,0c0.0814,0 0.1594,0.0292 0.2092,0.07744l4.66504,4.57032v0l4.6667,-4.57032c0.0498,-0.04824 0.12783,-0.07744 0.20918,-0.07744z"
                    fillOpacity="1"
                  ></path>
                </g>
              </g>
            </svg>
          )}
        </button>
      )}

      {hover && item.subMenu && collapsed && (
        <div
          className="hover-menu"
          style={{ top: hoverPosition.top }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="div-hover" />
          <div className="sidebar-menu">
            {item.subMenu?.map((sub, index) => (
              <div key={index * 20}>
                <span>{sub.subTitle}</span>
                {sub.links?.map((link, linkIndex) =>
                  link.subTitulo === "Extrato bancário" ||
                  link.subTitulo === "Movimentação Bancária" ? (
                    <div
                      key={linkIndex}
                      className="link-modal"
                      onClick={
                        link.subTitulo === "Extrato bancário"
                          ? handleShowExtrato
                          : handleShowMovimentacao
                      }
                    >
                      {link.subTitulo}
                    </div>
                  ) : (
                    <Link key={linkIndex} to={link.url}>
                      {link.subTitulo}
                    </Link>
                  ),
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {subMenu && item.subMenu && !collapsed && (
        <div>
          {item.subMenu.map((sub, index) => (
            <div key={index} className="sub-menu">
              <span>{sub.subTitle}</span>
              {sub.links?.map((link, linkIndex) =>
                link.subTitulo === "Extrato bancário" ||
                link.subTitulo === "Movimentação Bancária" ? (
                  <div
                    className="link-modal"
                    onClick={
                      link.subTitulo === "Extrato bancário"
                        ? handleShowExtrato
                        : handleShowMovimentacao
                    }
                  >
                    {link.subTitulo}
                  </div>
                ) : (
                  <Link key={linkIndex} to={link.url}>
                    {link.subTitulo}
                  </Link>
                ),
              )}
            </div>
          ))}
        </div>
      )}
    </S.MenuItem>
  );
}
