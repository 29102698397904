import { Dispatch, SetStateAction, useEffect } from "react";
import * as S from "./styles";
import InputMask from "react-input-mask";
import { Regex } from "../../../utils/Regex";
import { userDataByCEP } from "../../../Services/utilitiesService";
import Format from "../../../Services/Format";
import { StateForm } from "../../../interfaces/state-form";

interface IProps {
  autoComplete?: "on" | "off";
  type?: "string" | "number" | "date" | "text" | "email" | "password";
  placeholder?: string;
  maxLength?: number;
  label?: string;
  step?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  size?: "sm" | "lg";
  as?: any;
  rows?: number;
  name: string;
  mask?: string;
  defaultValue?: any;
  register: any;
  watch: any;
  setValue: any;
  setError: any;
  errors?: any;
  setStateForm: Dispatch<SetStateAction<StateForm>>;
  dataEdit?: any;
}

export function InputHookCep({
  label,
  type,
  autoComplete,
  placeholder,
  maxLength,
  step,
  min,
  max,
  disabled,
  size,
  as,
  rows,
  name,
  mask,
  defaultValue,
  register,
  watch,
  setValue,
  setError,
  errors,
  setStateForm,
  dataEdit,
}: IProps) {
  const cepValue = watch("cep") ?? "";

  async function CallAdress(cep: any) {
    try {
      setStateForm({ state: "loading" });
      const { data } = await userDataByCEP(cep || "");

      if (data.status === true) {
        setValue("address_street", data?.result?.logradouro);
        setValue("address_complement", data?.result?.complemento);
        setValue("address_district", data?.result?.bairro);
        setValue("state", data?.result?.uf);
        setValue("city", data?.result?.localidade);
        setStateForm({ state: "default" });
        setError("cep", {
          type: "custom",
          message: "",
        });
      } else {
        setError("cep", {
          type: "custom",
          message: "CEP não encontrado.",
        });
      }
      setStateForm({ state: "default" });
    } catch (err: any) {
      setStateForm({ state: "default" });

      return;
    }
  }

  useEffect(() => {
    if (dataEdit.cep === cepValue) return;

    const clearCEP = Regex.removeCharacters(cepValue);

    if (clearCEP?.length === 8) {
      (async () => {
        await CallAdress(Format.stringFormated(clearCEP));
      })();
    }

    if (clearCEP?.length === 0) {
      setValue("address_street", "");
      setValue("address_complement", "");
      setValue("address_district", "");
      setValue("state", "");
      setValue("city", "");
    }
  }, [cepValue]);

  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  return (
    <label>
      <div className="label">{label}</div>
      <S.Input
        errorToDisplay={
          errorToDisplay && errors[0][errorToDisplay]?.message.length > 0
        }
      >
        {mask ? (
          <InputMask
            className="inputMask"
            {...register(name)}
            mask={mask}
            defaultValue={defaultValue}
            name={name}
            type="text"
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            step={step}
            min={min}
            max={max}
          />
        ) : (
          <S.FormInput
            {...register(name)}
            defaultValue={defaultValue}
            name={name}
            as={as}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            maxLength={maxLength}
            step={step}
            min={min}
            max={max}
            disabled={disabled}
            rows={rows}
          />
        )}
        {errorToDisplay && (
          <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
        )}
      </S.Input>
    </label>
  );
}
