import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";
import { IProduct } from "../interface";

export const createCountAsync = createAsyncThunk(
  "create/count",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.count, data);

      if (res.data.data.uuid) {
        await api.post(
          globals.api.count + `/${res.data.data.uuid}/products`,
          data.products,
        );
      }

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editeCountAsync = createAsyncThunk(
  "put/count",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.count + `/${data?.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCountAsync = createAsyncThunk(
  "count",
  async (filter: string, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.count + `?paginate=false&${filter}`,
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCountByUuidAsync = createAsyncThunk(
  "count/uuid",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.get(globals.api.count + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getStoresAsync = createAsyncThunk(
  "count/stores",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.stores + `?paginate=false&store_id=${idStore}`,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getStockAsync = createAsyncThunk(
  "count/stock",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.stocks + `?paginate=false&store_id=${idStore}`,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCategoriesAsync = createAsyncThunk(
  "count/categories",
  async (idStore: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.categoriesCompleted +
          `?filter[is_active]=true&paginate=false&store_id=${idStore}`,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllProductsAsync = createAsyncThunk(
  "count/all-products",
  async (store_id: any, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty&store_id=${store_id}`,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAllProductstableAsync = createAsyncThunk(
  "count/all-products-table",
  async (store_id: any, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty&store_id=${store_id}`,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getProductsAsync = createAsyncThunk(
  "count/products",
  async (data: { idStock: number; store_id: number }, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty&store_id=${data.store_id}`,
      );

      const filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.stock_local_id === data.idStock,
      );
      return data.idStock ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getProductsByCategorieAsync = createAsyncThunk(
  "count/products-by-categorie",
  async (data: { catId: number; storeId: number }, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty?paginate=false&store_id=${data.storeId}`,
      );

      const filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.product_category_id === data.catId,
      );

      return data.catId ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteCountAsync = createAsyncThunk(
  "count/delete",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.count + `/${uuid}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteItemProductTableAsync = createAsyncThunk(
  "count-product/delete",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.count + `/products/${uuid}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const addItemProductTableAsync = createAsyncThunk(
  "count/add",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(
        globals.api.count + `/${data.uuidCount}/products`,
        [data],
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const putItemProductTableAsync = createAsyncThunk(
  "count/put",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.count + `/${data.uuidCount}/products`,
        {
          qty: data.qty,
          product_id: data.id,
        },
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
