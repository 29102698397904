import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import {
  msgStyles,
  NoOptionsMessage,
} from "../../../NoOptionsMessage/no-options-message-component";
import { getFinancialTransaction } from "../../../../Services/financial-transaction";
import { StoreContext } from "../../../../context/store-context";
import { ImprimirContext } from "../../../../context/print-receipt";
import ReactSelect from "../../../data/reactSelect";
import { InputInternText } from "../../../data/inputInternText";
import { InputDefault } from "../../../data/inputDefault";
import { useAppSelector } from "../../../../redux/store";
import { notifyError } from "../../../notify/notify-component";
import * as S from "../styled";

export default function ModalGerarApMovi(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const storeData = storeMe.storeActive;

  const {
    receivedMovi,
    setReceivedMovi,
    setReceivedMoviPrint,
    valueRef,
    setValueRef,
    referent,
    setReferent,
    date,
    setDate,
    show,
    handleClose,
  } = useContext(ImprimirContext);

  const [listTransitionCategories, setListTransitionCategories] = useState<
    any[]
  >([]);
  const [listRevenue, setListRevenue] = useState<any[]>([]);

  const local = storeData.city + "-" + storeData.state;

  const fillTransactionCategories = async () => {
    await getFinancialTransaction(storeData?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const dataRevenue = data.filter(
        (item: any) => item.relationships.category.entry_type === 1,
      );

      const filterStoreActived = dataRevenue.filter(
        (item: any) => item.store_id === storeData.id,
      );

      setListRevenue(filterStoreActived);
      const list = filterStoreActived.map((item: any) => ({
        value: item.id,
        label: item.document_description,
      }));
      setListTransitionCategories(list);
    });
  };

  function handleSubmit() {
    if (!receivedMovi || !valueRef || !referent || !date) {
      return notifyError("Por favor preencha todos os campos!");
    }

    props.OnAddPrintReceipt(true);
  }

  useEffect(() => {
    fillTransactionCategories();
    if (receivedMovi) {
      setValueRef(
        receivedMovi
          ? listRevenue.find((item) => item.id === receivedMovi).amount
          : undefined,
      );
      setReceivedMoviPrint(
        receivedMovi
          ? listRevenue.find((item) => item.id === receivedMovi).description
          : "",
      );
    }
  }, [receivedMovi]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">
          Gerar a partir de movimentação
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: "12px" }}>
        <Form>
          <S.Wrapper2>
            <ReactSelect
              label="Mov. financeira"
              isClearable={true}
              placeholder={"Movimentação financeira"}
              state={receivedMovi}
              options={listTransitionCategories}
              setState={setReceivedMovi}
            />

            <InputDefault
              label="Valor"
              type="number"
              step="0.01"
              autoComplete="off"
              disabled={true}
              state={valueRef}
              placeholder="Valor"
              min="0"
            />
            <InputDefault
              label="Referente à"
              placeholder="Referente à"
              size="sm"
              type="text"
              rows={3}
              autoComplete="off"
              state={referent}
              maxLength={800}
              onChenge={(e: any) => setReferent(e.target.value)}
            />
          </S.Wrapper2>
          <S.Wrapper2>
            <InputDefault
              placeholder="Nome"
              label="Nome"
              disabled={true}
              size="sm"
              type="text"
              autoComplete="off"
              state={storeData.company_name}
              maxLength={50}
            />

            <InputDefault
              label="Documento"
              placeholder="Documento (CPF / CNPJ / RG)"
              disabled={true}
              size="sm"
              type="string"
              step="any"
              autoComplete="off"
              state={storeData.cnpj}
            />

            <InputDefault
              label="Local"
              placeholder="Local"
              disabled={true}
              type="text"
              autoComplete="off"
              state={local}
              maxLength={50}
            />

            <InputDefault
              label="Data"
              type="date"
              state={date}
              setState={setDate}
            />
          </S.Wrapper2>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Proximo
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
