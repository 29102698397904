import { useContext, useState, useEffect } from "react";
import { Row, Form, Col } from "react-bootstrap";
import "./styles.css";
import Layout from "../../../data/Layout";
import { ProdutoContext } from "../../../../context/product";
import * as S from "../styled";
import { InputDefault } from "../../../data/inputDefault";

export default function Precos() {
  const {
    sale,
    setSale,
    coast,
    setCoast,
    minSale,
    setMinSale,
    markupPrice,
    setMarkupPrice,
    markup,
    setMarkup,
    validSale,
    validMinSale,
    validCoast,
  } = useContext(ProdutoContext);

  const [profit, setProfit] = useState<string>("0");
  const [profitMin, setProfitMin] = useState<string>("0");
  const [option, setOption] = useState("sell");
  const [sellDisabled, setSellDisabled] = useState<boolean>(false);
  const [markupPriceDisabled, setMarkupPriceDisabled] = useState<boolean>(true);
  const [markupPercentDisabled, setMarkupPercentDisabled] =
    useState<boolean>(true);
 

  function calcMarkup(coast: number, markup: number) {
    const calc = coast / (1 - markup / 100);

    if (calc === Infinity || !calc) {
      return "";
    }
    return calc.toFixed(2);
  }

  function calcPercentage(priceSale: number, priceCost: number) {
    const calc = Math.round((priceSale / priceCost - 1) * 100);
    if (calc === Infinity || !calc) {
      return "";
    }
    return calc.toFixed(2);
  }

  function calcMarkupValue(priceSale: number, priceCost: number) {
    const calc = priceSale + priceCost;
    if (calc === Infinity || !calc) {
      return "";
    }
    return calc.toFixed(2);
  }

  const handleChangeSaleValues = () => {
    setProfit(calcPercentage(parseFloat(sale), parseFloat(coast)));
  };

  const handleChangeMinSaleValues = () => {
    setProfitMin(calcPercentage(parseFloat(minSale), parseFloat(coast)));
  };

  const handleChangeCostPriceValues = () => {
    setProfit(calcPercentage(parseFloat(sale), parseFloat(coast)));
    setProfitMin(calcPercentage(parseFloat(minSale), parseFloat(coast)));
  };

  const handleChangeMarkupPriceValues = () => {
    if (parseFloat(markupPrice) > 0) {
      setSale(calcMarkupValue(parseFloat(markupPrice), parseFloat(coast)));
    }
    setProfit(calcPercentage(parseFloat(markupPrice), parseFloat(coast)));
  };

  const handleChangeMarkupValues = () => {
    if (parseFloat(markup) > 0) {
      setSale(calcMarkup(parseFloat(coast), parseFloat(markup)));
    }
    setProfit(calcPercentage(parseFloat(markup), parseFloat(coast)));
  };

  const handleChange = (event: any) => {
    setOption(event);
    switch (event) {
      case "sell":
        setSale(sale);
        setProfit(profit);
        setMarkup(markup);
        setMarkupPrice(markupPrice);
        setMarkupPriceDisabled(true);
        setMarkupPercentDisabled(true);
        setSellDisabled(false);
        break;

      case "markupPrice":
        setSale(sale);
        setProfit(profit);
        setMarkup(markup);
        setMarkupPrice(markupPrice);
        setMarkupPriceDisabled(false);
        setMarkupPercentDisabled(true);
        setSellDisabled(true);
        break;

      case "markupPercent":
        setSale(sale);
        setProfit(profit);
        setMarkupPriceDisabled(true);
        setMarkupPercentDisabled(false);
        setSellDisabled(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    handleChangeSaleValues();
    handleChangeMinSaleValues();
    handleChangeCostPriceValues();
  }, [sale, minSale, coast]);

  useEffect(() => {
    handleChangeMarkupPriceValues();
  }, [markupPrice]);

  useEffect(() => {
    handleChangeMarkupValues();
  }, [markup]);
  

  return (
    <Layout title="Preços">
      <Row className="mb-6">
        <Col md="2" className="mb-2">
          <div>Cálcular por:</div>
        </Col>

        <Col className="mb-2">
          {["radio"].map((type: any) => (
            <div key={`inline-${type}`}>
              <Form.Check
                inline
                label="Venda (R$)"
                name="radio"
                type={type}
                id={`inline-${type}-1`}
                value={option}
                checked={option === "sell"}
                onChange={(e) => handleChange("sell")}
              />
              <Form.Check
                inline
                label="Markup (R$)"
                name="radio"
                type={type}
                id={`inline-${type}-2`}
                value={option}
                checked={option === "markupPrice"}
                onChange={(e) => handleChange("markupPrice")}
              />
              <Form.Check
                inline
                label="Markup (%)"
                name="radio"
                type={type}
                id={`inline-${type}-3`}
                value={option}
                checked={option === "markupPercent"}
                onChange={(e) => handleChange("markupPercent")}
              />
            </div>
          ))}
        </Col>
      </Row>
      <S.Wrapper>
        <InputDefault
          label="Venda"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={sale}
          setState={setSale}
          disabled={sellDisabled}
          isInvalid={validSale}
        />

        <InputDefault
          label="Mínimo de venda"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={minSale}
          setState={setMinSale}
          isInvalid={validMinSale}
        />

        <InputDefault
          label="Custo"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={coast}
          setState={setCoast}
          isInvalid={validCoast}
        />

        <InputDefault
          label="Markup"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={markupPrice}
          setState={setMarkupPrice}
          disabled={markupPriceDisabled}
        />

        <InputDefault
          label="Markup de venda"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.1"
          min="0"
          state={markup}
          setState={setMarkup}
          disabled={markupPercentDisabled}
        />
      </S.Wrapper>
      <Row>
        <Col md="4">
          <div>Margem de lucro valor vendas: {profit}%</div>
        </Col>
        <Col md="4">
          <div>Margem de lucro valor mínimo vendas: {profitMin}%</div>
        </Col>
      </Row>
    </Layout>
  );
}
