// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__3P-3I {
  position: relative;
  min-height: 100vh;
}

.styles_children__KtnR0 {
  display: flex;
  flex: 1 1;
  margin-bottom: 90px;
}`, "",{"version":3,"sources":["webpack://./src/Components/GlobalLayout/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACF","sourcesContent":[".container {\n  position: relative;\n  min-height: 100vh;\n}\n\n.children {\n  display: flex;\n  flex: 1;\n  margin-bottom: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__3P-3I`,
	"children": `styles_children__KtnR0`
};
export default ___CSS_LOADER_EXPORT___;
