import { sortByCreated } from "../../../../Services/sortByCreated";
import { ICategorieFinancial } from "../interface";

export const getEntryType = (
  response: ICategorieFinancial[],
  entrytype: number,
) => {
  const dataFilter = response.filter(
    (expense: any) => expense.entry_type === entrytype,
  );
  const sortedData = sortByCreated(dataFilter);

  return sortedData;
};
