
import { SelectHook } from "../../../../reactHookForm/SelectHook";
import { InputHook } from "../../../../reactHookForm/InputHook";

import { useAppSelector } from "../../../../../redux/store";

import * as S from "./styled";


interface IProps {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  setError: any;
  errors: any;
  reset: any;
  openFilter: boolean;
}

export function InputFilter({
                              register,
                              control,
                              watch,
                              setValue,
                              setError,
                              reset,
                              openFilter,
                            }: IProps) {

  const { categories} = useAppSelector(
    ({ selfServiceReducer }) => selfServiceReducer.value,
  );

  return (
    <S.Wrapper>
      {openFilter && (
        <div className="min-screen">
          

          <SelectHook
            register={register}
            control={control}
            label="Categoria"
            name="category"
            placeholder="Categoria"
            options={categories.map(item => ({
              value: item.id,
              label: item.name
            }))}
          />

        </div>
      )}

      <div className="max-screen">
        
        <SelectHook
          register={register}
          control={control}
          label="Categoria"
          name="category"
          placeholder="categoria"
          options={categories.map(item => ({
            value: item.id,
            label: item.name
          }))}
        />

      </div>
    </S.Wrapper>
  );
}

