import {
  PayloadAction,
  createSlice,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { sortByCreated } from "../../../Services/sortByCreated";
import { ICfop } from "./interface";
import { getCfopAsync } from "./services";

interface InitialState {
  value: ICfopState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface ICfopState {
  loading: boolean;
  fetchError: string | {};
  stateForm: StateForm;
  cfops: ICfop[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    stateForm: { state: "default" },
    cfops: [],
  } as ICfopState,
};

export const userCfopState = createSlice({
  name: "cfop",
  initialState,
  reducers: {
    setStateForm: (state: InitialState, action: PayloadAction<StateForm>) => {
      state.value.stateForm = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialState>) => {
    builder.addCase(getCfopAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getCfopAsync.fulfilled,
      (state: InitialState, action: PayloadAction<ICfop[]>) => {
        state.value.loading = false;
        state.value.cfops = sortByCreated([...action.payload]);
      },
    );
    builder.addCase(getCfopAsync.rejected, (state: InitialState, action) => {
      state.value.loading = false;
      state.value.fetchError = action.payload ?? "Error fetching user data";
    });
  },
});

export const { setStateForm } = userCfopState.actions;

export default userCfopState.reducer;
