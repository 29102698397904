import { Col, Form, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Btn } from "../Btn";
import React, { SetStateAction, useState } from "react";

interface ILayout {
  children: any;
  title?: string;
  check?: boolean;
  onClickCancel?: () => void;
  onClickSave?: () => void;
  onClickEdit?: () => void;
  isEditable?: any;
  statusCheck?: boolean;
  setStatusCheck?: React.Dispatch<SetStateAction<boolean>>;
  onlyView?: boolean;
  handleDelete?: () => void;
}

export default function LayoutProduct({
  children,
  title,
  check,
  onClickCancel,
  onClickSave,
  isEditable,
  statusCheck,
  setStatusCheck,
  onClickEdit,
  onlyView,
  handleDelete,
}: ILayout) {
  const bg = statusCheck ? "#16a34a" : "#f87171";

  return (
    <Form className={styles.container}>
      <Row>
        <Col className={styles.titleGroup}>
          {title && <h3>{title}</h3>}
          {check && setStatusCheck && (
            <button
              className={styles.btn}
              type="button"
              onClick={() => setStatusCheck(!statusCheck)}
              style={{ background: bg }}
            >
              {statusCheck ? "Ativo" : "Inativo"}
            </button>
          )}
        </Col>
      </Row>
      {children}
      <Row className="mt-2">
        <Col className={styles.btnGourp}>
          {onClickCancel ? (
            <Btn
              text="Cancelar"
              type="button"
              variante="secondary"
              onClick={onClickCancel}
            />
          ) : (
            <div></div>
          )}
          <div className={styles.btnGourp2}>
            {handleDelete && (
              <Btn
                text="Excluir"
                type="button"
                variante="danger"
                onClick={handleDelete}
              />
            )}
            {onClickEdit && onClickSave && !onlyView && (
              <Btn
                text={isEditable ? "Atualizar" : "Salvar"}
                type="button"
                variante="success"
                onClick={isEditable ? onClickEdit : onClickSave}
              />
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
}

/*
 <Form.Check
              className={styles.check}
              type="switch"
              label={statusCheck === 1 ? "Ativo" : "Inativo"}
              checked={statusCheck === 1 ? true : false}
              onChange={(e: any) =>
                e.target.checked === true
                  ? setStatusCheck(1)
                  : setStatusCheck(0)
              }
            />
*/
