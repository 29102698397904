import { createContext, ReactNode, useEffect, useState } from "react";
import { IProduct } from "../../interfaces/IProduct";

interface ChildrenProps {
  children: ReactNode;
}

interface InitialContextInterface {
  status: boolean;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  barcode: string;
  setBarcode: React.Dispatch<React.SetStateAction<string>>;
  descriptionSale: string;
  setDescriptionSale: React.Dispatch<React.SetStateAction<string>>;
  descriptionPurchase: string;
  setDescriptionPurchase: React.Dispatch<React.SetStateAction<string>>;
  descriptionTouch: string;
  setDescriptionTouch: React.Dispatch<React.SetStateAction<string>>;
  category?: number;
  setCategory: React.Dispatch<React.SetStateAction<number | undefined>>;
  vendor?: number;
  setVendor: React.Dispatch<React.SetStateAction<number | undefined>>;
  productType?: number;
  setProductType: React.Dispatch<React.SetStateAction<number | undefined>>;
  sale: string;
  setSale: React.Dispatch<React.SetStateAction<string>>;
  minSale: string;
  setMinSale: React.Dispatch<React.SetStateAction<string>>;
  coast: string;
  setCoast: React.Dispatch<React.SetStateAction<string>>;
  markupPrice: string;
  setMarkupPrice: React.Dispatch<React.SetStateAction<string>>;
  markup: string;
  setMarkup: React.Dispatch<React.SetStateAction<string>>;
  stock_uuid: string;
  setStock_uuid: React.Dispatch<React.SetStateAction<string>>;

  unit: number;
  setUnit: React.Dispatch<React.SetStateAction<number>>;
  currentAmount: string;
  setCurrentAmount: React.Dispatch<React.SetStateAction<string>>;
  minAmount: string;
  setMinAmount: React.Dispatch<React.SetStateAction<string>>;
  localStockName: string;
  setLocalStockName: React.Dispatch<React.SetStateAction<string>>;
  localStock?: number;
  setLocalStock: React.Dispatch<React.SetStateAction<number | undefined>>;
  typeOper?: number;
  setTypeOper: React.Dispatch<React.SetStateAction<number | undefined>>;
  unitMeasureBuy?: number;
  setUnitMeasureBuy: React.Dispatch<React.SetStateAction<number | undefined>>;
  multiple: string;
  setMultiple: React.Dispatch<React.SetStateAction<string>>;
  observation?: string;
  setObservation: React.Dispatch<React.SetStateAction<string>>;
  taxProfile?: number;
  setTaxProfile: React.Dispatch<React.SetStateAction<number | undefined>>;
  stateOrigin?: number;
  setStateOrigin: React.Dispatch<React.SetStateAction<number | undefined>>;
  stateDestiny?: number;
  setStateDestiny: React.Dispatch<React.SetStateAction<number | undefined>>;
  types?: number;
  setTypes: React.Dispatch<React.SetStateAction<number | undefined>>;
  origins?: number;
  setOrigins: React.Dispatch<React.SetStateAction<number | undefined>>;
  ncm?: number;
  setNcm: React.Dispatch<React.SetStateAction<number | undefined>>;
  cest?: number;
  setCest: React.Dispatch<React.SetStateAction<number | undefined>>;
  cfop?: number;
  setCfop: React.Dispatch<React.SetStateAction<number | undefined>>;
  customer?: number;
  setCustomer: React.Dispatch<React.SetStateAction<number | undefined>>;
  icms?: number;
  setIcms: React.Dispatch<React.SetStateAction<number | undefined>>;
  ipi?: number;
  setIpi: React.Dispatch<React.SetStateAction<number | undefined>>;
  cstReasons?: number;
  setCstReasons: React.Dispatch<React.SetStateAction<number | undefined>>;
  pis?: number;
  setPis: React.Dispatch<React.SetStateAction<number | undefined>>;
  cofins?: number;
  setCofins: React.Dispatch<React.SetStateAction<number | undefined>>;

  weight: string;
  setWeight: React.Dispatch<React.SetStateAction<string>>;
  ncm_origin: string;
  setNcm_origin: React.Dispatch<React.SetStateAction<string>>;
  
  aliquot: string;
  setAliquotIcms: React.Dispatch<React.SetStateAction<string>>;
  mva: string;
  setMva: React.Dispatch<React.SetStateAction<string>>;
  reduction: string;
  setReduction: React.Dispatch<React.SetStateAction<string>>;
  icmsAliquotDeferral: string;
  setIcmsAliquotDeferral: React.Dispatch<React.SetStateAction<string>>;
  icmsCredit: string;
  setIcmsCredit: React.Dispatch<React.SetStateAction<string>>;
  aliquotIpi: string;
  setAliquotIpi: React.Dispatch<React.SetStateAction<string>>;
  aliquotPis: string;
  setAliquotPis: React.Dispatch<React.SetStateAction<string>>;
  aliquotCofins: string;
  setAliquotCofins: React.Dispatch<React.SetStateAction<string>>;
  xdRequestQty: boolean;
  setXdRequestQty: React.Dispatch<React.SetStateAction<boolean>>;
  xdRequestPriceWhenZero: boolean;
  setXdRequestPriceWhenZero: React.Dispatch<React.SetStateAction<boolean>>;
  xdBalanceQty: boolean;
  setXdBalanceQty: React.Dispatch<React.SetStateAction<boolean>>;
  xdOrderGrouped: boolean;
  setXdOrderGrouped: React.Dispatch<React.SetStateAction<boolean>>;
  xdBillGroup: boolean;
  setXdBillGroup: React.Dispatch<React.SetStateAction<boolean>>;
  xdAddTips: boolean;
  setXdAddTips: React.Dispatch<React.SetStateAction<boolean>>;
  xdExcludeMinimumConsumption: boolean;
  setXdExcludeMinimumConsumption: React.Dispatch<React.SetStateAction<boolean>>;
  selfService: boolean;
  setSelfService: React.Dispatch<React.SetStateAction<boolean>>;
  xdBook: boolean;
  setXdBook: React.Dispatch<React.SetStateAction<boolean>>;

  xdFollowItem: boolean;
  setXdFollowItem: React.Dispatch<React.SetStateAction<boolean>>;
  xdPrinters: string;
  setXdPrinters: React.Dispatch<React.SetStateAction<string>>;
  cleanInputs: () => void;
  handleEdit: (props: IProduct) => void;
  handleDuplicate: (props: IProduct) => void;
  handleValidete: () => boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  listCode: any;
  setListCode: React.Dispatch<React.SetStateAction<any[]>>;
  categoriesList: any;
  setCategoriesComplement: React.Dispatch<React.SetStateAction<any[]>>;
  productsList: any;
  setProductsComplement: React.Dispatch<React.SetStateAction<any[]>>;

  setAssociatedComplement: React.Dispatch<React.SetStateAction<any[]>>;
  associatedComplement: any;
  productsAssociatedComplement: any;
  setProductsAssociatedComplement: React.Dispatch<React.SetStateAction<any[]>>;

  validCode: boolean;
  setValidCode: React.Dispatch<React.SetStateAction<boolean>>;
  validDescriptionSale: boolean;
  setValidDescriptionSale: React.Dispatch<React.SetStateAction<boolean>>;
  validDescriptionPurchase: boolean;
  setValidDescriptionPurchase: React.Dispatch<React.SetStateAction<boolean>>;
  validDescriptionTouch: boolean;
  setValidDescriptionTouch: React.Dispatch<React.SetStateAction<boolean>>;
  validCategory: boolean;
  setValidCategory: React.Dispatch<React.SetStateAction<boolean>>;
  validProductType: boolean;
  setValidProductType: React.Dispatch<React.SetStateAction<boolean>>;
  validSale: boolean;
  setValidSale: React.Dispatch<React.SetStateAction<boolean>>;
  validMinSale: boolean;
  setValidMinSale: React.Dispatch<React.SetStateAction<boolean>>;
  validCoast: boolean;
  setValidCoast: React.Dispatch<React.SetStateAction<boolean>>;
  validUnit: boolean;
  setValidUnit: React.Dispatch<React.SetStateAction<boolean>>;
  validNcm: boolean;
  setValidNcm: React.Dispatch<React.SetStateAction<boolean>>;

  validCfop: boolean;
  setValidCfop: React.Dispatch<React.SetStateAction<boolean>>;
  validIcms: boolean;
  setValidIcms: React.Dispatch<React.SetStateAction<boolean>>;
  validAliquot: boolean;
  setValidAliquot: React.Dispatch<React.SetStateAction<boolean>>;
  validIpi: boolean;
  setValidIpi: React.Dispatch<React.SetStateAction<boolean>>;
  validAliquotIpi: boolean;
  setValidAliquotIpi: React.Dispatch<React.SetStateAction<boolean>>;
  validPis: boolean;
  setValidPis: React.Dispatch<React.SetStateAction<boolean>>;
  validAliquotPis: boolean;
  setValidCofins: React.Dispatch<React.SetStateAction<boolean>>;
  validCofins: boolean;
  setValidAliquotPis: React.Dispatch<React.SetStateAction<boolean>>;
  validType: boolean;
  setValidType: React.Dispatch<React.SetStateAction<boolean>>;
  validOrigins: boolean;
  setValidOrigins: React.Dispatch<React.SetStateAction<boolean>>;
  validqty: boolean;
  setValidqty: React.Dispatch<React.SetStateAction<boolean>>;
  validLocalStock: boolean;
  setValidLocalStock: React.Dispatch<React.SetStateAction<boolean>>;
  validOrigem: boolean;
  setValidOrigem: React.Dispatch<React.SetStateAction<boolean>>;
  validcstReasons: boolean;
  setValidcstReasons: React.Dispatch<React.SetStateAction<boolean>>;
  loadingListCode: boolean;
  setLoadingListCode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProdutoContext = createContext<InitialContextInterface>(
  {} as InitialContextInterface,
);

export const ProdutoProvider = ({ children }: ChildrenProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  //Dados gerais
  const [status, setStatus] = useState<boolean>(true);
  const [image, setImage] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");
  const [ncm_origin, setNcm_origin] = useState<string>("Nacional");
  const [descriptionSale, setDescriptionSale] = useState("");
  const [descriptionPurchase, setDescriptionPurchase] = useState("");
  const [descriptionTouch, setDescriptionTouch] = useState("");
  const [category, setCategory] = useState<number | undefined>(undefined);
  const [vendor, setVendor] = useState<number | undefined>(undefined);
  const [productType, setProductType] = useState<number | undefined>(undefined);
  
  const [listCode, setListCode] = useState<any[]>([]);
  const [loadingListCode, setLoadingListCode] = useState<boolean>(false);

  //Preços
  const [sale, setSale] = useState<string>("0.00");
  const [minSale, setMinSale] = useState<string>("0.00");
  const [coast, setCoast] = useState<string>("0.00");
  const [markupPrice, setMarkupPrice] = useState<string>("");
  const [markup, setMarkup] = useState<string>("");
  //Unidade de medida
  const [unit, setUnit] = useState<number>(0);
  //Informações de estoque
  const [stock_uuid, setStock_uuid] = useState<string>("");

  const [currentAmount, setCurrentAmount] = useState<string>("0");
  const [minAmount, setMinAmount] = useState<string>("0");
  const [localStock, setLocalStock] = useState<number | undefined>(undefined); //Futuramente iniciar com cadastrado na loja padrão no listProduct
  const [localStockName, setLocalStockName] = useState<string>("");
  const [typeOper, setTypeOper] = useState<number | undefined>(undefined);
  const [unitMeasureBuy, setUnitMeasureBuy] = useState<number | undefined>(
    undefined,
  );
  const [multiple, setMultiple] = useState<string>("");
  const [observation, setObservation] = useState<string>("");
  //Informações Fiscais
  const [taxProfile, setTaxProfile] = useState<number | undefined>(undefined);
  const [stateOrigin, setStateOrigin] = useState<number | undefined>(undefined);
  const [stateDestiny, setStateDestiny] = useState<number | undefined>(
    undefined,
  );
  const [types, setTypes] = useState<number | undefined>(undefined);
  const [origins, setOrigins] = useState<number | undefined>(undefined);
  const [ncm, setNcm] = useState<number | undefined>(undefined);
  const [cest, setCest] = useState<number | undefined>(undefined);
  const [cfop, setCfop] = useState<number | undefined>(undefined);
  const [customer, setCustomer] = useState<number | undefined>(undefined);
  const [icms, setIcms] = useState<number | undefined>(undefined);
  const [ipi, setIpi] = useState<number | undefined>(undefined);
  const [cstReasons, setCstReasons] = useState<number | undefined>(129);
  const [pis, setPis] = useState<number | undefined>(undefined);
  const [cofins, setCofins] = useState<number | undefined>(undefined);
  const [weight, setWeight] = useState<string>("0");
  const [aliquot, setAliquotIcms] = useState<string>("0");
  const [mva, setMva] = useState<string>("0");
  const [reduction, setReduction] = useState<string>("0");
  const [icmsAliquotDeferral, setIcmsAliquotDeferral] = useState<string>("0");
  const [icmsCredit, setIcmsCredit] = useState<string>("0");
  const [aliquotIpi, setAliquotIpi] = useState<string>("0");
  const [aliquotPis, setAliquotPis] = useState<string>("0");
  const [aliquotCofins, setAliquotCofins] = useState<string>("0");
  //Informações de venda
  const [xdRequestQty, setXdRequestQty] = useState<boolean>(false);
  const [xdRequestPriceWhenZero, setXdRequestPriceWhenZero] =
    useState<boolean>(true);
  const [xdBalanceQty, setXdBalanceQty] = useState<boolean>(false);
  const [xdOrderGrouped, setXdOrderGrouped] = useState<boolean>(true);
  const [xdBillGroup, setXdBillGroup] = useState<boolean>(true);
  const [xdAddTips, setXdAddTips] = useState<boolean>(true);
  const [xdExcludeMinimumConsumption, setXdExcludeMinimumConsumption] =
    useState<boolean>(false);
  const [xdBook, setXdBook] = useState<boolean>(false);
  const [selfService, setSelfService] = useState<boolean>(true);
  const [xdFollowItem, setXdFollowItem] = useState<boolean>(false);
  const [xdPrinters, setXdPrinters] = useState<string>(
    "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0",
  );

  const [validCode, setValidCode] = useState<boolean>(false);
  const [validDescriptionSale, setValidDescriptionSale] =
    useState<boolean>(false);
  const [validDescriptionPurchase, setValidDescriptionPurchase] =
    useState<boolean>(false);
  const [validDescriptionTouch, setValidDescriptionTouch] =
    useState<boolean>(false);
  const [validCategory, setValidCategory] = useState<boolean>(false);
  const [validProductType, setValidProductType] = useState<boolean>(false);
  const [validSale, setValidSale] = useState<boolean>(false);
  const [validMinSale, setValidMinSale] = useState<boolean>(false);
  const [validCoast, setValidCoast] = useState<boolean>(false);
  const [validUnit, setValidUnit] = useState<boolean>(false);
  const [validNcm, setValidNcm] = useState<boolean>(false);
  
  const [validCfop, setValidCfop] = useState<boolean>(false);
  const [validIcms, setValidIcms] = useState<boolean>(false);
  const [validAliquot, setValidAliquot] = useState<boolean>(false);
  const [validAliquotIpi, setValidAliquotIpi] = useState<boolean>(false);
  const [validIpi, setValidIpi] = useState<boolean>(false);
  const [validPis, setValidPis] = useState<boolean>(false);
  const [validCofins, setValidCofins] = useState<boolean>(false);
  const [validAliquotPis, setValidAliquotPis] = useState<boolean>(false);
  const [validType, setValidType] = useState<boolean>(false);
  const [validOrigins, setValidOrigins] = useState<boolean>(false);
  const [validqty, setValidqty] = useState<boolean>(false);
  const [validOrigem, setValidOrigem] = useState<boolean>(false);
  const [validcstReasons, setValidcstReasons] = useState<boolean>(false);
  const [validLocalStock, setValidLocalStock] = useState<boolean>(false);
  const [categoriesList, setCategoriesComplement] = useState<Array<any>>([]);
  const [productsList, setProductsComplement] = useState<Array<any>>([]);
  const [associatedComplement, setAssociatedComplement] = useState<Array<any>>([]);
  const [productsAssociatedComplement, setProductsAssociatedComplement] = useState<Array<any>>([]);

  const cleanInputs = () => {
    setStatus(true);
    setImage("");
    setCode("");
    setBarcode("");
    setDescriptionSale("");
    setDescriptionPurchase("");
    setDescriptionTouch("");
    setCategory(undefined);
    setVendor(undefined);
    setNcm_origin("");
    //setProductType(undefined);
    //setSale("");
    // setMinSale("");
    // setCoast("");
    setMarkupPrice("0");
    setMarkup("0");
    // setStock_uuid("");

    //setUnit(0);
    setCurrentAmount("0");
    setMinAmount("0");
    setLocalStock(undefined);
    // setTypeOper(undefined);
    // setUnitMeasureBuy(undefined);
    setMultiple("");
    setObservation("");
    setTaxProfile(undefined);
    setStateOrigin(undefined);
    setStateDestiny(undefined);
    setTypes(undefined);
    setOrigins(undefined);
    setNcm(undefined);
    setCest(undefined);
    setCfop(undefined);
    setCustomer(undefined);
    setIcms(undefined);
    setIpi(undefined);
    setCstReasons(129);
    setPis(undefined);
    setCofins(undefined);
    setWeight("0");
    setAliquotIcms("0");
    setMva("0");
    setReduction("0");
    setIcmsAliquotDeferral("0");
    setIcmsCredit("0");
    setAliquotIpi("0");
    setAliquotPis("0");
    setAliquotCofins("0");
    // setXdRequestQty(false);
    //setXdRequestPriceWhenZero(false);
    setXdBalanceQty(false);
    //setXdOrderGrouped(false);
    // setXdBillGroup(false);
    // setXdAddTips(false);
    setXdExcludeMinimumConsumption(false);
    setXdBook(false);
    setXdFollowItem(false);
    setXdPrinters("0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0");
    setProductsAssociatedComplement([])
    setAssociatedComplement([])
    setProductsComplement([])
    setCategoriesComplement([])
    setValidDescriptionSale(false)
    setValidDescriptionPurchase(false)
    setValidDescriptionTouch(false)
    setValidCategory(false);
    setValidProductType(false);
    setValidSale(false);
    setValidMinSale(false);
    setValidCoast(false);
    setValidUnit(false);
    setValidNcm(false);
    setValidCfop(false);
    setValidIcms(false);
    setValidAliquot(false);
    setValidAliquotIpi(false);
    setValidIpi(false);
    setValidPis(false);
    setValidCofins(false);
    setValidAliquotPis(false);
    setValidType(false);
    setValidOrigins(false);
    setValidqty(false);
    setValidOrigem(false);
    setValidcstReasons(false);
    setValidLocalStock(false);
  };

  const handleEdit = (props: IProduct) => {
    setNcm_origin(props.ncm_origin)
    setStatus(props?.is_active);
    setImage(props?.image);
    setCode(props?.code);
    setBarcode(props?.barcode);
    setDescriptionSale(props?.sale_name);
    setDescriptionPurchase(props?.purchase_name);
    setDescriptionTouch(props?.touch_name);
    setCategory(props?.product_category_id);
    setVendor(props?.vendor_id);
    setProductType(props?.product_type_id);
    setSale(props?.sale_price?.toString() ?? "0");
    setMinSale(props?.sale_minimal_price?.toString() ?? "0");
    setCoast(props?.cost_price?.toString() ?? "0");
    setMarkupPrice(props?.markup_value?.toFixed(2)?.toString() ?? "0");
    setMarkup(props?.markup_percentil?.toFixed(2)?.toString() ?? "0");
    //setStock_uuid()
    setCategoriesComplement(props.relationships.complements);
    setAssociatedComplement(props.relationships.associateds);
    setUnit(props?.sale_measure_unit_id);
    setMinAmount(props?.stock_minimal_qty?.toString() ?? "0");
    setCurrentAmount(props?.stock_qty?.toString() ?? "0");
    setLocalStock(props?.stock_local_id);
    //setLocalStock(props?.stock_reference);
    setTypeOper(props?.product_purpose_id);
    setUnitMeasureBuy(props?.stock_measure_unit_id);
    setMultiple(props?.sale_measure_unit_multiplier?.toString() ?? "0");
    //setObservation(props?.stock_reference);
    setTaxProfile(props?.tax_profile_id);
    setStateOrigin(props?.icms_origin_state_id);
    setStateDestiny(props?.icms_destiny_state_id);
    setTypes(props?.tax_invoice_type_id);
    setOrigins(props?.cst_origin_id);
    setNcm(props?.ncm_id);
    setCest(props?.cest_id);
    setCfop(props?.cfop_id);
    setCustomer(props?.icms_customer_type_id);
    setIcms(props?.cst_icms_id);
    setIpi(props?.cst_ipi_id);
    setCstReasons(props?.cst_ipi_reason_id ?? 129);
    setPis(props?.cst_pis_id);
    setCofins(props?.cst_cofins_id);
    setWeight(props?.tax_weight?.toString()?? "0");
    setAliquotIcms(props?.icms_aliquot?.toString()?? "0");
    setMva(props?.icms_mva?.toString()?? "0");
    setReduction(props?.icms_base_calc_reduction?.toString()?? "0");
    setIcmsAliquotDeferral(props?.icms_aliquot_deferral?.toString()?? "0");
    setIcmsCredit(props?.icms_credit?.toString()?? "0");
    setAliquotIpi(props?.ipi_aliquot?.toString()?? "0");
    setAliquotPis(props?.pis_aliquot?.toString()?? "0");
    setAliquotCofins(props?.cofins_aliquot?.toString()?? "0");
    setXdRequestQty(props?.xd_request_qty);
    setXdRequestPriceWhenZero(props?.xd_request_price_when_zero);
    setXdBalanceQty(props?.xd_balance_qty);
    setXdOrderGrouped(props?.xd_order_grouped);
    setXdBillGroup(props?.xd_bill_group);
    setXdAddTips(props?.xd_add_tips);
    setXdExcludeMinimumConsumption(props?.xd_exclude_minimum_consumption);
    setXdBook(props?.xd_book);
    setXdFollowItem(props?.xd_follow_item);
    setXdPrinters(props?.xd_printers);
    setNcm(props?.ncm_id);
    setSelfService(props?.self_service);
  };

  const handleDuplicate = (props: IProduct) => {
    setStatus(props?.is_active);
    setImage("");
    setCategoriesComplement(props.relationships.complements);
    setAssociatedComplement(props.relationships.associateds);
    setBarcode("");
    setDescriptionSale("");
    setDescriptionPurchase("");
    setDescriptionTouch("");
    setCategory(props?.product_category_id);
    setVendor(props?.vendor_id);
    setProductType(props?.product_type_id);
    setSale(props?.sale_price?.toString() ?? "0");
    setMinSale(props?.sale_minimal_price?.toString() ?? "0");
    setCoast(props?.cost_price?.toString() ?? "0");
    setMarkupPrice(props?.markup_value?.toString() ?? "0");
    setMarkup(props?.markup_percentil?.toString() ?? "0");
    setUnit(props?.sale_measure_unit_id);
    setCurrentAmount("0");
    setMinAmount("0");
    setLocalStock(props?.stock_local_id);
    setTypeOper(props?.product_purpose_id);
    setUnitMeasureBuy(props?.stock_measure_unit_id);
    setMultiple("");
    setNcm_origin("");
    //setObservation(props?.stock_reference);
    setTaxProfile(props?.tax_profile_id);
    setStateOrigin(props?.icms_origin_state_id);
    setStateDestiny(props?.icms_destiny_state_id);
    setTypes(props?.tax_invoice_type_id);
    setOrigins(props?.cst_origin_id);
    setCest(props?.cest_id);
    setCfop(props?.cfop_id);
    setCustomer(props?.icms_customer_type_id);
    setIcms(props?.cst_icms_id);
    setIpi(props?.cst_ipi_id);
    setCstReasons(props?.cst_ipi_reason_id ?? 129);
    setPis(props?.cst_pis_id);
    setCofins(props?.cst_cofins_id);
    setWeight(props?.tax_weight?.toString() ?? "0");
    setAliquotIcms(props?.icms_aliquot?.toString() ?? "0");
    setMva(props?.icms_mva?.toString() ?? "0");
    setReduction(props?.icms_base_calc_reduction?.toString() ?? "0");
    setIcmsAliquotDeferral(props?.icms_aliquot_deferral?.toString() ?? "0");
    setIcmsCredit(props?.icms_credit?.toString() ?? "0");
    setAliquotIpi(props?.ipi_aliquot?.toString() ?? "0");
    setAliquotPis(props?.pis_aliquot?.toString() ?? "0");
    setAliquotCofins(props?.cofins_aliquot?.toString() ?? "0");
    setXdRequestQty(props?.xd_request_qty);
    setXdRequestPriceWhenZero(props?.xd_request_price_when_zero);
    setXdBalanceQty(props?.xd_balance_qty);
    setXdOrderGrouped(props?.xd_order_grouped);
    setXdBillGroup(props?.xd_bill_group);
    setXdAddTips(props?.xd_add_tips);
    setXdExcludeMinimumConsumption(props?.xd_exclude_minimum_consumption);
    setXdBook(props?.xd_book);
    setXdFollowItem(props?.xd_follow_item);
    setXdPrinters(props?.xd_printers);
    setNcm(props?.ncm_id);
    setSelfService(props?.self_service);
  };

  const handleValidete = () => {
    if (!code) setValidCode(true);
    if (code) setValidCode(false);
    if (!descriptionSale) setValidDescriptionSale(true);
    if (descriptionSale) setValidDescriptionSale(false);
    if (!descriptionPurchase) setValidDescriptionPurchase(true);
    if (descriptionPurchase) setValidDescriptionPurchase(false);
    if (!descriptionTouch) setValidDescriptionTouch(true);
    if (descriptionTouch) setValidDescriptionTouch(false);
    if (!category) setValidCategory(true);
    if (category) setValidCategory(false);
    if (!productType || productType !== 0) setValidProductType(true);
    if (productType || productType === 0) setValidProductType(false);
    if (!sale) setValidSale(true);
    if (sale) setValidSale(false);
    if (!minSale) setValidMinSale(true);
    if (minSale) setValidMinSale(false);
    if (!coast) setValidCoast(true);
    if (coast) setValidCoast(false);
    if (!unit) setValidUnit(true);
    if (unit) setValidUnit(false);
    if (!ncm) setValidNcm(true);
    if (ncm) setValidNcm(false);
    //if (selfService && !cest) setValidCest(true);
    //if (selfService && cest) setValidCest(false);
    if (selfService && !cstReasons) setValidcstReasons(true);
    if (selfService && cstReasons) setValidcstReasons(false);
 
    if (!cfop) setValidCfop(true);
    if (cfop) setValidCfop(false);
    if (!icms) setValidIcms(true);
    if (icms) setValidIcms(false);
    if (!aliquot) setValidAliquot(true);
    if (aliquot) setValidAliquot(false);
    if (!ipi) setValidIpi(true);
    if (ipi) setValidIpi(false);
    if (!aliquotIpi) setValidAliquotIpi(true);
    if (aliquotIpi) setValidAliquotIpi(false);
    if (!pis) setValidPis(true);
    if (pis) setValidPis(false);
    if (!cofins) setValidCofins(true);
    if (cofins) setValidCofins(false);
    if (!aliquotPis) setValidAliquotPis(true);
    if (aliquotPis) setValidAliquotPis(false);
   
    if (!currentAmount) setValidqty(true);
    if (currentAmount || typeOper === 1) setValidqty(false);
    if (!localStock) setValidLocalStock(true);
    if (localStock || typeOper === 1) setValidLocalStock(false);

    if (
      code &&
      descriptionSale &&
      category &&
      (productType || productType === 0) &&
      sale &&
      minSale &&
      coast &&
      unit &&
      ncm &&
      
     
      (selfService ? cstReasons : true) &&
      cfop &&
      icms &&
      aliquot &&
      ipi &&
      aliquotIpi &&
      pis &&
      cofins &&
      aliquotPis &&
     
      (currentAmount || typeOper === 1) &&
      (localStock || typeOper === 1)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (code) setValidCode(false);
    if (descriptionSale) setValidDescriptionSale(false);
    if (descriptionPurchase) setValidDescriptionPurchase(false);
    if (descriptionTouch) setValidDescriptionTouch(false);
    if (category) setValidCategory(false);
    if (productType || productType === 0) setValidProductType(false);
    if (sale) setValidSale(false);
    if (minSale) setValidMinSale(false);
    if (coast) setValidCoast(false);
    if (unit) setValidUnit(false);
    if (ncm) setValidNcm(false);

    if (cfop) setValidCfop(false);
    if (icms) setValidIcms(false);
    if (aliquot) setValidAliquot(false);
    if (ipi) setValidIpi(false);
    if (aliquotIpi) setValidAliquotIpi(false);
    if (pis) setValidPis(false);
    if (cofins) setValidCofins(false);
    if (aliquotPis) setValidAliquotPis(false);
   
    if (currentAmount) setValidqty(false);
    if (localStock) setValidLocalStock(false);
  }, [
    code,
    descriptionSale,
    descriptionPurchase,
    descriptionTouch,
    category,
    productType,
    sale,
    minSale,
 
    selfService,
    coast,
    unit,
    ncm,
    cfop,
    icms,
    aliquot,
    aliquotIpi,
    pis,
    cofins,
    aliquotPis,
  
    ipi,
    currentAmount,
    localStock,
  ]);

  return (
    <ProdutoContext.Provider
      value={{
        loading,
        setLoading,
        status,
        setStatus,
        code,
        setCode,
        image,
        setImage,
        barcode,
        setBarcode,
        descriptionSale,
        setDescriptionSale,
        descriptionPurchase,
        setDescriptionPurchase,
        descriptionTouch,
        setDescriptionTouch,
        category,
        setCategory,
        vendor,
        setVendor,
        productType,
        setProductType,
        sale,
        setSale,
        minSale,
        setMinSale,
        coast,
        setCoast,
        markupPrice,
        setMarkupPrice,
        markup,
        setMarkup,

        stock_uuid,
        setStock_uuid,
        unit,
        setUnit,
        currentAmount,
        setCurrentAmount,
        minAmount,
        setMinAmount,
        localStock,
        setLocalStock,
        observation,
        setObservation,
        typeOper,
        setTypeOper,
        multiple,
        setMultiple,
        unitMeasureBuy,
        setUnitMeasureBuy,
        taxProfile,
        setTaxProfile,
        stateOrigin,
        setStateOrigin,
        stateDestiny,
        setStateDestiny,
        types,
        setTypes,
        origins,
        setOrigins,
        ncm,
        setNcm,
        cest,
        setCest,
        cfop,
        setCfop,
        customer,
        setCustomer,
        icms,
        setIcms,
        ipi,
        setIpi,
        cstReasons,
        setCstReasons,
        pis,
        setPis,
        weight,
        setWeight,
        aliquot,
        setAliquotIcms,
        mva,
        setMva,
        reduction,
        setReduction,
        icmsAliquotDeferral,
        setIcmsAliquotDeferral,
        icmsCredit,
        setIcmsCredit,
        aliquotIpi,
        setAliquotIpi,
        aliquotPis,
        setAliquotPis,
        aliquotCofins,
        setAliquotCofins,
        xdRequestQty,
        setXdRequestQty,
        xdRequestPriceWhenZero,
        setXdRequestPriceWhenZero,
        xdBalanceQty,
        setXdBalanceQty,
        xdOrderGrouped,
        setXdOrderGrouped,
        xdBillGroup,
        setXdBillGroup,
        xdAddTips,
        setXdAddTips,
        xdExcludeMinimumConsumption,
        setXdExcludeMinimumConsumption,
        xdBook,
        setXdBook,
        selfService,
        setSelfService,
        xdFollowItem,
        setXdFollowItem,
        xdPrinters,
        setXdPrinters,
        cleanInputs,
        handleEdit,
        handleDuplicate,
        handleValidete,
        validCode,
        setValidCode,
        validDescriptionSale,
        setValidDescriptionSale,
        validDescriptionPurchase,
        setValidDescriptionPurchase,
        validDescriptionTouch,
        setValidDescriptionTouch,
        validCategory,
        setValidCategory,
        validProductType,
        setValidProductType,
        validSale,
        setValidSale,
        validMinSale,
        setValidMinSale,
        validCoast,
        setValidCoast,
        validUnit,
        setValidUnit,
        validNcm,
        setValidNcm,
        validCfop,
        setValidCfop,
        validIcms,
        setValidIcms,
        validAliquot,
        setValidAliquot,
        validAliquotIpi,
        setValidAliquotIpi,
        validIpi,
        setValidIpi,
        validPis,
        setValidPis,
        validAliquotPis,
        setValidAliquotPis,
        validType,
        setValidType,
        validOrigins,
        setValidOrigins,
        validqty,
        setValidqty,
        validLocalStock,
        setValidLocalStock,
        listCode,
        setListCode,
        localStockName,
        setLocalStockName,
        setCofins,
        setValidCofins,
        cofins,
        validCofins,
        loadingListCode,
        setLoadingListCode,
        categoriesList,
        setCategoriesComplement,
       
        validcstReasons,
        setValidcstReasons,
        validOrigem,
        setValidOrigem,
        productsList,
        setProductsComplement,
        associatedComplement,
        setAssociatedComplement,
        productsAssociatedComplement,
        setProductsAssociatedComplement,
        ncm_origin,
        setNcm_origin,
      }}
    >
      {children}
    </ProdutoContext.Provider>
  );
};
