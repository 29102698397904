import DateUtils from "../../../../Services/DateUtils";
import Format from "../../../../Services/Format";
import { IChartAccount, IFinancialTransaction } from "../interface";

export const getFirstDataFinancial = () => {
  const filterList = [];
  filterList.push(`[due_dt_starts_at]=${Format.firstDate("YYYY-MM-DD")}`);
  filterList.push(
    `[due_dt_ends_at]=${Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}`,
  );
  return createFilter(filterList);
};

const createFilter = (filterList: Array<string>): string => {
  let filter = "";
  for (let i = 0; i < filterList.length; i++) {
    if (i >= 1) {
      filter += "&";
    }
    filter += `filter${filterList[i]}`;
  }
  return filter;
};

export const getEntryType = (
  response: IFinancialTransaction[],
  entrytype: number,
) => {
  const dataFilter = response.filter(
    (expense: any) => expense.relationships.category.entry_type === entrytype,
  );

  return dataFilter;
};

export const getEntryTypeChartAccount = (
  response: IChartAccount[],
  entrytype: number,
) => {
  const dataFilter = response.filter(
    (expense: any) => expense.entry_type === entrytype,
  );

  return dataFilter;
};

export const getDueMonthDate = (listData: IFinancialTransaction[]) => {
  const filtered = listData.filter((obj: IFinancialTransaction) => {
    const objDate = new Date(obj.due_date);
    const start = new Date(Format.firstDate("YYYY-MM-DD"));
    const end = new Date(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));

    return objDate >= start && objDate <= end;
  });

  return filtered;
};
