// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_containerFilter__N1s64 {
  background-color: white;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
}
.styles_containerFilter__N1s64 h3 {
  font-size: 16px;
}

.styles_containerTable__HRBdk {
  background-color: white;
  padding: 15px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
}

.styles_label__2bahF {
  margin: 0 0 5px 5px;
}

.styles_status__6bEvH {
  margin-top: 23px;
}

.styles_rowControl__-RacX {
  padding: 10px 0px 0px 0px;
}

.styles_clearButton__\\+LbFK {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles_clearButton__\\+LbFK:hover {
  background: #f44336;
  color: white;
}

.styles_addButtonCol__9ZZVp {
  display: flex;
  align-items: center;
}

.styles_clearButtonCol__8C8nw {
  display: flex;
  align-items: center;
}

.styles_loading__lfT1f {
  display: flex;
  align-items: center;
}

.styles_spaceBetween__b5piY {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/Components/Estoque/TipoMovimentacao/ListaTipoMovimentacao/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,uBAAA;EACA,aAAA;EACA,8DAAA;EACA,kBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF","sourcesContent":[".containerFilter {\n  background-color: white;\n  padding: 12px;\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n\n  h3 {\n    font-size: 16px;\n  }\n}\n\n.containerTable {\n  background-color: white;\n  padding: 15px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n}\n\n.label {\n  margin: 0 0 5px 5px;\n}\n\n.status {\n  margin-top: 23px;\n}\n\n.rowControl {\n  padding: 10px 0px 0px 0px;\n}\n\n.clearButton {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.clearButton:hover {\n  background: #f44336;\n  color: white;\n}\n\n.addButtonCol {\n  display: flex;\n  align-items: center;\n}\n\n.clearButtonCol {\n  display: flex;\n  align-items: center;\n}\n\n.loading {\n  display: flex;\n  align-items: center;\n}\n\n.spaceBetween {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFilter": `styles_containerFilter__N1s64`,
	"containerTable": `styles_containerTable__HRBdk`,
	"label": `styles_label__2bahF`,
	"status": `styles_status__6bEvH`,
	"rowControl": `styles_rowControl__-RacX`,
	"clearButton": `styles_clearButton__+LbFK`,
	"addButtonCol": `styles_addButtonCol__9ZZVp`,
	"clearButtonCol": `styles_clearButtonCol__8C8nw`,
	"loading": `styles_loading__lfT1f`,
	"spaceBetween": `styles_spaceBetween__b5piY`
};
export default ___CSS_LOADER_EXPORT___;
