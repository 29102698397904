import { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.css";
import styles from "../FormProduto/styles.module.scss";
import ReactSelect from "../../../data/reactSelect";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { ProdutoContext } from "../../../../context/product";
import OptionsReactSelect from "../../../../interfaces/OptionsReactSelect";
import Layout from "../../../data/Layout";
import { useAppSelector } from "../../../../redux/store";
import * as S from "../styled";
import { InputDefault } from "../../../data/inputDefault";
import api from "../../../../Services/api";
import globals from "./../../../../Globals.json";
import ReactSelectAsync from "../../../data/reactSelectAsync";
import InputCheckRadio from '../../../data/inputCheckRadio';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export default function InfoFiscais({
  isEdit,
  isDupli,
}: {
  isEdit: any;
  isDupli: any;
}) {
  const {
    taxProfile,
    setTaxProfile,
    stateOrigin,
    setStateOrigin,
    stateDestiny,
    setStateDestiny,
    types,
    setTypes,
    origins,
    setOrigins,
    ncm,
    setNcm,
    cest,
    setCest,
    cfop,
    setCfop,
    customer,
    setCustomer,
    icms,
    setIcms,
    ipi,
    setIpi,
    cstReasons,
    setCstReasons,
    pis,
    setPis,
    weight,
    setWeight,
    aliquot,
    setAliquotIcms,
    mva,
    setMva,
    reduction,
    setReduction,
    icmsAliquotDeferral,
    setIcmsAliquotDeferral,
    icmsCredit,
    setIcmsCredit,
    aliquotIpi,
    setAliquotIpi,
    aliquotPis,
    setAliquotPis,
    aliquotCofins,
    setAliquotCofins,
    validNcm,
    validCfop,
    validIcms,
    validAliquot,
    validAliquotIpi,
    validIpi,
    validPis,
    validAliquotPis,
   
    category,
    setCofins,
    cofins,
    validCofins,
    setXdPrinters,
    setSelfService,
    setXdFollowItem,
    ncm_origin,
    setNcm_origin
  } = useContext(ProdutoContext);

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [taxProfileList, setTaxProfileList] = useState<OptionsReactSelect[]>(
    [],
  );
  const [taxProfileListCompleted, setTaxProfileListCompleted] = useState<any[]>(
    [],
  );
  const [cate, setListCategory] = useState<OptionsReactSelect[]>([]);

  const [categoryListCompleted, setCategoryListCompleted] = useState<any[]>([]);
  const [statesList, setStatesList] = useState<OptionsReactSelect[]>([]);
  const [invoiceTypesList, setInvoiceTypesList] = useState<
    OptionsReactSelect[]
  >([]);
  const [cstOriginsList, setCstOriginsList] = useState<OptionsReactSelect[]>(
    [],
  );
  
  const [cestListObject, setCestListObject] = useState<OptionsReactSelect[]>([]);
  const [cestList, setCestList] = useState<OptionsReactSelect[]>([]);
  const [cfopList, setCfopList] = useState<OptionsReactSelect[]>([]);
  const [icmsCustomerTypesList, setIcmsCustomerTypesList] = useState<
    OptionsReactSelect[]
  >([]);
  const [icmsList, setIcmsList] = useState<OptionsReactSelect[]>([]);
  const [ipiList, setIpiList] = useState<OptionsReactSelect[]>([]);
  const [pisList, setPisList] = useState<OptionsReactSelect[]>([]);
  const [cstReasonsList, setCstReasonsList] = useState<OptionsReactSelect[]>(
    [],
  );
  const [objetNCM, setObjetNCM] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [icmsOpen, setIcmsOpen] = useState<boolean>(isDupli !== undefined || isEdit !== undefined);
  
  const completeTaxProfileInformationCategory = () => {
    const taxProfileCategory = categoryListCompleted.find(
      (item: any) => item.id === category,
    );

    taxProfileCategory && setXdPrinters(taxProfileCategory?.xd_printers);
    taxProfileCategory && setXdFollowItem(taxProfileCategory?.xd_follow_item);
    taxProfileCategory && setSelfService(taxProfileCategory?.self_service);
    const selectedTaxProfileCat = taxProfileListCompleted.find(
      (item: any) => item.id === taxProfileCategory?.tax_profile_id,
    );

    setIfoFiscais(selectedTaxProfileCat, taxProfileCategory?.tax_profile_id);

    if (!category || category === 0) {
      clearInfoFicais();
    }
  };

  const completeTaxProfileInformationTax = () => {
    if (taxProfile) {
      const selectedTaxProfile = taxProfileListCompleted.find(
        (item: any) => item.id === taxProfile,
      );

      setIfoFiscais(selectedTaxProfile);

      if (!taxProfile || taxProfile === 0) {
        clearInfoFicais();
      }

      !taxProfile && completeTaxProfileInformationCategory();
    } else {
      clearInfoFicais();
    }
  };

  const setIfoFiscais = (list: any, id?: number) => {
    id && setTaxProfile(id);
    setCest(list?.relationships?.cest?.id);
    setTypes(list?.relationships?.tax_invoice_type?.id);
    setOrigins(list?.relationships?.cst_origin?.id);
    setIcms(list?.relationships?.cst_icms?.id);
    setNcm(list?.relationships?.ncm?.id);
    setCfop(list?.relationships?.cfop?.id);
    setStateOrigin(list?.relationships?.icms_origin_state?.id);
    setStateDestiny(list?.relationships?.icms_destiny_state?.id);
    setCustomer(list?.relationships?.icms_customer_type?.id);
    setIpi(list?.relationships?.cst_ipi?.id);
    setCstReasons(list?.relationships?.cst_ipi_reason?.id ?? 129);
    setCofins(list?.relationships?.cst_cofins?.id);
    setPis(list?.relationships?.cst_pis?.id);
    setWeight(list?.tax_weight?.toString() ?? "0");
    setAliquotIcms(list?.icms_aliquot?.toString() ?? "0");
    setMva(list?.icms_mva?.toString() ?? "0");
    setReduction(list?.icms_base_calc_reduction?.toString() ?? "0");
    setAliquotIpi(list?.ipi_aliquot?.toString() ?? "0");
    setAliquotPis(list?.pis_aliquot?.toString() ?? "0");
    setAliquotCofins(list?.cofins_aliquot?.toString() ?? "0");
    setIcmsAliquotDeferral(list?.icms_aliquot_deferral?.toString() ?? "0");
    setIcmsCredit(list?.icms_credit?.toString() ?? "0");
    //getObjectNCM(list?.relationships?.ncm?.codigo)
  };

  const clearInfoFicais = () => {
    setCest(undefined);
    setTypes(undefined);
    setOrigins(undefined);
    setIcms(undefined);
    setNcm(undefined);
    setCfop(undefined);
    setStateOrigin(undefined);
    setStateDestiny(undefined);
    setCustomer(undefined);
    setIpi(undefined);
    setCstReasons(129);
    setPis(undefined);
    setCofins(undefined);
    setWeight("0");
    setAliquotIcms("0");
    setMva("0");
    setReduction("0");
    setAliquotIpi("0");
    setAliquotPis("0");
    setAliquotCofins("0");
    setIcmsAliquotDeferral("0");
    setIcmsCredit("0");
    setXdPrinters("0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0");
    setXdFollowItem(false);
    //setSelfService(false);
  };

  const getNcmByCodeOrDes = async (path: string) => {
    setCest(undefined);
    if (path.length < 3) {
      isEdit?.relationships?.ncm?.codigo &&
        getObjectNCM(isEdit?.relationships?.ncm?.codigo);
      isDupli?.relationships?.ncm?.codigo &&
        getObjectNCM(isDupli?.relationships?.ncm?.codigo);

      const res = await api.get(globals.api.taxes + "/ncms?limit=400");
      const newOject = res?.data?.data
        ?.map((item: any) => ({
          value: item.id,
          label: item.codigo + " - " + item.descricao,
          ncm_code: item?.codigo
        }))
        .slice(0, 400);;
      setObjetNCM((prev) => [...prev, ...newOject]);
      return newOject;
    } else {
      const isNumber = !isNaN(Number(path));
      const url = isNumber
        ? `${globals.api.taxes}/ncms?codigo=${path}`
        : `${globals.api.taxes}/ncms?descricao=${path}`;
      const res = await api.get(url);
      const newOject = res?.data?.data
        ?.map((item: any) => ({
          value: item.id,
          label: item.codigo + " - " + item.descricao,
          ncm_code: item?.codigo
        }))
        .slice(0, 400);;
      setObjetNCM((prev) => [...prev, ...newOject]);
      return newOject;
    }
  };
  
  const getObjectNCM = async (path: string) => {
    if (path) {
      await getNcmByCodeOrDes(path);
    }
  };

  useEffect(() => {
    GetEndPoint.categories(
      setListCategory,
      storeMe?.storeActive?.id,
      setCategoryListCompleted,
    );
    GetEndPoint.taxProfile(
      setTaxProfileList,
      storeMe?.storeActive?.id,
      setTaxProfileListCompleted,
    );
    GetEndPoint.state(setStatesList);
    GetEndPoint.invoiceTypes(setInvoiceTypesList, storeMe?.storeActive?.id);
    GetEndPoint.cstOrigin(setCstOriginsList, storeMe?.storeActive?.id);
    GetEndPoint.cests(setCestList, storeMe?.storeActive?.id);
    GetEndPoint.cfop(setCfopList, storeMe?.storeActive?.id);
    GetEndPoint.icmsCustomerTypes(
      setIcmsCustomerTypesList,
      storeMe?.storeActive?.id,
    );
    GetEndPoint.csts(
      setIcmsList,
      setIpiList,
      setPisList,
      storeMe?.storeActive?.id,
    );
    GetEndPoint.cstReasons(setCstReasonsList, storeMe?.storeActive?.id);
    getNcmByCodeOrDes("");

   
    const timer = setTimeout(() => {
      setLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);
  

  useEffect(() => {
    setTimeout(() => {
      completeTaxProfileInformationCategory();
    }, 500);
  }, [category]);

  useEffect(() => {
    setTimeout(() => {
      completeTaxProfileInformationTax();
    }, 500);
  }, [taxProfile]);

  useEffect(() => {
    const ncmSelected = objetNCM.find(item => item.value === ncm)
    setCestListObject(cestList.filter(item =>
      Number(item?.ncm_code) === Number(ncmSelected?.ncm_code)))
  }, [ncm])

  const newTaxProfileList = taxProfileList.filter(
    (item) => item.store_id === storeMe?.storeActive?.id,
  );


  const cestObjectEdit = cestList?.find(item =>
    Number(item.value) === Number(cest))
    
    useEffect(()=> {
      cestObjectEdit && setCestListObject([...cestListObject,cestObjectEdit])
    },[cestObjectEdit])
  

  return (
    <Layout title="Informações Fiscais">
      <Row>
        <p>
          {" "}
          Selecione um perfil fiscal para preencher automaticamente todas as
          informações fiscais do produto.
        </p>
      </Row>
      <S.Wrapper>
        <ReactSelect
          label="Perfil fiscal"
          placeholder="Perfil fiscal"
          isClearable
          options={newTaxProfileList}
          state={taxProfile}
          setState={setTaxProfile}
          isLoading={loading}
        />
      </S.Wrapper>
      <div className={styles.line} />
      <S.Wrapper>
        <InputDefault
          label="Peso"
          placeholder="0,00 Kg"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={weight}
          setState={setWeight}
        />

        <ReactSelect
          label="Tipo"
          placeholder="Tipo"
          isClearable
          options={invoiceTypesList}
          state={types}
          setState={setTypes}
          isLoading={loading}
        />

        <ReactSelect
          label="Origem"
          placeholder="Selecione a origem"
          isClearable
          options={cstOriginsList}
          state={origins}
          setState={setOrigins}
          isLoading={loading}
        />

        <ReactSelectAsync
          options={objetNCM}
          label="NCM"
          placeholder="Pesquise seu NCM"
          isClearable
          state={ncm}
          setState={setNcm}
          isInvalid={validNcm}
          promiseOptions={getNcmByCodeOrDes}
          isLoading={loading}
        />

        {cestListObject.length > 0 &&
          <ReactSelect
            label="CEST"
            placeholder="CEST"
            isClearable
            options={cestListObject}
            state={cest}
            setState={setCest}
            isLoading={loading}
          />
        }


        <ReactSelect
          label="CFOP"
          placeholder="CFOP"
          isClearable
          options={cfopList}
          state={cfop}
          setState={setCfop}
          isInvalid={validCfop}
          isLoading={loading}
        />
      </S.Wrapper>

      <span style={{ fontWeight: "bold", padding: "0 20px", fontSize: "13px" }}>Origem do NCM</span>
      <S.Wrapper4>

        <InputCheckRadio
          text="Nacional"
          value="Nacional"
          state={ncm_origin}
          setState={setNcm_origin}
        />
        <InputCheckRadio
          text="Importado"
          value="Importado"
          state={ncm_origin}
          setState={setNcm_origin}
        />
        <InputCheckRadio
          text="Estatal"
          value="Estatal"
          state={ncm_origin}
          setState={setNcm_origin}
        />
        <InputCheckRadio
          text="Municipal"
          value="Municipal"
          state={ncm_origin}
          setState={setNcm_origin}
        />
      </S.Wrapper4>

      <div className={styles.line} />

      <Row className="mb-2">
        <Col className="spaceBetween" style={{ cursor: 'pointer', paddingRight: '1rem' }}
             onClick={() => setIcmsOpen(!icmsOpen)}>
          <h4>Criar Regra ICMS</h4>
          <div>{!icmsOpen ? <IoIosArrowDown size={20} /> : <IoIosArrowUp size={20} />}</div>
        </Col>
      </Row>
      {
        icmsOpen &&
        <>
          <S.Wrapper>


            <ReactSelect
              label="Origem"
              placeholder="UF"
              isClearable
              options={statesList}
              state={stateOrigin}
              setState={setStateOrigin}
              isLoading={loading}
            />

            <ReactSelect
              label="Destino"
              placeholder="UF"
              isClearable
              options={statesList}
              state={stateDestiny}
              setState={setStateDestiny}
              isLoading={loading}
            />

            <ReactSelect
              label="Tip. cliente"
              placeholder="Tipo de cliente"
              isClearable
              options={icmsCustomerTypesList}
              state={customer}
              setState={setCustomer}
              isLoading={loading}
            />

            <InputDefault
              label="MVA"
              placeholder="0,00"
              type="number"
              autoComplete="off"
              step="0.01"
              min="0"
              state={mva}
              setState={setMva}
            />

            <InputDefault
              label="Diferimento"
              placeholder="0,00"
              type="number"
              autoComplete="off"
              step="0.01"
              min="0"
              state={icmsAliquotDeferral}
              setState={setIcmsAliquotDeferral}
            />

            <InputDefault
              label="Cálc. crédito"
              placeholder="0,00"
              type="number"
              autoComplete="off"
              step="0.01"
              min="0"
              state={icmsCredit}
              setState={setIcmsCredit}
            />

            <InputDefault
              label="Redu. calculo"
              placeholder="0,00"
              type="number"
              autoComplete="off"
              step="0.01"
              min="0"
              state={reduction}
              setState={setReduction}
            />


          </S.Wrapper>

        </>

      }

      <div className={styles.line} />

      <Row className="mb-2">
        <Col className="spaceBetween">
          <h4>IPI</h4>
        </Col>
      </Row>

      <S.Wrapper>
        <ReactSelect
          label="Sit. tributária"
          placeholder="Situação tributária"
          isClearable
          options={ipiList}
          state={ipi}
          setState={setIpi}
          isInvalid={validIpi}
          isLoading={loading}
        />

        <InputDefault
          label="Aliq. IPI"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquotIpi}
          setState={setAliquotIpi}
          isInvalid={validAliquotIpi}
        />

        <ReactSelect
          label="Enqu.de IPI"
          placeholder="Enquadramento de ipi"
          isClearable
          options={cstReasonsList}
          state={cstReasons}
          setState={setCstReasons}
          isLoading={loading}
        />
      </S.Wrapper>

      <div className={styles.line} />

      <Row className="mb-2">
        <Col className="spaceBetween">
          <h4>PIS e COFINS</h4>
        </Col>
      </Row>

      <S.Wrapper2>
        <ReactSelect
          label="CTS Saída"
          placeholder="CTS Saída"
          isClearable
          options={pisList}
          state={pis}
          setState={setPis}
          isInvalid={validPis}
          isLoading={loading}
        />

        <InputDefault
          label="Alíquota"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquotPis}
          setState={setAliquotPis}
          isInvalid={validAliquotPis}
        />

        <ReactSelect
          label="CTS Saída"
          placeholder="CTS Saída"
          isClearable
          options={pisList}
          state={cofins}
          setState={setCofins}
          isInvalid={validCofins}
          isLoading={loading}
        />

        <InputDefault
          label="Alíquota"
          placeholder="0,00"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquotCofins}
          
          setState={setAliquotCofins}
        />

        <ReactSelect
          label="CST/CSON"
          placeholder="CSOSN(Código de situação de operação do Simples Nacional)"
          isClearable
          options={icmsList}
          state={icms}
          setState={setIcms}
          isInvalid={validIcms}
          isLoading={loading}
        />

        <InputDefault
          label="Alíquota"
          placeholder="Alíquota do ICMS do Simples Nacional"
          type="number"
          autoComplete="off"
          step="0.01"
          min="0"
          state={aliquot}
          setState={setAliquotIcms}
          isInvalid={validAliquot}
        />

      </S.Wrapper2>
    </Layout>
  );
}
