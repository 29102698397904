import { IFormInventary } from "../../../../Pages/Cadastro/CadInventario";
import * as Yup from "yup";
import { inventarySchema } from "../../../../redux/features/inventary/schema";
import { SubmitHandler, useForm } from "react-hook-form";
import LayoutHookForm from "../../../reactHookForm/LayoutForm";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { InputClone } from "./InputClone";
import { useEffect, useState } from "react";

import { notify, notifyError } from "../../../notify/notify-component";

import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../../Loading/Loading";
import {
  createCountAsync,
  editeCountAsync,
  getCountAsync,
  getCountByUuidAsync,
} from "../../../../redux/features/count/services";
import type { IProduct } from "../../../../redux/features/count/interface";
import {
  addItensTable,
  deleteAllItensTable,
  setIncludeInactiveProductsFalse,
  setStateForm,
  setUuidEdit,
} from "../../../../redux/features/count/count-slice";

type FormData = Yup.InferType<typeof inventarySchema>;

interface IFormInput {
  store: string;
  stock: string;
  type: string;
}

export function FormContagem({
  OnAddNewInventary,
  title,
  editando,
  inventaryData,
}: IFormInventary) {
  const dispatch = useDispatch<AppDispatch>();
  const count = useAppSelector(({ countReducer }) => countReducer.value);
  const isDisable =
    count.InventaryByUuid?.status === 2 || count.InventaryByUuid?.status === 3;
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(inventarySchema),
  });
  const inputError = [errors];

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (count.itensTable.length === 0) {
      notifyError("Adicione pelo menos um produto.");
      return;
    }
    dispatch(setStateForm({ state: "loading" }));

    const newData = {
      store_id: data?.store?.value
        ? parseInt(data?.store?.value)
        : storeMe.user.relationships.stores[0].id,
      stock_id: data?.stock?.value
        ? parseInt(data?.stock?.value)
        : count?.stock[0]?.id,
      name: data.name,
      data: data?.stock.name,
      type: 1,
      products: count.itensTable,
    };

    if (!editando) {
      dispatch(createCountAsync(newData)).then((result: any) => {
        if (createCountAsync.fulfilled.match(result)) {
          handleCancel();
        } else {
          notifyError("Erro ao registrar contagem.");
          dispatch(setStateForm({ state: "error" }));
        }
      });
    } else {
      const newDataPut = {
        ...inventaryData,
        stock_id: data?.stock?.value
          ? parseInt(data?.stock?.value)
          : count?.stock[0]?.id,
        name: data.name,
        data: data?.stock.name,
        type: 1,
      };
      dispatch(editeCountAsync(newDataPut)).then((result: any) => {
        if (editeCountAsync.fulfilled.match(result)) {
          handleCancel();
        } else {
          notifyError("Erro ao editar contagem.");
          dispatch(setStateForm({ state: "error" }));
        }
      });
    }
  };

  const handleCancel = () => {
    dispatch(deleteAllItensTable());
    reset();
    OnAddNewInventary(false);
    dispatch(setStateForm({ state: "default" }));
    dispatch(setUuidEdit({}));
    dispatch(setIncludeInactiveProductsFalse());
  };

  useEffect(() => {
    if (!inventaryData.uuid) return;
    dispatch(getCountByUuidAsync(inventaryData.uuid));
  }, [dispatch, inventaryData.uuid]);

  useEffect(() => {
    dispatch(deleteAllItensTable());

    if (
      count?.InventaryByUuid &&
      count?.InventaryByUuid?.relationships?.products?.length > 0
    ) {
      for (const value of count.InventaryByUuid.relationships?.products) {
        const getProduct = count?.allProductsTable?.find(
          (item: IProduct) => item?.id === value?.product_id,
        );

        const newItem = {
          ...getProduct,
          product_id: getProduct?.id,
          qty: value.qty,
        };
        dispatch(addItensTable({ ...newItem, uuidTable: value.uuid }));
      }
    }
  }, [count.InventaryByUuid, count?.allProductsTable]);

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={
          !editando ? "Criar Contagem de Estoque" : "Editar Contagem de Estoque"
        }
        isEditable={editando}
        onClickCancel={handleCancel}
        stateForm={count.stateForm}
        isDisable={isDisable}
      >
        <InputClone
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
          setError={setError}
          errors={inputError}
          reset={reset}
          isEditable={editando}
          inventaryData={inventaryData}
        />
      </LayoutHookForm>

      {count.stateForm.state === "loading" && <Loading />}
    </>
  );
}
