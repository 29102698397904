// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imageContainer label {
  font-size: 9px !important;
  margin-top: 0.5rem;
}
.imageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  position: relative;

  button {
    position: absolute;
    right: -50px;
  }
}

.dragImg {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.dragImg img {
  height: 130px;
  width: 100px;
  object-fit: contain;
  border: 1px solid #cbcbcb;
}
img:hover {
  box-shadow: 0 0 2px 1px rgba(126, 213, 241, 0.5);
}
#error {
  margin-top: 10px;
  color: red;
  font-size: 11px;
}
.imageSelect {
  position: relative;
  font-size: 11px;
}
.btnDeleteImg {
  /* position: absolute;
z-index: 2;
top:0px;
right: 5px; */
}
`, "",{"version":3,"sources":["webpack://./src/Components/DragDropImage/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,kBAAkB;;EAElB;IACE,kBAAkB;IAClB,YAAY;EACd;AACF;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,gDAAgD;AAClD;AACA;EACE,gBAAgB;EAChB,UAAU;EACV,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE;;;aAGW;AACb","sourcesContent":[".imageContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.imageContainer label {\n  font-size: 9px !important;\n  margin-top: 0.5rem;\n}\n.imageHeader {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n  position: relative;\n\n  button {\n    position: absolute;\n    right: -50px;\n  }\n}\n\n.dragImg {\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n}\n.dragImg img {\n  height: 130px;\n  width: 100px;\n  object-fit: contain;\n  border: 1px solid #cbcbcb;\n}\nimg:hover {\n  box-shadow: 0 0 2px 1px rgba(126, 213, 241, 0.5);\n}\n#error {\n  margin-top: 10px;\n  color: red;\n  font-size: 11px;\n}\n.imageSelect {\n  position: relative;\n  font-size: 11px;\n}\n.btnDeleteImg {\n  /* position: absolute;\nz-index: 2;\ntop:0px;\nright: 5px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
