export function CheckCPF(strCPF: string) {
  strCPF = strCPF.replace(/\D/g, "");
  let Soma;
  let Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function CheckCNPJ(cnpj: any) {
  const value = cnpj?.replace(/\D/g, "");

  if (cnpj?.length === 18) {
    if (value?.length !== 14 || /^(\d)\1+$/.test(value)) return false;

    let length = value.length - 2;
    let numbers = value.substring(0, length);
    const digits = value.substring(length);
    let summ = 0;

    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
      summ += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let result = summ % 11 < 2 ? 0 : 11 - (summ % 11);
    if (result != digits.charAt(0)) return false;

    length = length + 1;
    numbers = value.substring(0, length);
    summ = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
      summ += numbers.charAt(length - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = summ % 11 < 2 ? 0 : 11 - (summ % 11);

    if (result != digits.charAt(1)) return false;

    return true;
  }

  return false;
}

export const convertDatePTbr = (data: string) => {
  const splitData = data.split("-");

  return `${splitData[2]}/${splitData[1]}/${splitData[0]}`;
};
