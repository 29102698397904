import { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { ThemeContext } from "../../../context/theme-context";
import ListaPessoa from "../../../Components/Cadastro/Pessoa/ListaPessoa";
import IPessoaService from "../../../Components/Cadastro/Pessoa/Interface/IPessoaService";
import { FormPessoa } from "../../../Components/Cadastro/Pessoa/FormPessoa";
import GetEndPoint from "../../../Services/GetEndPoint";

export default function CadPessoa() {
  const { collapsed } = useContext(ThemeContext);

  const [cad, setCad] = useState<boolean>(false);
  const [toEditPeople, setToEditPeople] = useState<{}>({});
  const [editando, setEditando] = useState<boolean>(false);
  const [stateOptions, setStateOptions] = useState();

  const handleNew = (e: boolean) => {
    setEditando(false);
    setToEditPeople({});
    setCad(e);
  };

  const handleEdit = (props: IPessoaService) => {
    setToEditPeople(props);
    setCad(true);
    setEditando(true);
  };

  useEffect(() => {
    GetEndPoint.state(setStateOptions);
  }, []);

  const selectOptions = {
    state: stateOptions,
  };

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaPessoa
            Title="Transportadoras"
            Path="carrier"
            OnAddNewCli={(e: any) => {
              handleNew(e);
            }}
            onEditItem={(item: IPessoaService) => handleEdit(item)}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormPessoa
            Title="Transportador"
            Path="carrier"
            OnAddNewPeople={(e: boolean) => handleNew(e)}
            people={toEditPeople}
            editando={editando}
            selectOptions={selectOptions}
          />
        </Container>
      )}
    </>
  );
}
