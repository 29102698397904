import { Button, Modal } from "react-bootstrap";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "../../../../../redux/store";
import tableStyleDefaultBolder from "../../../../../Services/tableStyleDefaultBolder";
import DataTable from "react-data-table-component";
import api from "../../../../../Services/api";
import Globals from "../../../../../Globals.json";
import CustomLoader from "../../../../data/customLoader";
import { IoIosAddCircle } from "react-icons/io";
import { paginationOptions } from "../../../../data/PagineteInfo";
import { notify, notifyError } from "../../../../notify/notify-component";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setAssociatedComplement: Dispatch<SetStateAction<any[]>>;
  associatedComplement: any;
}

export default function ModalADD({
  show, setShow, associatedComplement, setAssociatedComplement,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const [productsList, setProductsList] = useState<Array<any>>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [clearRows, setClearRows] = useState<boolean>(false);
  const [addInBuçk, setAddInBulk] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const columns: any = [
    {
      name: "Código",
      sortable: true,
      width: "100px",
      center: true,
      selector: (row: any) => row.code,
    },
    {
      name: "Produto",
      minWidth: "190px",
      sortable: true,
      selector: (row: any) => row.sale_name?.toLowerCase(),
    },
    {
      name: "Adicionar",
      cell: (row: any) => (
        <div className="actions">
          <IoIosAddCircle
            size={20}
            data-toggle="tooltip"
            data-placement="top"
            title="Adicionar"
            className="action"
            onClick={() => handleAdd(row)}
            style={{ marginRight: 16 }}
            color="#3C7EDF"
          />
        </div>
      ),
      button: true,
    },
  ];

  const handleClose = () => {
    setShow(false);
    setSelectedRows([]);
  };

  const getData = async () => {
    setLoading(true);
    const uri1 = `?paginate=false&append=stock_qty&orderBy=created_at&order=desc&
      store_id=${storeMe?.storeActive?.id}&filter[product_type_id]=1`;
    
    try {
      const res1 : any = await api.get(Globals.api.products + `${uri1}`)

      setProductsList(res1?.data?.data);
    } catch (error:any) {
      notifyError(error ?? "Erro ao buscas produtos");
    } finally {
      setLoading(false);
    }

  };

  const handleAdd = (complement: any) => {
    const alreadyExists = associatedComplement?.find(
      (item: any) => item?.id === complement?.id,
    );

    if (alreadyExists) {
      notify("Associado já adicionado.");
      return;
    }
    setAssociatedComplement((prevent: any) => [...prevent, complement]);
  };

  const handleRowsChange = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
    setClearRows(false);

    if (state.selectedRows.length > 0) {
      setAddInBulk(true);
    }
  }, []);

  const handleAddInBulk = () => {
    if (associatedComplement?.length > 0) {
      for (let index = 0; index < selectedRows?.length; index++) {
        const alreadyExists = associatedComplement?.find(
          (item: any) => item?.id === selectedRows[index]?.id,
        );

        if (alreadyExists) {
          return;
        } else {
          setAssociatedComplement((prevent: any) => [
            ...prevent,
            selectedRows[index],
          ]);
          handleClose();
        }
      }
    } else {
      setAssociatedComplement(selectedRows);
      handleClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Novo Associado</h3>
        </Modal.Title>
      </Modal.Header>
      <div>
        <Modal.Body>
          <DataTable
            columns={columns}
            data={
              productsList?.filter((item: any) => item.product_type_id === 1) ||
              []
            }
            striped
            onSelectedRowsChange={handleRowsChange}
            clearSelectedRows={clearRows}
            selectableRows
            pagination
            paginationDefaultPage={1}
            paginationPerPage={10}
            customStyles={tableStyleDefaultBolder}
            paginationComponentOptions={paginationOptions}
            progressPending={loading}
            progressComponent={<CustomLoader />}
            noDataComponent="Nenhuma informação foi encontrada."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {selectedRows?.length > 0 && (
            <Button variant="primary" onClick={handleAddInBulk}>
              Salvar
            </Button>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
}
