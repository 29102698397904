import "./styles.scss";
import styles from "./styles.module.scss";
import { useContext, useEffect, useRef, useState } from "react";
import Logo from "../../../images/ERP_Cloud_Logo.png";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Card, Button } from "react-bootstrap/";
import { useReactToPrint } from "react-to-print";
import { StoreContext } from "../../../../context/store-context";
import { ImprimirContext } from "../../../../context/print-receipt";
import { useAppSelector } from "../../../../redux/store";

export function Imprimir(props: any) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const storeData = storeMe.storeActive;

  const {
    received,
    receivedMoviPrint,
    valueRef,
    referent,
    date,
    handleCancelar,
  } = useContext(ImprimirContext);

  const local =
    storeData.address_street +
    ", " +
    storeData.address_number +
    ", " +
    storeData.city +
    ", " +
    storeData.address_zip_code +
    ", " +
    storeData.city +
    "-" +
    storeData.state;

  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({ content: () => componentRef.current });

  const dayOfMonth = date[5] + date[6];

  const getMonth = (date: string) => {
    switch (date) {
      case "01":
        return "Janeiro";
      case "02":
        return "Fevereiro";
      case "03":
        return "Março";
      case "04":
        return "Abril";
      case "05":
        return "Maio";
      case "06":
        return "Junho";
      case "07":
        return "Julho";
      case "08":
        return "Agosto";
      case "09":
        return "Setembro";
      case "10":
        return "Outubro";
      case "11":
        return "Novembro";
      case "12":
        return "Dezembro";
    }
  };

  return (
    <>
      <div className={styles.containerFilter}>
        <Card.Body>
          <Row className="mb-9">
            <h3> {props.Title} </h3>
          </Row>
          <div className={styles.section} ref={componentRef}>
            <div className={styles.containerTop}>
              <div className={styles.wrapperLeft}>
                <img className={styles.image} src={Logo} alt="Logo" />
              </div>
              <div className={styles.wrapperRight}>
                <div className={styles.name}>{storeData.company_name}</div>
                <div>{storeData.cnpj}</div>
                <div>{local}</div>
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.containerCenter}>
              <span>RECIBO</span>
              <span>
                R${" "}
                {valueRef
                  ? Number(valueRef)?.toFixed(2).replace(".", ",")
                  : "0,00"}
              </span>
            </div>
            <div className={styles.line} />
            <div>
              <p>
                Recebi(emos) de{" "}
                {received
                  ? received.toUpperCase()
                  : receivedMoviPrint.toUpperCase()}{" "}
                a quantia de{" "}
                {valueRef
                  ? Number(valueRef)?.toFixed(2).replace(".", ",")
                  : "0,00"}{" "}
                REAIS referente a/ao {referent.toUpperCase()}
              </p>
              <div className={styles.wrapperBottom}>
                {storeData.city +
                  "-" +
                  storeData.state +
                  ", " +
                  date[8] +
                  date[9] +
                  " de " +
                  getMonth(dayOfMonth) +
                  " de " +
                  date[0] +
                  date[1] +
                  date[2] +
                  date[3]}
              </div>
              <div className={styles.containerBottom}>
                <div className={styles.smallLine} />
                <div>{storeData.company_name}</div>
                <div>{storeData.cnpj}</div>
              </div>
            </div>
            <div className={styles.line} />
            <p className={styles.site}>www.web3-erp-cloud.com.br</p>
          </div>
        </Card.Body>
        <Row className="mb-3">
          <Col md="12">
            <div className="btnSpaceBetween mt-6">
              <Button
                size="sm"
                type="button"
                variant="secondary"
                onClick={() => handleCancelar(props)}
              >
                voltar
              </Button>
              <Button
                size="sm"
                variant="primary"
                type="button"
                onClick={handlePrint}
              >
                Imprimir
              </Button>
            </div>
            <br />
          </Col>
        </Row>
      </div>
    </>
  );
}
