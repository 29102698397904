import { IInventary } from "../interface";

export function findMostRecentItem(items: IInventary[]): IInventary | null {
  if (items.length === 0) {
    return null;
  }

  let mostRecentItem = items[0];

  for (let i = 1; i < items.length; i++) {
    const currentItem = items[i];
    if (currentItem.can_chargeback) {
      const currentCreatedAt = new Date(
        currentItem.relationships.entryMovement?.created_at,
      );
      const mostRecentCreatedAt = new Date(
        mostRecentItem.relationships.entryMovement?.created_at,
      );
      if (currentCreatedAt > mostRecentCreatedAt) {
        mostRecentItem = currentItem;
      }
    }
  }

  return mostRecentItem;
}

export const getType = (response: IInventary[], entrytype: number) => {
  const dataFilter = response.filter(
    (expense: IInventary) => expense.type === entrytype,
  );

  return dataFilter;
};
