// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnSpaceBetween {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #009ef7 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #181c32 !important;
}

.nav-tabs .nav-link {
  font-size: 14px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Cadastro/CadTransfEstoque/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAiCA;;EAEE,yBAAA;AA9BF;;AAmCA;;EAEE,yBAAA;AAhCF;;AAmDA;EACE,eAAA;EACA,gBAAA;AAhDF","sourcesContent":[".btnSpaceBetween {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 30px;\n}\n\n// .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {\n//   font-size: 15px !important;\n//   font-weight: 500;\n//   border-color: none !important;\n//   border: none !important;\n//   outline: none !important;\n// }\n// .nav-tabs .nav-link {\n//   font-size: 15px !important;\n//   font-weight: 500;\n//   outline: none !important;\n// }\n\n// .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {\n//   background-color: #fff !important;\n//   border-color: none !important;\n//   border: none !important;\n//   outline: none !important;\n// }\n\n// .nav-tabs {\n//   border: none !important;\n//   border-bottom: none !important;\n//   outline: none !important;\n// }\n\n// .nav-tabs .nav-link:hover{\n//   color: #181c32 !important;\n//   outline: none !important;\n// }\n\n.nav-tabs .nav-item.show .nav-link,\n.nav-tabs .nav-link.active {\n  color: #009ef7 !important;\n  // background-color: #fff !important;\n  // border-color: none !important;\n}\n\n.nav-tabs .nav-item.show .nav-link,\n.nav-tabs .nav-link {\n  color: #181c32 !important;\n}\n\n.nav-tabs .nav-link.active,\n.nav-tabs .nav-item.show .nav-link {\n  // border-color: transparent !important;\n}\n\n.nav-tabs {\n  // border-bottom: none !important;\n  // font-size: 50px !important;\n  // margin-left: 16px;\n  // margin-top: 14px;\n}\n\n.nav-tabs .nav-link:hover {\n  // color: #181c32 !important;\n}\n\n.nav-tabs .nav-link {\n  font-size: 14px;\n  font-weight: 600;\n  // border-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
