// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_containerFilter__5jaZZ {
  background-color: white;
  padding: 12px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 1px -1px rgba(164, 166, 163, 0.9803921569);
  border-radius: 5px;
}
.styles_containerFilter__5jaZZ h3 {
  font-size: 16px;
}

.styles_label__kUGs0 {
  margin: 0 0 5px 5px;
}

.styles_status__AwIiO {
  margin-top: 23px;
}

.styles_rowControl__CFMQc {
  padding: 10px 0px 0px 0px;
}

.styles_clearButton__I14TV {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles_clearButton__I14TV:hover {
  background: #f44336;
  color: white;
}

.styles_addButtonCol__LWN13 {
  display: flex;
  align-items: center;
}

.styles_clearButtonCol__iE3o1 {
  display: flex;
  align-items: center;
}

.styles_loading__pU\\+Zt {
  display: flex;
  align-items: center;
}

.styles_spaceBetween__2hgVC {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/Components/Estoque/LocaisEstoque/ListaEstoque/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,8DAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,mBAAA;EACA,YAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,aAAA;EACA,8BAAA;AAAF","sourcesContent":[".containerFilter {\n  background-color: white;\n  padding: 12px;\n  margin-bottom: 10px;\n  box-shadow: 1px 1px 1px -1px #a4a6a3fa;\n  border-radius: 5px;\n\n  h3 {\n    font-size: 16px;\n  }\n}\n\n.label {\n  margin: 0 0 5px 5px;\n}\n\n.status {\n  margin-top: 23px;\n}\n\n.rowControl {\n  padding: 10px 0px 0px 0px;\n}\n\n.clearButton {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.clearButton:hover {\n  background: #f44336;\n  color: white;\n}\n\n.addButtonCol {\n  display: flex;\n  align-items: center;\n}\n\n.clearButtonCol {\n  display: flex;\n  align-items: center;\n}\n\n.loading {\n  display: flex;\n  align-items: center;\n}\n\n.spaceBetween {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFilter": `styles_containerFilter__5jaZZ`,
	"label": `styles_label__kUGs0`,
	"status": `styles_status__AwIiO`,
	"rowControl": `styles_rowControl__CFMQc`,
	"clearButton": `styles_clearButton__I14TV`,
	"addButtonCol": `styles_addButtonCol__LWN13`,
	"clearButtonCol": `styles_clearButtonCol__iE3o1`,
	"loading": `styles_loading__pU+Zt`,
	"spaceBetween": `styles_spaceBetween__2hgVC`
};
export default ___CSS_LOADER_EXPORT___;
