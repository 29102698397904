import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import { ThemeContext } from "../../../context/theme-context";
import ListaProdutos from "../../../Components/Cadastro/Produtos/ListaProdutos";
import FormProduto from "../../../Components/Cadastro/Produtos/FormProduto";
import { ProdutoProvider } from "../../../context/product";

export default function CadProdutos() {
  const { collapsed } = useContext(ThemeContext);

  const [level, setLevel] = useState<number>(0);
  const [editando, setEditando] = useState<boolean>(false);
  const [duplicar, setDuplicar] = useState<boolean>(false);
  const [produto, setProduto] = useState<any>();
  const [ifDuplicate, setIfDuplicate] = useState<boolean>(false);

  const cancelAction = () => {
    setLevel(0);
    setEditando(false);
    setDuplicar(false);
    setIfDuplicate(false);
    setProduto(undefined);
  };

  return (
    <ProdutoProvider>
      {level === 0 ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaProdutos
            onNewProduct={() => setLevel(1)}
            onEditItem={(item: any) => setProduto(item)}
            onEditando={(eh: boolean) => setEditando(eh)}
            onDuplicar={(eh: boolean) => setDuplicar(eh)}
            setIfDuplicate={setIfDuplicate}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormProduto
            duplicar={duplicar}
            editando={editando}
            produto={produto}
            onCancelAction={() => cancelAction()}
            ifDuplicate={ifDuplicate}
          />
        </Container>
      )}
    </ProdutoProvider>
  );
}
