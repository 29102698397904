import { defaultThemes } from "react-data-table-component";

const tableStyleCustom: any = {
  table: {
    style: {
      minHeight: "50vh",
      borderRadius: "10px",
      overflow: "hidden",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
    },
  },

  headRow: {
    style: {
      backgroundColor: "#fff",
      //borderBottom: "1px solid #ccc",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      height: "60px",
    },
  },

  headCells: {
    style: {
      textAlign: "center",
      fontSize: "13px",
      textTransform: "capitalize",
      padding: "15px",
    },
  },

  cells: {
    style: {
      padding: "15px",
      //border: "1px solid #ddd",
      fontSize: "12px",
      textAlign: "center",
    },
  },

  rows: {
    style: {
      //borderBottom: "1px solid #ddd",
      "&:hover": {
        backgroundColor: "#e3f2fd",
      },
      height: "70px",
    },
  },
};

export default tableStyleCustom;
