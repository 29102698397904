import { useState } from "react";
import { StateForm } from "../../interfaces/state-form";

import * as Yup from "yup";

import Spinner from "../../Components/spinner/Spinner";
import * as S from "./styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import Globals from "../../Globals.json";
import api from "../../Services/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputHookDefal } from "../../Components/reactHookForm/InputHookDefal";
import { toast, ToastContainer } from "react-toastify";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const createPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Por favor, insira uma senha."),
  password_confirmation: Yup.string()
    .required("Por favor, confirme a senha.")
    .oneOf([Yup.ref<any>("password"), null], "As senhas devem ser iguais."),
});

interface IFormInput {}
type FormData = Yup.InferType<typeof createPasswordSchema>;

interface ILogin {
  email: string;
}

export default function Recovery() {
  const history = useHistory();

  const query = useQuery();

  const token = query.get("token");
  const email = query.get("email");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(createPasswordSchema),
  });
  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      setStateForm({ state: "loading" });

      const dataObj = {
        token,
        email,
        password: data.password,
        password_confirmation: data.password_confirmation,
      };

      await api.post(Globals.api.returnResetPassword, dataObj);
      toast.success("Senha criada/alterada com sucesso.");

      setTimeout(() => {}, 2000);
      history.push(Globals.paths.login);
      setStateForm({ state: "send" });
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (erroHandler.length > 0) {
        setStateForm({
          state: "error",
          message: erroHandler[0] || "api_error_please_contact_support",
        });

        return;
      }
    }
  };

  const inputError = [errors];

  return (
    <S.Container>
      <S.Login>
        <S.Card>
          <h2>Alterar senha</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            {stateForm.state === "error" && (
              <p className="error">{stateForm.message}</p>
            )}
            <InputHookDefal
              label="Nova senha"
              name="password"
              type="password"
              placeholder="Senha"
              register={register}
              errors={inputError}
            />
            <InputHookDefal
              label="Confirmar senha"
              name="password_confirmation"
              type="password"
              placeholder="Confirmar senha"
              register={register}
              errors={inputError}
            />
            {
              <S.Button>
                {stateForm.state === "loading" ? (
                  <S.PositionSpinner>
                    <Spinner button />
                  </S.PositionSpinner>
                ) : (
                  "Enviar"
                )}
              </S.Button>
            }
            <div className="forgot">
              <span>
                <Link to={Globals.paths.login}>Voltar para login</Link>
              </span>
            </div>
          </form>
        </S.Card>
      </S.Login>
      <ToastContainer
        style={{ width: "auto" }}
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        //transition: Bounce,
      />
    </S.Container>
  );
}
