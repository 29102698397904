import globals from '../Globals.json';
import api from './api';

export const getProducts = () => {
  return api.get(`${globals.api.products}`);
};

export const getStockableProducts = (store_id) => {
  return api.get(
    `${globals.api.products}?paginate=false&append=stock_qty&store_id=${store_id}&filter[is_stockable]=true`
  );
};

export const getProductStockQty = (uuid) => {
  return api.get(`${globals.api.products}/${uuid}?append=stock_qty`);
};

export const getAllProducts = () => {
  return api.get(`${globals.api.products}?paginate=false`);
};
