import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";

export const getLocalStockAsync = createAsyncThunk(
  "get/payment-type",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.stocks + `?paginate=false&store_id=${storeActivedId}`,
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createLocalStockAsync = createAsyncThunk(
  "create/payment-type",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.stocks, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateLocalStockAsync = createAsyncThunk(
  "update/payment-type",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.stocks + `/${data.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteLocalStockAsync = createAsyncThunk(
  "delete/payment-type",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.stocks + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
