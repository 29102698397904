import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getToken } from "./auth";



const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// Interceptador para adicionar token de autorização e X-Store ao cabeçalho da requisição
api.interceptors.request.use((config: any) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["X-Store"] = localStorage.getItem("X-Store");
  }
  return config;
});

// Interceptador para lidar com erros de resposta
api.interceptors.response.use(
  (response) => {
    const token = getToken();

    if (token) {
      response.headers["Authorization"] = `Bearer ${token}`;
    }

    return response;
  },
  (error) => {
    if (error.response) {
      return Promise.reject( {
        status: error.response.status,
        message: error.response.data.message,
        details: error.response.data.errors,
      })
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
