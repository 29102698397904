export default class FilterBy {
  static findName(listData: any, id: number) {
    const data = [listData.find((product: any) => product?.id === id)];
    return data;
  }

  static date(listData: any, startDate: any, endDate: any) {
    const filtered = listData.filter((obj: any) => {
      const objDate = new Date(obj.created_at);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return objDate >= start && objDate <= end;
    });

    return filtered;
  }
}
