import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/theme-context";
import { Container } from "react-bootstrap";
import { IFinancialTransaction } from "../../../redux/features/financialTransaction/interface";
import { ListaFinanceiro } from "../../../Components/Financeiro/TrancacaoFinanceira/ListaFinanceiro";
import { FormTransacao } from "../../../Components/Financeiro/TrancacaoFinanceira/FormTransacao";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../redux/store";
import {
  getBanksAsync,
  getfinancialTransactionAsync,
} from "../../../redux/features/financialTransaction/services";

export interface IListFinancial {
  onEditItem: (item: IFinancialTransaction) => void;
  onAddNewCli: (e: boolean) => void;
  financialData?: any;
  entryType: number;
  title: string;
  dispatch: any;
}

export interface IFormFinancial {
  title: string;
  OnAddNewFinancial: (e: boolean) => void;
  financialData?: any;
  entryType: number;
  editando: boolean;
  dispatch: any;
}

export default function CadFinanceiroDespesa() {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const { collapsed } = useContext(ThemeContext);
  const [cad, setCad] = useState<boolean>(false);
  const [toEditFinancial, setToEditFinancial] =
    useState<IFinancialTransaction>();
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: boolean) => {
    setEditando(false);
    setToEditFinancial(undefined);
    setCad(e);
  };

  const handleEdit = (props: IFinancialTransaction) => {
    setToEditFinancial(props);
    setCad(true);
    setEditando(true);
  };

  const handleClearCancelBtn = (e: any) => {
    setToEditFinancial(undefined);
  };

  useEffect(() => {
    dispatch(getfinancialTransactionAsync(storeMe?.storeActive?.id));
    dispatch(getBanksAsync(storeMe?.storeActive?.id));
  }, [dispatch]);

  return (
    <>
      {!cad ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <ListaFinanceiro
            title="Despesa"
            dispatch={dispatch}
            entryType={0}
            onEditItem={(item: IFinancialTransaction) => handleEdit(item)}
            financialData={toEditFinancial}
            onAddNewCli={(e: boolean) => {
              handleNew(e);
            }}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <FormTransacao
            Title="Adicionar Movimentação Financeira Despesa"
            EntryType={0}
            dispatch={dispatch}
            Label="Pagmaneto"
            Placeholder="Forma de Pagamento"
            Path="financial-transaction"
            onAddNewTransaction={(efinancial: boolean) => {
              handleNew(efinancial);
            }}
            OnClear={(efinancial: boolean) => {
              handleClearCancelBtn(efinancial);
            }}
            transacao={toEditFinancial}
            editando={editando}
          />
        </Container>
      )}
    </>
  );
}
