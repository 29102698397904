import { notifyError } from "../Components/notify/notify-component";
import globals from "../Globals.json";
import api from "./api";

export const createTransactionCategories = async (
  objTransactionCategories: any,
) => {
  if (objTransactionCategories.uuid) {
    return api
      .put(
        `${globals.api.transactionCategories}/${objTransactionCategories.uuid}`,
        objTransactionCategories,
      )
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  } else {
    return api
      .post(`${globals.api.transactionCategories}`, objTransactionCategories)
      .then((res: any) => {
        if (res.status === 201 || res.status === 200) {
          const objCallback = {
            data: res.data.data,
            status: true,
          };
          return objCallback;
        }
      })
      .catch((error: any) => {
        notifyError(error);
      });
  }
};

export const getTransactionCategories = (store_id: number) => {
  return api.get(
    `${globals.api.transactionCategories}?paginate=false&store_id=${store_id}`,
  );
};
