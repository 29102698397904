import { ISelfServiceComponente } from '../../../../Pages/Cadastro/CadSelfService';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import { getCategoriesAsync, getProductByCategoryAsync, editeProductSelfServiceAsync } from '../../../../redux/features/selfService/services';
import { selfServiceSchema } from '../../../../redux/features/selfService/schema';
import LayoutHookFilter from './LayoutFilter';

import LayoutHookTable from '../../../reactHookForm/LayoutTable';
import DataTable from 'react-data-table-component';
import { paginationOptions } from '../../../data/PagineteInfo';
import CustomLoader from '../../../data/customLoader';
import { ISelfServiceProduct } from '../../../../redux/features/selfService/interface';
import tableStyleDefault from './tableStyleSelfService';

import { notifyError } from '../../../notify/notify-component';
import { InputFilter } from './InputFilter';
import Loading from '../../../Loading/Loading';
import { Btn } from '../../../data/Btn';

import { ModalAPP } from './Modal';


interface IFormInput {}

export function SelfServiceComponente({ title }: ISelfServiceComponente) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { loading, products,  perPage, totalItems, page } = useAppSelector(({ selfServiceReducer }) => selfServiceReducer.value);
  const [filter, setFilter] = useState("");
  
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(selfServiceSchema) });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [loadingSelecteds, setLoadingSelecteds] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(page ? page :1);
  const [selectedRows, setSelectedRows] = useState<ISelfServiceProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ISelfServiceProduct>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>();

  const handleClose = () => {
    setLogo(undefined)
    setOpenModal(false);
  }
  const handleShow = (row:ISelfServiceProduct) => {
    setSelectedProduct(row)
    setOpenModal(true);
    
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    const filterList = [];
  
   
    if (data?.category?.value)
      filterList?.push(`[product_category_id]=${data?.category?.value}`);
   
    if (filterList?.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id, page: currentPage, filter: filter }));
        setFilter(filter);
      }
      return;
    }
    dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id, page: currentPage, filter: filter }));
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `${filterList[i]}`;
    }
    return filter;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const handleToggleSelfService = async (row: ISelfServiceProduct) => {
   
    try {
      await dispatch(editeProductSelfServiceAsync({uuid: row.uuid, self_service: !row.self_service}));
      dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id,
        page: currentPage, filter: filter }));
    } catch (error) {
      notifyError("Erro ao atualizar produto");
      console.error(error);
    } 
  };

  const handleToggleIsActived = async (row: ISelfServiceProduct) => {
   
    try {
      await dispatch(editeProductSelfServiceAsync({uuid: row.uuid, is_active: !row.is_active,}));
      dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id,
        page: currentPage, filter: filter }));
    } catch (error) {
      notifyError("Erro ao atualizar produto");
      console.error(error);
    } 
  };

  const clearFilter = () => {
    reset();
    setValue("category", {})
    dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id,
      page: 1 }));
  };

  // Função para lidar com a seleção de produtos na tabela
  const handleRowSelected = ({ selectedRows }: { selectedRows: ISelfServiceProduct[] }) => {
    setSelectedRows(selectedRows);
  };

  // Função para atualizar em massa o campo 'is_active'
  const handleBulkUpdateIsActive = async () => {
    setLoadingSelecteds(true)
    try {
      await Promise.all(
        selectedRows.map((row) =>
          dispatch(editeProductSelfServiceAsync({ uuid: row.uuid, is_active: !row.is_active }))
        )
      );
      dispatch(getProductByCategoryAsync({
        activedStoreId: storeMe?.storeActive?.id,
        page: currentPage,
        filter: filter
      }));
    } catch (error) {
      notifyError('Erro ao atualizar os produtos selecionados');
    } finally {
      setLoadingSelecteds(false);
    }
  };

// Função para atualizar em massa o campo 'self_service'
  const handleBulkUpdateSelfService = async () => {
    setLoadingSelecteds(true)
    try {
      await Promise.all(
        selectedRows.map((row) =>
          dispatch(editeProductSelfServiceAsync({ uuid: row.uuid, self_service: !row.self_service }))
        )
      );
      dispatch(getProductByCategoryAsync({
        activedStoreId: storeMe?.storeActive?.id,
        page: currentPage,
        filter: filter
      }));
    } catch (error) {
      notifyError('Erro ao atualizar os produtos selecionados');
    } finally {
      setLoadingSelecteds(false);
    }
  };

  const columns: any = [
    {
      name: "Ativo",
      cell: (row: ISelfServiceProduct) => (
        <input
          style={{cursor: "pointer" }}
          type="checkbox"
          checked={row.is_active}
          onChange={() =>  handleToggleIsActived(row)}
        />
      ),
      width: "80px",
      center: true,
    },
    {
      name: "Produto",
      selector: (row: ISelfServiceProduct) => row.sale_name,
      
      grow: 2,
    },
    {
      name: "Categoria",
      selector: (row: ISelfServiceProduct) => row.relationships.category.name,
     
      grow: 2,
    },
    {
      name: "Autoatendimento",
      cell: (row: ISelfServiceProduct) => (
        <input
          style={{cursor: "pointer" }}
          type="checkbox"
          checked={row.self_service}
          onChange={() => handleToggleSelfService(row)}
        />
      ),
      width: "150px",
      center: true,
    },
    {
      name: "Imagem",
      cell: (row: ISelfServiceProduct) => (
        <img
          src={row.image || "https://via.placeholder.com/100"}
          onClick={() => handleShow(row)}
          alt="Produto"
          style={{
            width: "80px",
            height: "60px",
            borderRadius: "8px",
            border: "1px solid #ccc",
            cursor: "pointer",
          }}
        />
      ),
      width: "100px",
      center: true,
    },''
  ];

  useEffect(() => {
    dispatch(getCategoriesAsync(storeMe?.storeActive?.id));
  }, [dispatch]);
  
  useEffect(() => {
    dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id, 
      page: currentPage, filter: filter }));
  }, [dispatch, currentPage]);
  
  
  return (
    <>
      <ModalAPP 
        show={openModal} 
        handleClose={handleClose} 
        selectedProduct={selectedProduct} 
        logo={logo} 
        setLogo={setLogo}
        dispatch={dispatch}
        currentPage={currentPage}
        filter={filter}
        storeMe={storeMe}
      />
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutHookFilter title={title} onClickClear={clearFilter} onClickOpenFilter={() => setOpenFilter(!openFilter)}
                          openFilter={openFilter}>
          <InputFilter register={register} control={control} watch={watch} setValue={setValue} setError={setError}
                       errors={errors} reset={reset} openFilter={openFilter} />
        </LayoutHookFilter>

          {
            selectedRows.length > 0 &&
            <LayoutHookTable>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Btn
                variante="primary"
                text="Ativar/Desativar Selecionados"
                type="button"
                onClick={handleBulkUpdateIsActive}
              />
              <Btn
                variante="primary"
                text="Ativar/Desativar Autoatendimento Selecionados"
                type="button"
                onClick={handleBulkUpdateSelfService}
              />
            </div>
            </LayoutHookTable>
          }

        <LayoutHookTable>
          <div style={{ padding: '0 1rem' }}>
            <DataTable
              columns={columns}
              data={products || []}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              striped
              pagination
              paginationServer
              paginationTotalRows={totalItems}
              paginationDefaultPage={1}
              paginationPerPage={perPage}
              onChangePage={handlePageChange}
              customStyles={tableStyleDefault}
              paginationComponentOptions={paginationOptions}
              progressPending={loading}
              progressComponent={<CustomLoader />}
              noDataComponent="Nenhuma informação foi encontrada."
            />
          </div>
         
        </LayoutHookTable>
      </form>

      {loadingSelecteds && <Loading />}
    </>
  );
}
