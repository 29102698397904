import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getPAymentTypesync = createAsyncThunk(
  "get/payment-type",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.paymentTypes + `?paginate=false&store_id=${storeActivedId}`,
      );
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getChartAccountsync = createAsyncThunk(
  "get/chartAccount",
  async (store_id: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.chartAccounts + `?paginate=false&store_id=${store_id}`,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createPAymentTypesync = createAsyncThunk(
  "create/payment-type",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.paymentTypes, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updatePAymentTypesync = createAsyncThunk(
  "update/payment-type",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.paymentTypes + `/${data.uuid}`,
        data,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deletePAymentTypesync = createAsyncThunk(
  "delete/payment-type",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.paymentTypes + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
