import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import CadImprimirRecibo from "../../../Components/Financeiro/ImprimirRecibos/CadImprimirRecibo";
import { Imprimir } from "../../../Components/Financeiro/ImprimirRecibos/Imprimir/Imprimir";
import { ThemeContext } from "../../../context/theme-context";
import { ImprimirProvider } from "../../../context/print-receipt";

export default function CadImprimirRecibos() {
  const { collapsed } = useContext(ThemeContext);
  const [imprimir, seImprimir] = useState<boolean>(false);
  const [editando, setEditando] = useState<boolean>(false);

  const handleNew = (e: any) => {
    setEditando(false);
    seImprimir(e);
  };

  return (
    <ImprimirProvider>
      {!imprimir ? (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <CadImprimirRecibo
            Title="Imprimir Recibos"
            OnAddPrintReceipt={(ePrintReceipt: any) => {
              handleNew(ePrintReceipt);
            }}
          />
        </Container>
      ) : (
        <Container
          className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
        >
          <Imprimir
            Title="Imprimir Recibo"
            OnAddNewPrintReceipt={(eChartAccount: any) => {
              handleNew(eChartAccount);
            }}
            OnAddNewCli={(e: any) => {
              handleNew(e);
            }}
            editando={editando}
          />
        </Container>
      )}
    </ImprimirProvider>
  );
}
