import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getBrPaymentTypesAsync,
  getBrPaymentTypesProvidersAsync,
  getPAymentTypePDVAsync,
  getPaymentMechanismsAsync,
} from "./services";
import {
  IBrPaymentTypes,
  IBrPaymentTypesProviders,
  IPaymentMechanisms,
  IPaymentTypePDV,
} from "./interface";
import { sortByCreated } from "../../../Services/sortByCreated";

interface InitialState {
  value: PaymentTypePDVState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface PaymentTypePDVState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  paymentTypeDdv: IPaymentTypePDV[];
  paymentMechanisms: IPaymentMechanisms[];
  brPaymentTypes: IBrPaymentTypes[];
  brPaymentTypesProviders: IBrPaymentTypesProviders[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    paymentTypeDdv: [],
    paymentMechanisms: [],
    brPaymentTypes: [],
    brPaymentTypesProviders: [],
    stateForm: { state: "default" },
  } as PaymentTypePDVState,
};

export const paymentTypePDV = createSlice({
  name: "paymentTypePDV",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(getPAymentTypePDVAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getPAymentTypePDVAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IPaymentTypePDV[]>) => {
        state.value.loading = false;
        state.value.paymentTypeDdv = sortByCreated(action.payload);
      },
    );
    builder.addCase(
      getPAymentTypePDVAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );

    builder.addCase(
      getPaymentMechanismsAsync.pending,
      (state: InitialState) => {
        state.value.loading = true;
      },
    );
    builder.addCase(
      getPaymentMechanismsAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IPaymentMechanisms[]>) => {
        state.value.loading = false;
        state.value.paymentMechanisms = action.payload;
      },
    );
    builder.addCase(
      getPaymentMechanismsAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );

    builder.addCase(getBrPaymentTypesAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getBrPaymentTypesAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IBrPaymentTypes[]>) => {
        state.value.loading = false;
        state.value.brPaymentTypes = action.payload;
      },
    );
    builder.addCase(
      getBrPaymentTypesAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );

    builder.addCase(
      getBrPaymentTypesProvidersAsync.pending,
      (state: InitialState) => {
        state.value.loading = true;
      },
    );
    builder.addCase(
      getBrPaymentTypesProvidersAsync.fulfilled,
      (
        state: InitialState,
        action: PayloadAction<IBrPaymentTypesProviders[]>,
      ) => {
        state.value.loading = false;
        state.value.brPaymentTypesProviders = action.payload;
      },
    );
    builder.addCase(
      getBrPaymentTypesProvidersAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );
  },
});

export const {} = paymentTypePDV.actions;

export default paymentTypePDV.reducer;
