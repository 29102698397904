import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sortByCreated } from "../../../Services/sortByCreated";
import { IUserProfile } from "./interface";
import { getUserProfileAsync } from "./services";

interface InitialState {
  value: IUserProfileState;
}

interface StateForm {
  state: "error" | "loading" | "send" | "default";
  message?: string;
}

interface IUserProfileState {
  loading: boolean;
  fetchError: string;
  stateForm: StateForm;
  users: IUserProfile[];
}

const initialState: InitialState = {
  value: {
    loading: false,
    fetchError: "",
    users: [],
    stateForm: { state: "default" },
  } as IUserProfileState,
};

export const userProfileState = createSlice({
  name: "user-profile",
  initialState,
  reducers: {
    setStateForm: (state: InitialState, action) => {
      state.value.stateForm = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getUserProfileAsync.pending, (state: InitialState) => {
      state.value.loading = true;
    });
    builder.addCase(
      getUserProfileAsync.fulfilled,
      (state: InitialState, action: PayloadAction<IUserProfile[]>) => {
        state.value.loading = false;
        state.value.users = sortByCreated(action.payload);
      },
    );
    builder.addCase(
      getUserProfileAsync.rejected,
      (state: InitialState, action: { payload: string }) => {
        state.value.loading = false;
        state.value.fetchError = action.payload;
      },
    );
  },
});

export const { setStateForm } = userProfileState.actions;

export default userProfileState.reducer;
