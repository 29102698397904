import api from "./api";
import global from "../Globals.json";
import Format from "./Format";

export class Services {
  static async user(
    data: unknown,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(global.api.user, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função user:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async profile(
    data: unknown,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(global.api.profiles, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função profile:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async representative(
    data: unknown,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(global.api.representative, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função representative:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async representativePut(
    data: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.put(
        global.api.representative + "/" + data.id,
        data,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função representative:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async store(
    data: unknown,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(global.api.stores, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função store:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async storePut(
    data: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.put(global.api.stores + "/" + data.uuid, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função store:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async checkIfCnpjExists(cnpj: string) {
    try {
      const response = await api.get(
        `${global.api.stores}?filter[cnpj]=${cnpj}`,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função checkIfCnpjExistsStores:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }
          return errorMessages;
        }
      }
      throw error;
    }
  }

  static async checkIfCnpjExistsPeople(cnpj: string, path: string) {
    try {
      const response = await api.get(
        `${global.api.people}/type/${path}?filter[cpf_cnpj]=${cnpj}`,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função checkIfCnpjExistsPeople:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }
          return errorMessages;
        }
      }
      throw error;
    }
  }

  static async people(
    data: unknown,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(global.api.people, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função people:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async peoplePut(
    data: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.put(global.api.people + "/" + data.uuid, data);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função peoplePut:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async peoplePutAddress(
    data: any,
    uuidPeople: string,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.put(
        global.api.people + `/${uuidPeople}/addresses/${data.id}`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função peoplePutAddress:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async peopleDeleteAddress(
    uuidPeople: string,
    uuidAdress: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.delete(
        global.api.people + `/${uuidPeople}/addresses/${uuidAdress}`,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função peoplePutAddress:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async checkIfCpfExistsPeople(cpf: string, path: string) {
    try {
      const response = await api.get(
        `${global.api.people}/type/${path}?filter[cpf_cnpj]=${cpf}`,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função checkIfCnpjExists:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }
          return errorMessages;
        }
      }
      throw error;
    }
  }

  static async addNewAddress(
    uuid: string,
    data: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(
        `${global.api.people}/${uuid}/addresses`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função addNewAddress:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async addNewContacts(
    uuid: string,
    data: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.post(
        `${global.api.people}/${uuid}/contacts`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função addNewContacts:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async peoplePutContacts(
    data: any,
    uuidPeople: string,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.put(
        global.api.people + `/${uuidPeople}/contacts/${data.uuid}`,
        data,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função peoplePutContacts:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async peopleDeleteContacts(
    uuidPeople: string,
    uuidAdress: any,
    setStateForm: React.Dispatch<React.SetStateAction<any>>,
  ) {
    try {
      const response = await api.delete(
        global.api.people + `/${uuidPeople}/contacts/${uuidAdress}`,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função peoplePutContacts:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
          setStateForm({
            state: "error",
            message: errorMessages[0],
          });
        } else {
          setStateForm({
            state: "default",
          });
        }
      }
      throw error;
    }
  }

  static async stockTransfersChargeBack(uuid: string) {
    try {
      const response = await api.put(
        global.api.transfers + `/${uuid}/chargeback`,
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função stockTransfersChargeBack:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }
      throw error;
    }
  }

  static async stockQuotesAddProduct(
    uuid: string,
    uuidProduct: string,
    setLoading: any,
    data: any,
  ) {
    try {
      const response = await api.post(
        global.api.stockQuotes + `/${uuid}/products`,
        data,
      );
      setLoading(false);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função stockQuotesAddProduct:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }
      setLoading(false);
      throw error;
    }
  }

  static async stockQuotesEditeProduct(
    uuid: string,
    uuidProduct: string,
    setLoading: any,
    data: any,
  ) {
    try {
      const response = await api.put(
        global.api.stockQuotes + `/${uuid}/products/${uuidProduct}`,
        data,
      );
      setLoading(false);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função stockQuotesEditeProduct:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }
      setLoading(false);
      throw error;
    }
  }

  static async stockQuotesDeleteProduct(
    uuid: string,
    uuidProduct: string,
    setLoading: any,
  ) {
    try {
      const response = await api.delete(
        global.api.stockQuotes + `/${uuid}/products/${uuidProduct}`,
      );
      setLoading(false);
      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função stockQuotesDeleteProduct:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }
      setLoading(false);
      return error;
    }
  }

  static async chartAccountDelete(uuid: string) {
    try {
      const response = await api.delete(global.api.chartAccounts + `/${uuid}`);

      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função chartAccountDelete:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }

      return error;
    }
  }

  static async categoriProductsDelete(uuid: string) {
    try {
      const response = await api.delete(global.api.categoriesAlt + `/${uuid}`);

      return response;
    } catch (error: any) {
      if (error.response) {
        console.log(
          "Erro na função categoryProductDelete:",
          error.response.data,
        );
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }

      return error;
    }
  }

  static async finanaciltransitionDelete(uuid: string) {
    try {
      const response = await api.delete(
        global.api.financialTransaction + `/${uuid}`,
      );

      return response;
    } catch (error: any) {
      if (error.response) {
        console.log("Erro na função chartAccountDelete:", error.response.data);
        if (error.response && error.response.data.errors) {
          const errorMessages: string[] = [];
          const errorData = error.response.data.errors;

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              errorMessages.push(...errorData[key]);
            }
          }

          console.log("Erros de validação:", errorMessages);
        } else {
        }
      }

      return error;
    }
  }
}
