import { Form } from "react-bootstrap";
import styles from "./styles.module.scss";

interface IProps {
  text: string;
  value: any;
  state: any;
  setState: (value: any) => void;
}

export default function InputCheckRadio({ text, value, state, setState }: IProps) {
  return (
    <Form.Check
      type="radio"
      label={text}
      name="custom-radio-group"
      className={styles.input}
      style={{fontWeight: "bold"}}
      checked={state === value}
      onChange={() => setState(value)}
    />
  );
}
