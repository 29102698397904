import { useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import ListaPosicoes from "../../../Components/Estoque/ListaPosicaoEstoque";
import { ThemeContext } from "../../../context/theme-context";

export default function CadPosicoes() {
  const { collapsed } = useContext(ThemeContext);
  return (
    <>
      <Container
        className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
      >
        <ListaPosicoes Title="Posições de Estoque" Path="positions" />
      </Container>
    </>
  );
}
