import axios from 'axios';
//import client from '../../http'

export const userDataByCPF = (cpf, birth) => {
  return axios.get(
    `https://ws.hubdodesenvolvedor.com.br/v2/cpf/?cpf=${cpf}&data=${birth}&token=88334435kgwRBVImSN159485144`
  );
};

export const userCPF = async (cpf) => {
  return await axios.get(
    `https://ws.hubdodesenvolvedor.com.br/v2/nome_cpf/?cpf=${cpf}&token=88334435kgwRBVImSN159485144`
  );
};

export const userDataByCEP = (cep) => {
  return axios.get(
    `https://ws.hubdodesenvolvedor.com.br/v2/cep3/?cep=${cep}&token=88334435kgwRBVImSN159485144`
  );
};

export const userDataByCNPJ = (cnpj) => {
  return axios.get(
    `https://ws.hubdodesenvolvedor.com.br/v2/cnpj/?cnpj=${cnpj}&token=88334435kgwRBVImSN159485144`
  );
};
export const getStates = () => {
  return [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
    'DF',
  ];
};
