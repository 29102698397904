// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-cad-medida {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.action {
  cursor: pointer;
}

.btn {
  color: #8e8d8d;
}

#excludeMsg {
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Cadastro/CadUnidMedida/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;AACF;;AAGA;EACE,aAAA;EACA,6BAAA;AAAF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAGF","sourcesContent":[".header-cad-medida {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 5px;\n  // margin: -20px 0 20px 0;\n}\n\n.actions {\n  display: flex;\n  justify-content: space-around;\n}\n.action {\n  cursor: pointer;\n}\n.btn {\n  color: #8e8d8d;\n}\n#excludeMsg {\n  margin-top: 20px;\n  font-weight: 300;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
