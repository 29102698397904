import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import api from "../../../../Services/api";
import Globals from "../../../../Globals.json";
import { useAppSelector } from "../../../../redux/store";
import tableStyleDefaultBolder from "../../../../Services/tableStyleDefaultBolder";
import CustomLoader from "../../../data/customLoader";
import * as S from "./styled";
import { HiOutlineMinusSm, HiOutlinePlusSm } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { ProdutoContext } from "../../../../context/product";
import ModalADD from "./ModalAdd";
import ModalCopy from "./ModalCopy";
import { paginationOptions } from "../../../data/PagineteInfo";
import { notifyError } from "../../../notify/notify-component";

export default function Associated({
  isEditable,
  isDuplicate,
}: {
  isEditable: boolean;
  isDuplicate: boolean;
}) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { category,associatedComplement, setAssociatedComplement } =
    useContext(ProdutoContext);

  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalCopy, setModalCopy] = useState<boolean>(false);
  const [selectedComplement, setSelectedComplement] = useState<
    number | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const columns: any = [
    {
      name: "Código",
      sortable: true,
      width: "100px",
      center: true,
      selector: (row: any) => row.code,
    },
    {
      name: "Produto",
      minWidth: "190px",
      sortable: true,
      selector: (row: any) => {
        return (
          <div
            title="Selecionar"
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleSelectComplement(row)}
          >
            <span
              style={{ color: row.id === selectedComplement ? "blue" : "" }}
            >
              {" "}
              {row.sale_name?.toLowerCase()}
            </span>
          </div>
        );
      },
    },
  ];

  const getCategories = () => {
    setLoading(true);
    const uri = `?paginate=false&store_id=${storeMe?.storeActive?.id}`;
    api
      .get(Globals.api.categories + `${uri}`)
      .then((response) => {
        const data = response?.data?.data?.find(
          (item: any) => item.id === category,
        );
      
        setAssociatedComplement(data?.relationships.associateds);
      })
      .catch((error: any) => {
        notifyError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemove = () => {
    setAssociatedComplement(
      associatedComplement.filter((item: any) => item.id !== selectedComplement),
    );
    setSelectedComplement(undefined);
  };

  const handleSelectComplement = (complement: any) => {
    setSelectedComplement(complement.id);
  };

  useEffect(() => {
    !isEditable && !isDuplicate && getCategories();
  }, [category]);

  console.log('data', associatedComplement);

  return (
    <>
      <S.Section>
        <DataTable
          columns={columns}
          data={associatedComplement || []}
          striped
          customStyles={tableStyleDefaultBolder}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhum associado encontrado."
        />
        <S.ButtonGroup>
          <button onClick={() => setModalAdd(true)} type="button">
            <span>
              <HiOutlinePlusSm title="Adicionar" size={16} color="white" />
            </span>
            Adicionar
          </button>
          <button onClick={() => setModalCopy(true)} type="button">
            <span>
              <FaRegCopy title="Copiar" size={16} color="white" />
            </span>
            Copiar
          </button>
          <button
            disabled={selectedComplement === undefined ? true : false}
            onClick={handleRemove}
            type="button"
          >
            <span>
              <HiOutlineMinusSm title="Remover" size={16} color="white" />
            </span>
            Remover
          </button>
        </S.ButtonGroup>
      </S.Section>
      <ModalADD
        setShow={setModalAdd}
        show={modalAdd}
        associatedComplement={associatedComplement}
        setAssociatedComplement={setAssociatedComplement}
      />
      <ModalCopy
        setShow={setModalCopy}
        show={modalCopy}
        associatedComplement={associatedComplement}
        setAssociatedComplement={setAssociatedComplement}
      />
    </>
  );
}
