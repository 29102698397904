import api from "./api";
import globals from "../Globals.json";
import { notifyError } from "../Components/notify/notify-component";

export class CreateAndEdit {
  static async post(
    endpoit: any,
    objFinancialTransaction: any,
    endPoitAlt?: any,
  ) {
    if (objFinancialTransaction.uuid) {
      return api
        .put(
          `${endPoitAlt ? endPoitAlt : endpoit}/${
            objFinancialTransaction.uuid
          }`,
          objFinancialTransaction,
        )
        .then((res: any) => {
          if (res.status === 201 || res.status === 200) {
            const objCallback = {
              data: res.data.data,
              status: true,
            };
            return objCallback;
          }
        })
        .catch((error: any) => {
          console.log("error validação", error);
          
          notifyError(error);
        });
    } else {
      return api
        .post(`${endpoit}`, objFinancialTransaction)
        .then((res: any) => {
          if (res.status === 201 || res.status === 200) {
            const objCallback = {
              data: res.data.data,
              status: true,
            };
            return objCallback;
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 422) {
            notifyError(error.response.data.message);
          } else {
            notifyError(error.response.data.message || "Erro desconhecido");
          }
        });
    }
  }

  static async postMoviments(objStockMovement: any) {
    if (objStockMovement.uuid) {
      return api
        .put(
          `${globals.api.movements}/${objStockMovement.uuid}`,
          objStockMovement,
        )
        .then((res: any) => {
          if (res.status === 201 || res.status === 200) {
            const objCallback = {
              data: res.data.data,
              status: true,
            };
            return objCallback;
          }
        })
        .catch((error: any) => {
          notifyError(error);
        });
    } else {
      return api
        .post(
          `${globals.api.stocks}/${objStockMovement.stock_uuid}/movements`,
          objStockMovement,
        )
        .then((res: any) => {
          if (res.status === 201 || res.status === 200) {
            const objCallback = {
              data: res.data.data,
              status: true,
            };
            return objCallback;
          }
        })
        .catch((error: any) => {
          notifyError(error);
        });
    }
  }
}
