import { Button, Col, Modal, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../../../redux/store";

import { notify, notifyError } from "../../../../notify/notify-component";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../reactHookForm/SelectHook";
import { updatePAymentTypesync } from "../../../../../redux/features/paymentType/services";
import {
  IBankAccount,
  IExternalBank,
} from "../../../../../redux/features/bankAccount/interface";
import { bankAccountSchema } from "../../../../../redux/features/bankAccount/schema";
import {
  createBankAccountAsync,
  getBankAccountAsync,
  updateBankAccountAsync,
} from "../../../../../redux/features/bankAccount/services";
import { CheckboxHook } from "../../../../reactHookForm/inputCheckbox";
import * as S from "./styled";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  listEditeItem: IBankAccount | undefined;
  setListEditeItem: Dispatch<SetStateAction<IBankAccount | undefined>>;
}

type FormData = Yup.InferType<typeof bankAccountSchema>;

interface IInput {
  description: string;
  agency: number;
  agency_digit: number;
  account: number;
  account_digit: number;
  balance: number;
  bank: any;
  is_active: boolean;
}

export default function ModalCad({
  show,
  setShow,
  listEditeItem,
  setListEditeItem,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const { externalBanks } = useAppSelector(
    ({ externalBankReducer }) => externalBankReducer.value,
  );

  const objBankAccount = externalBanks?.map((item: IExternalBank) => ({
    label: item.label,
    value: item.id,
  }));

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(bankAccountSchema),
  });

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const onSubmit: SubmitHandler<IInput> = async (data: IInput) => {
    const newData = {
      store_id: storeMe?.storeActive?.id,
      ...data,
      bank: data.bank.label.toString(),
      is_active: true,
    };

    if (listEditeItem) {
      const newDataUpdate = {
        ...listEditeItem,
        ...newData,
        is_active: data.is_active,
      };
      dispatch(updateBankAccountAsync(newDataUpdate)).then((result: any) => {
        if (updatePAymentTypesync.fulfilled.match(result)) {
          dispatch(getBankAccountAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });
      resetItens();
      setShow(false);
      return;
    }

    dispatch(createBankAccountAsync(newData)).then((result: any) => {
      if (createBankAccountAsync.fulfilled.match(result)) {
        dispatch(getBankAccountAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    resetItens();
    setShow(false);
  };

  const inputError = [errors];

  const resetItens = () => {
    setValue("description", "");
    setValue("agency", "");
    setValue("agency_digit", "");
    setValue("account", "");
    setValue("account_digit", "");
    setValue("balance", "");
    setValue("bank", "");
    setValue("is_active", "");
    reset({});
  };

  useEffect(() => {
    if (listEditeItem && show) {
      setValue("description", listEditeItem?.description);
      setValue("balance", listEditeItem?.balance);
      setValue("agency", listEditeItem.agency);
      setValue("agency_digit", listEditeItem?.agency_digit);
      setValue("account", listEditeItem?.account);
      setValue("account_digit", listEditeItem?.account_digit);
      setValue("is_active", listEditeItem?.is_active);
      setValue(
        "bank",
        objBankAccount.find((item: any) => item.label === listEditeItem?.bank),
      );
    } else {
      resetItens();
      setValue("is_active", false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{listEditeItem ? "Editar " : "Adicionar "} Conta Bancaria</h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Descrição"
              name="description"
              defaultValue={"Despesa"}
              placeholder="Descrição"
              type="text"
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Saldo"
              name="balance"
              type="number"
              step="1"
              placeholder="Saldo atual"
              min="0"
            />
          </S.Wrapper>
          <S.Wrapper>
            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="Bancos"
              name="bank"
              placeholder="Bancos"
              options={objBankAccount}
            />
          </S.Wrapper>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Agência"
              placeholder="Agência"
              name="agency"
              type="number"
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Digito"
              placeholder="Digito"
              name="agency_digit"
              type="number"
            />
          </S.Wrapper>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Conta"
              placeholder="Conta"
              name="account"
              type="number"
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Digito"
              placeholder="Digito"
              name="account_digit"
              type="number"
            />
          </S.Wrapper>
          <CheckboxHook
            register={register}
            errors={inputError}
            label="Ativo"
            name="is_active"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
