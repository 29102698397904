import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IListInventary } from "../../../../Pages/Cadastro/CadInventario";
import { ToastContainer } from "react-toastify";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import LayoutHookTable from "../../../reactHookForm/LayoutTable";
import DataTable from "react-data-table-component";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { paginationOptions } from "../../../data/PagineteInfo";
import { FaEdit, FaTrash } from "react-icons/fa";
import { InputFilter } from "./InputFilter";
import { SubmitHandler, useForm } from "react-hook-form";
import LayoutHookFilter from "./LayoutFilter";
import Format from "../../../../Services/Format";
import DateUtils from "../../../../Services/DateUtils";
import { IInventary } from "../../../../redux/features/inventary/interface";
import ModalBootstrap from "../../../data/Modal";
import CustomLoader from "../../../data/customLoader";
import {
  deleteCountAsync,
  getAllProductsAsync,
  getAllProductstableAsync,
  getCategoriesAsync,
  getCountAsync,
  getProductsAsync,
  getStockAsync,
} from "../../../../redux/features/count/services";
import { notify, notifyError } from "../../../notify/notify-component";

interface IFormInput {}

export function ListContagem({
  title,
  onEditItem,
  onAddNewCli,
}: IListInventary) {
  const dispatch = useDispatch<AppDispatch>();
  const count = useAppSelector(({ countReducer }) => countReducer.value);
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  useState<boolean>(false);
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>();
  const [uuid, setUuid] = useState<string>("");
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState("");

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IFormInput>();
  const inputError = [errors];

  const columns: any = [
    {
      name: "ID",
      selector: (row: IInventary) => row.id,
      center: true,
      sortable: true,
      grow: 0.2,
    },
    {
      name: "Tipo",
      center: true,
      grow: 0.3,
      selector: (row: IInventary) =>
        row.type === 1 ? "Contagem" : "Inventário",
    },
    {
      name: "Nome",
      center: true,
      sortable: true,
      selector: (row: IInventary) => row?.name?.toLowerCase() ?? "---",
    },

    {
      name: "Status",
      center: true,
      sortable: true,
      grow: 0.3,
      selector: (row: IInventary) => row?.status_description?.toLowerCase(),
    },

    {
      name: "Data Criação",
      center: true,
      grow: 0.5,
      selector: (row: IInventary) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Data Finalização",
      center: true,
      grow: 0.5,
      selector: (row: IInventary) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY"),
    },
    {
      name: "Usuario",
      sortable: true,
      center: true,
      selector: (row: IInventary) =>
        row?.relationships?.user?.name?.toLowerCase(),
    },
    {
      name: "Ação",
      center: true,
      grow: 0.3,
      cell: (row: IInventary) => (
        <>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleEdit(row)}
                variant="contained"
                color="primary"
              >
                <FaEdit size={16} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Excluir</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleModalId(row.uuid, row.id)}
                variant="contained"
                color="primary"
              >
                <FaTrash size={13} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
        </>
      ),
      button: true,
    },
  ];

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    const filterList = [];

    filterList.push(
      `store_id=${data?.store.length > 0 ? data?.store?.value : storeMe?.storeActive?.id}`,
    );

    if (data.stock_id?.label !== undefined && data.stock_id?.label !== "Todos")
      filterList.push(`filter[stock_id]=${data?.stock_id?.value}`);

    if (data?.typedate?.value === 2) {
      filterList.push(`filter[created_from]=${data?.start}`);
      filterList.push(`filter[created_until]=${data?.end}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        dispatch(getCountAsync(filter));
        setFilter(filter);
      }
      return;
    }
    dispatch(getCountAsync(`store_id=${storeMe?.storeActive?.id}`));
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `${filterList[i]}`;
    }
    return filter;
  };

  const handleEdit = (row: IInventary) => {
    onEditItem(row);
  };

  const handleAdd = () => {
    onAddNewCli(true);
  };

  const handleModalId = (uuid: string, id: number | undefined) => {
    setId(id);
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteAsync = (uuid: string, id: number | undefined) => {
    dispatch(deleteCountAsync(uuid)).then((result: any) => {
      if (deleteCountAsync.fulfilled.match(result)) {
        notify("Contagem deletada.");
        dispatch(getCountAsync(`store_id=${storeMe?.storeActive?.id}`));
        setModalConfirmation(false);
      } else if (errors) {
        notifyError("Erro ao deletar produto.");
      }
    });
  };

  const clearFilter = () => {
    reset();
    dispatch(getCountAsync(`store_id=${storeMe?.storeActive?.id}`));
  };

  const handleDeleteItemTable = () => {
    handleDeleteAsync(uuid, id);
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    //dispatch(getStoreMeAsync());
    dispatch(getCountAsync(`store_id=${storeMe?.storeActive?.id}`));
    dispatch(getAllProductsAsync(storeMe?.storeActive?.id));
    dispatch(getAllProductstableAsync(storeMe?.storeActive?.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStockAsync(storeMe.storeActive ? storeMe?.storeActive?.id : 0));
    dispatch(
      getCategoriesAsync(storeMe.storeActive ? storeMe?.storeActive?.id : 0),
    );
    dispatch(
      getProductsAsync({
        idStock: count.stock.length > 0 ? count.stock[0].id : 0,
        store_id: storeMe?.storeActive?.id,
      }),
    );
  }, [storeMe, dispatch]);

  return (
    <div>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir a contagem?"
        handleAction={handleDeleteItemTable}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <LayoutHookFilter
          title={title}
          onClickAdd={handleAdd}
          onClickClear={clearFilter}
          onClickOpenFilter={onClickOpenFilter}
          openFilter={openFilter}
        >
          <InputFilter
            register={register}
            control={control}
            watch={watch}
            setValue={setValue}
            setError={setError}
            errors={inputError}
            reset={reset}
            openFilter={openFilter}
          />
        </LayoutHookFilter>
      </form>

      <LayoutHookTable>
        <DataTable
          columns={columns}
          data={count?.contagem || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={count.loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutHookTable>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
