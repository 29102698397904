import { useEffect, useRef, useState } from "react";
import { StateForm } from "../../interfaces/state-form";

import * as Yup from "yup";

import { InputForm } from "../../Components/data/inputsWeb/Input";
import Spinner from "../../Components/spinner/Spinner";
import * as S from "./styles";
import { authSchema } from "../../schemas/authSchema";
import { useAuth } from "../../context/Auth";
import { YupValidation } from "../../Services/yup-validation";
import { Link, useHistory } from "react-router-dom";
import Globals from "./../../Globals.json";
import Loading from "../../Components/Loading/Loading";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputHook } from "../../Components/reactHookForm/InputHook";
import { InputHookDefal } from "../../Components/reactHookForm/InputHookDefal";

interface ILogin {
  email: string;
  password: string;
}

interface IFormInput {}
type FormData = Yup.InferType<typeof authSchema>;

export default function Login() {
  const history = useHistory();
  const { signIn, getMe } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(authSchema),
  });

  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    try {
      setStateForm({ state: "loading" });

      const { email, password } = data;

      await signIn({ email, password });

      history.push(Globals.paths.home);
      window.location.reload();
      setStateForm({ state: "default" });
    } catch (err: any) {
      setStateForm({
        state: "error",
        message:
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          "api_error_please_contact_support",
      });
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  const inputError = [errors];

  return (
    <S.Container>
      <S.Login>
        <S.Card>
          <div className="imgDiv">
            <img src="img/ERP_Cloud_Preto.png" alt="logo" />
          </div>
          <div className="description">
            <span className="Entrar">Entrar</span>
            <span className="description-2">
              Insira suas credenciais do <strong>ERPCloud</strong> para acessar
              sua área de cliente.
            </span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {stateForm.state === "error" && (
              <p className="error">{stateForm.message}</p>
            )}
            {stateForm.state === "send" && (
              <p className="success">{stateForm.message}</p>
            )}
            <InputHookDefal
              register={register}
              errors={inputError}
              name="email"
              type="email"
              placeholder="E-mail"
              label="Email"
            />
            <InputHookDefal
              register={register}
              errors={inputError}
              label="Senha"
              name="password"
              type="password"
              placeholder="Senha"
            />
            {
              <S.Button>
                {stateForm.state === "loading" ? (
                  <S.PositionSpinner>
                    <Spinner button />
                  </S.PositionSpinner>
                ) : (
                  <span>Entrar</span>
                )}
              </S.Button>
            }
            <div className="forgot">
              Esqueceu sua senha?
              <span>
                <Link to={Globals.paths.forgot}>Clique aqui</Link>
              </span>
            </div>
          </form>
        </S.Card>
      </S.Login>
      {/* 
        
      <div className="imgC">
        <div className="wrapper">
          <img src="img/logoRP.png" alt="logo" />
          <h1>Tome decisões estratégicas com base em dados precisos.</h1>
          <h2>Controle Total de Finanças e Estoque para o Seu Sucesso</h2>
          <div className="footer">
            <h2>Integrado ao PDV</h2>
            <img src="img/xd.png" alt="xd" />
          </div>
        </div>
      </div>
        */}
    </S.Container>
  );
}
