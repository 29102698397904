import { useEffect, useState } from "react";
import { IListPerfilUsuario } from "../../../../Pages/Cadastro/CadPermissions";
import LayoutFilter from "../../../data/LayoutFilter";
import { SelectHook } from "../../../reactHookForm/SelectHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import * as S from "./styled";
import LayoutTable from "../../../data/LayoutTable";
import DataTable from "react-data-table-component";
import tableStyleDefaultBolder from "../../../../Services/tableStyleDefaultBolder";
import { paginationOptions } from "../../../data/PagineteInfo";
import CustomLoader from "../../../data/customLoader";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { getUserProfileAsync } from "../../../../redux/features/userProfile/services";
import { perfilAccsessSchema } from "../../../../redux/features/userAccess/schema";
import { IListPerfilAcesso } from "../../../../Pages/Cadastro/CadPerfilAcesso";
import {
  deleteUserAccessAsync,
  getUserAccessAsync,
} from "../../../../redux/features/userAccess/services";
import { FormPerfilAcesso } from "../FormPerfilAcesso";
import { deletePAymentTypePDVAsync } from "../../../../redux/features/PaymentTypePDV/services";
import { notify, notifyError } from "../../../notify/notify-component";
import ModalBootstrap from "../../../data/Modal";
import { getStoreAsync } from "../../../../redux/features/store/services";

type FormData = Yup.InferType<typeof perfilAccsessSchema>;

export function ListPerfilAcesso({ title }: IListPerfilAcesso) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const { loading, role } = useAppSelector(
    ({ userAccessReducer }) => userAccessReducer.value,
  );

  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string>("");
  const [openModalCadPDV, setModalCadPDV] = useState<boolean>(false);
  const [listEditeItem, setListEditeItem] = useState<any>();

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(perfilAccsessSchema),
  });
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleSearchList = () => {};

  const clearFields = () => {};

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleEdit = (item: any) => {
    setListEditeItem(item);
    setModalCadPDV(true);
  };

  const handleDelete = (uuid: any) => {
    dispatch(deleteUserAccessAsync(uuid)).then((result: any) => {
      if (deleteUserAccessAsync.fulfilled.match(result)) {
        notify("Perfil de acesso excluido.");
        dispatch(getUserAccessAsync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao excluir perfil de acesso.");
      }
    });
  };

  const columns: any = [
    {
      name: "Nome",
      selector: (row: any) => row.name.toLowerCase(),
      sortable: true,
    },

    {
      name: "Descrição",
      selector: (row: any) => row.description.toLowerCase(),
      sortable: true,
    },

    {
      name: "Ação",
      grow: 0.2,
      cell: (row: any) => (
        <div>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Editar</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleEdit(row)}
                variant="contained"
                color="primary"
              >
                <FaEdit size={16} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Excluir</Tooltip>}
          >
            <span className="d-inline-block">
              <Button
                onClick={() => handleModalUuid(row.uuid)}
                variant="contained"
                color="primary"
              >
                <FaTrash size={14} color="8e8d8d" />
              </Button>
            </span>
          </OverlayTrigger>
        </div>
      ),
      button: true,
    },
  ];

  const handleModalUuid = (uuid: string) => {
    setUuid(uuid);
    setModalConfirmation(true);
  };

  const handleDeleteItem = () => {
    setModalConfirmation(false);
    handleDelete(uuid);
  };

  useEffect(() => {
    dispatch(getUserAccessAsync(storeMe?.storeActive?.id));
  }, [dispatch]);

  return (
    <>
      <LayoutFilter
        title={title}
        onClickAdd={() => setModalCadPDV(true)}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        openFilter={openFilter}
        onClickOpenFilter={onClickOpenFilter}
      >
        <S.Wrapper>
          {openFilter && (
            <div className="min-screen">
              <SelectHook
                register={register}
                control={control}
                label="Nome"
                name="perfil"
                placeholder="Nome"
                options={[]}
              />
            </div>
          )}

          <div className="max-screen">
            <SelectHook
              register={register}
              control={control}
              label="Nome"
              name="perfil"
              placeholder="Nome"
              options={[]}
            />
          </div>
        </S.Wrapper>
      </LayoutFilter>

      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text={"Certeza que deseja excluir?"}
        handleAction={handleDeleteItem}
      />

      <FormPerfilAcesso
        show={openModalCadPDV}
        setShow={setModalCadPDV}
        listEditeItem={listEditeItem}
        setListEditeItem={setListEditeItem}
      />

      <LayoutTable>
        <DataTable
          columns={columns}
          data={role || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefaultBolder}
          paginationComponentOptions={paginationOptions}
          progressPending={loading}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>
    </>
  );
}
