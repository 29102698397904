import * as Yup from "yup";
import { perfilUsuarioSchemaForm } from "../../../../redux/features/userProfile/schema";
import { AppDispatch, useAppSelector } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as S from "./styles";
import { InputHook } from "../../../reactHookForm/InputHook";
import { IFormUserSystem } from "../../../../Pages/Cadastro/CadUserSystem";
import { SelectHook } from "../../../reactHookForm/SelectHook";
import { setStateForm } from "../../../../redux/features/userSystem/user-system-slice";
import { notifyError } from "../../../notify/notify-component";
import {
  createUserSystemAsync,
  editeUserSystemAsync,
  getUserSystemAsync,
} from "../../../../redux/features/userSystem/services";
import { Button, Modal } from "react-bootstrap";
import { useEffect } from "react";

type FormData = Yup.InferType<typeof perfilUsuarioSchemaForm>;

export function FormUserSystem({
  show,
  setShow,
  listEditeItem,
  setListEditeItem,
}: IFormUserSystem) {
  const dispatch = useDispatch<AppDispatch>();
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const { stateForm, users } = useAppSelector(
    ({ userSystemReducer }) => userSystemReducer.value,
  );
  const { role } = useAppSelector(
    ({ userAccessReducer }) => userAccessReducer.value,
  );
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({ resolver: yupResolver(perfilUsuarioSchemaForm) });

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    dispatch(setStateForm({ state: "loading" }));

    if (listEditeItem) {
      const { email, ...rest } = listEditeItem;
      const newDataEdit = {
        ...rest,
        name: data.name,
        storeId: storeMe?.storeActive?.id,
        ...(data.email !== listEditeItem.email && { email: data.email }),
        role_id: data?.role_id?.value,
      };
      dispatch(editeUserSystemAsync(newDataEdit)).then((result: any) => {
        if (editeUserSystemAsync.fulfilled.match(result)) {
          dispatch(getUserSystemAsync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar usuário.");
        }
      });

      dispatch(setStateForm({ state: "default" }));
      handleClose();
      return;
    } else {
      const newData = {
        ...data,
        storeId: storeMe?.storeActive?.id,
        role_id: data.role_id.value,
      };
      dispatch(createUserSystemAsync(newData)).then((result: any) => {
        if (createUserSystemAsync.fulfilled.match(result)) {
          dispatch(getUserSystemAsync(storeMe?.storeActive?.id));
        } else if (result) {
          notifyError(
            result?.payload?.response?.data?.message ||
              "Erro ao adicionar usuário.",
          );
          return;
        }
        handleClose();
        dispatch(setStateForm({ state: "default" }));
      });
    }
  };

  const resetItens = () => {
    setValue("email", "");
    setValue("name", "");
    setValue("role_id", "");
    reset({});
  };

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const inputError = [errors];

  useEffect(() => {
    if (listEditeItem && show) {
      reset(listEditeItem);
      setValue(
        "role_id",
        role.find((item: any) => item.label === listEditeItem?.name),
      );
    } else {
      resetItens();
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        style={{ fontSize: "12px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{listEditeItem ? "Editar " : "Adicionar "} Perfil de Acesso</h3>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <S.Wrapper>
              <InputHook
                register={register}
                errors={inputError}
                label="Nome"
                name="name"
                placeholder="Nome do usuário"
                type="text"
              />

              <InputHook
                register={register}
                errors={inputError}
                label="Email"
                name="email"
                placeholder="Email"
                type="email"
              />
              <SelectHook
                register={register}
                control={control}
                label="Perfil"
                name="role_id"
                placeholder="perfil de acesso"
                options={role.map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </S.Wrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              {listEditeItem ? "Atualizar" : "Salvar"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
