import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";
import type { IProduct } from "../../inventary/interface";

export const getStockTransferAsync = createAsyncThunk(
  "get/stock-transfer",
  async (query: { store_id: number; filter?: string }, thunkAPI) => {
    const uri =
      query?.filter !== undefined
        ? `${query?.filter}&paginate=false&store_id=${query.store_id}`
        : `paginate=false&store_id=${query.store_id}`;
    try {
      const res = await api.get(globals.api.transfersStocks + `?${uri}`);

      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getStockLocationAsync = createAsyncThunk(
  "get/stock-location",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.stocks + `?paginate=false&store_id=${storeActivedId}`,
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getStockAllLocationAsync = createAsyncThunk(
  "get/stock-all-location",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.stocks + `?paginate=false&store_id=${storeActivedId}`,
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createStockTransferAsync = createAsyncThunk(
  "create/stock-transfer",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(
        globals.api.stocks + `/${data.stock_uuid}/transfers`,
        data,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateStockTransferAsync = createAsyncThunk(
  "update/stock-transfer",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.transfers + `/${data.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteStockTransferAsync = createAsyncThunk(
  "delete/stock-transfer",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.transfers + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getProductsAsync = createAsyncThunk(
  "inventary/products",
  async (data: { idStock: number; store_id: number }, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.products +
          `?paginate=false&filter[is_stockable]=true&append=stock_qty&store_id=${data.store_id}`,
      );

      const filteredProduct = res.data.data.filter(
        (item: IProduct) => item?.stock_local_id === data.idStock,
      );
      return data.idStock ? filteredProduct : res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
