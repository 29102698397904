import { createContext, ReactNode, useState, useEffect } from "react";
import { IFichaTecnica } from "../../Components/Cadastro/Fichatecnica/interface/IFichaTecnica";

interface ChildrenProps {
  children: ReactNode;
}

interface InitialContextInterface {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fichaTecnica: number;
  setFichaTecnica: React.Dispatch<React.SetStateAction<number>>;
  produto: number;
  setProduto: React.Dispatch<React.SetStateAction<number>>;
  unidadeMedidaId: number;
  setUnidadeMedidaId: React.Dispatch<React.SetStateAction<number>>;
  unidadeMedida: string;
  setUnidadeMedida: React.Dispatch<React.SetStateAction<string>>;
  rendimentos: string;
  setRendimentos: React.Dispatch<React.SetStateAction<string>>;
  tipoProducao: number;
  setTipoProducao: React.Dispatch<React.SetStateAction<number>>;
  quantidade: string;
  setQuantidade: React.Dispatch<React.SetStateAction<string>>;
  produtoInsumo: number;
  setProdutoInsumo: React.Dispatch<React.SetStateAction<number>>;
  isValidproduto: boolean;
  setIsValidProduto: React.Dispatch<React.SetStateAction<boolean>>;
  isValidrendimentos: boolean;
  setIsValidRendimentos: React.Dispatch<React.SetStateAction<boolean>>;
  isValidprodutoInsumo: boolean;
  setIsValidProdutoInsumo: React.Dispatch<React.SetStateAction<boolean>>;
  isValidquantidade: boolean;
  setIsValidQuantidade: React.Dispatch<React.SetStateAction<boolean>>;
  listaProdutosAdd: any[];
  setListaProdutosAdd: any;

  clearFilds: () => void;
  handleValidete: () => boolean;
  handleEdit: (props: IFichaTecnica) => void;
}

export const DatasheetContext = createContext<InitialContextInterface>(
  {} as InitialContextInterface,
);

export const DatasheetProvider = ({ children }: ChildrenProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fichaTecnica, setFichaTecnica] = useState<number>(0);

  const [produto, setProduto] = useState<number>(0);
  const [isValidproduto, setIsValidProduto] = useState<boolean>(false);
  const [unidadeMedida, setUnidadeMedida] = useState<string>("");
  const [rendimentos, setRendimentos] = useState<string>("");
  const [isValidrendimentos, setIsValidRendimentos] = useState<boolean>(false);
  const [tipoProducao, setTipoProducao] = useState<number>(1);
  const [produtoInsumo, setProdutoInsumo] = useState<number>(0);
  const [isValidprodutoInsumo, setIsValidProdutoInsumo] =
    useState<boolean>(false);
  const [quantidade, setQuantidade] = useState<string>("");
  const [isValidquantidade, setIsValidQuantidade] = useState<boolean>(false);

  const [listaProdutosAdd, setListaProdutosAdd] = useState<any>([]);

  const [unidadeMedidaId, setUnidadeMedidaId] = useState<number>(0);

  const handleValidete = () => {
    if (!produto) setIsValidProduto(true);
    if (produto) setIsValidProduto(false);
    if (!rendimentos) setIsValidRendimentos(true);
    if (rendimentos) setIsValidRendimentos(false);

    if (produto && rendimentos) {
      return true;
    }

    return false;
  };

  const clearFilds = () => {
    setProduto(0);
    setRendimentos("");
    setTipoProducao(1);
    setProdutoInsumo(0);
    setQuantidade("");
    setListaProdutosAdd([]);
  };

  const handleEdit = (props: IFichaTecnica) => {
    setProduto(props.product_id);
    setRendimentos(props.qty.toString());
    setTipoProducao(props.is_produced_on_stock_movement === true ? 1 : 0);
    setListaProdutosAdd(props.relationships.inputs);
  };

  useEffect(() => {
    if (produto) setIsValidProduto(false);
    if (rendimentos) setIsValidRendimentos(false);
    if (produtoInsumo) setIsValidProdutoInsumo(false);
    if (quantidade) setIsValidQuantidade(false);
  }, [produto, rendimentos, produtoInsumo, quantidade]);

  return (
    <DatasheetContext.Provider
      value={{
        loading,
        setLoading,
        fichaTecnica,
        setFichaTecnica,
        produto,
        setProduto,
        unidadeMedida,
        setUnidadeMedida,
        rendimentos,
        setRendimentos,
        tipoProducao,
        setTipoProducao,
        produtoInsumo,
        setProdutoInsumo,
        quantidade,
        setQuantidade,
        isValidproduto,
        setIsValidProduto,
        isValidrendimentos,
        setIsValidRendimentos,
        isValidprodutoInsumo,
        setIsValidProdutoInsumo,
        isValidquantidade,
        setIsValidQuantidade,
        listaProdutosAdd,
        setListaProdutosAdd,
        unidadeMedidaId,
        setUnidadeMedidaId,

        clearFilds,
        handleValidete,
        handleEdit,
      }}
    >
      {children}
    </DatasheetContext.Provider>
  );
};
