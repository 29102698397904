


import { SelectHook } from "../../../../reactHookForm/SelectHook";
import { InputHook } from "../../../../reactHookForm/InputHook";
import { objectStatus } from "../../../../../utils/objectSelect";
import Format from "../../../../../Services/Format";
import DateUtils from "../../../../../Services/DateUtils";
import { useAppSelector } from "../../../../../redux/store";
import { IStore } from "../../../../../redux/features/userMe/interface";

import * as S from "../../../contagem/ListContagem/styled";
import type { IStock } from "../../../../../redux/features/inventary/interface";

interface IProps {
  register: any;
  control: any;
  watch: any;
  setValue: any;
  setError: any;
  errors: any;
  reset: any;
  openFilter: boolean;
}

export function InputFilter({
                              register,
                              control,
                              watch,
                              setValue,
                              setError,
                              reset,
                              openFilter,
                            }: IProps) {
  const periodoState = watch("typedate");
  const inventary = useAppSelector(
    ({ inventaryReducer }) => inventaryReducer.value,
  );

  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  return (
    <S.Wrapper>
      {openFilter && (
        <div className="min-screen">
          <SelectHook
            register={register}
            control={control}
            label="Loja"
            defaultValue={[]}
            name="store"
            placeholder="Loja"
            options={
              storeMe &&
              storeMe?.user?.relationships?.stores?.map((item: IStore) => ({
                value: item.id,
                label: item.trading_name || item.company_name,
              }))
            }
          />

          <SelectHook
            register={register}
            control={control}
            label="Status"
            defaultValue={objectStatus[0]}
            name="status"
            placeholder="Status"
            options={inventary.status}
          />
          <SelectHook
            register={register}
            control={control}
            label="Estoque"
            name="stock_id"
            placeholder="Estoque"
            options={inventary.stock.map((item: IStock) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          <SelectHook
            register={register}
            control={control}
            label="Período"
            defaultValue={inventary.period[0]}
            name="typedate"
            placeholder="Período"
            options={inventary.period}
            isClearable={false}
          />

          <InputHook
            register={register}
            label="De"
            name="start"
            placeholder="Filtrar por data inicial"
            type="date"
            disabled={periodoState?.value === 1 || !periodoState ? true : false}
            defaultValue={Format.firstDate("YYYY-MM-DD")}
          />

          <InputHook
            register={register}
            label="Até"
            name="end"
            placeholder="Filtrar por data final"
            type="date"
            disabled={periodoState?.value === 1 || !periodoState ? true : false}
            defaultValue={Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}
          />
        </div>
      )}

      <div className="max-screen">
        <SelectHook
          register={register}
          control={control}
          label="Loja"
          defaultValue={[]}
          name="store"
          placeholder="Loja"
          options={
            storeMe &&
            storeMe?.user?.relationships?.stores?.map((item: IStore) => ({
              value: item.id,
              label: item.trading_name || item.company_name,
            }))
          }
        />

        <SelectHook
          register={register}
          control={control}
          label="Status"
          defaultValue={objectStatus[0]}
          name="status"
          placeholder="Status"
          options={inventary.status}
        />
        <SelectHook
          register={register}
          control={control}
          label="Estoque"
          name="stock_id"
          placeholder="Estoque"
          options={inventary.stock.map((item: IStock) => ({
            value: item.id,
            label: item.name,
          }))}
        />

        <SelectHook
          register={register}
          control={control}
          label="Período"
          defaultValue={inventary.period[0]}
          name="typedate"
          options={inventary.period}
          isClearable={false}
        />

        <InputHook
          register={register}
          label="De"
          name="start"
          type="date"
          disabled={periodoState?.value === 1 || !periodoState ? true : false}
          defaultValue={Format.firstDate("YYYY-MM-DD")}
        />

        <InputHook
          register={register}
          label="Até"
          name="end"
          type="date"
          disabled={periodoState?.value === 1 || !periodoState ? true : false}
          defaultValue={Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD")}
        />
      </div>
    </S.Wrapper>
  );
}
