import { Button, Col, Modal, Row } from "react-bootstrap";
import { Dispatch, SetStateAction, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { pdvSchema } from "../../../../../../schemas/pdvSchema";
import { AppDispatch, useAppSelector } from "../../../../../../redux/store";

import { notify, notifyError } from "../../../../../notify/notify-component";
import { InputHook } from "../../../../../reactHookForm/InputHook";
import { SelectHook } from "../../../../../reactHookForm/SelectHook";
import { CheckboxHook } from "../../../../../reactHookForm/inputCheckbox";
import { IPaymentEntry } from "../../../../../../redux/features/paymentType/interface";
import { IChartAccount } from "../../../../../../redux/features/chartAccount/interface";
import {
  createPAymentTypesync,
  getPAymentTypesync,
  updatePAymentTypesync,
} from "../../../../../../redux/features/paymentType/services";
import { IObj } from "../typeOptionsGroup/obj";
import { paymantSchema } from "../../../../../../schemas/paymantSchema";
import * as S from "../../../styled";

interface IProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setListPDV: any;
  listPDV: any[];
  listEditeItem: IPaymentEntry | undefined;
  setListEditeItem: Dispatch<SetStateAction<IPaymentEntry | undefined>>;
  setKeyActived: any;
}

type FormData = Yup.InferType<typeof pdvSchema>;

interface IInput {
  is_active: boolean;
  description: string;
  entry_type: number;
  chart_account_id: IObj;
  terminate_days: number;
  tax: number;
  accept_change: boolean;
  visible_pdv: boolean;
}

export default function ModalPDV({
  show,
  setShow,
  setLoading,
  setListPDV,
  listPDV,
  listEditeItem,
  setListEditeItem,
  setKeyActived,
}: IProps) {
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);
  const dispatch = useDispatch<AppDispatch>();
  const { chartAccountExpense } = useAppSelector(
    ({ paymentTypeReducer }) => paymentTypeReducer.value,
  );

  //const [modal, setModal] = useState<boolean>(false)

  const objChartAccount = chartAccountExpense.map((item: IChartAccount) => ({
    label: item.description,
    value: item.id,
  }));

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(paymantSchema),
  });

  const handleClose = () => {
    setShow(false);
    setListEditeItem(undefined);
    resetItens();
  };

  const onSubmit: SubmitHandler<IInput> = async (data: IInput) => {
    setLoading(true);
    const newData = {
      ...data,
      description: data.description,
      entry_type: 0,
      store_id: storeMe?.storeActive?.id,
      chart_account_id: data.chart_account_id.value,
      terminate_days: data.terminate_days,
      tax: data.tax,
      accept_change: data.accept_change ? true : false,
      visible_pdv: data.visible_pdv ? true : false,
      is_active: true,
    };

    if (listEditeItem) {
      const newDataUpdate = {
        ...listEditeItem,
        ...newData,
        is_active: !data?.is_active ? true : false,
      };
      dispatch(updatePAymentTypesync(newDataUpdate)).then((result: any) => {
        if (updatePAymentTypesync.fulfilled.match(result)) {
          dispatch(getPAymentTypesync(storeMe?.storeActive?.id));
        } else {
          notifyError("Erro ao atualizar item.");
        }
      });
      resetItens();
      setShow(false);
      setLoading(false);
      setKeyActived("revenue");
      return;
    }

    dispatch(createPAymentTypesync(newData)).then((result: any) => {
      if (createPAymentTypesync.fulfilled.match(result)) {
        dispatch(getPAymentTypesync(storeMe?.storeActive?.id));
      } else {
        notifyError("Erro ao adicionar item.");
      }
    });
    setKeyActived("revenue");
    setLoading(false);
    resetItens();
    setShow(false);
  };

  const inputError = [errors];

  const resetItens = () => {
    setValue("description", "");
    setValue("chart_account_id", "");
    setValue("terminate_days", "");
    setValue("tax", "");
    setValue("accept_change", "");
    setValue("visible_pdv", "");
    setValue("is_active", "");

    reset({});
  };

  useEffect(() => {
    if (listEditeItem && show) {
      setValue("description", listEditeItem?.description);
      setValue("terminate_days", listEditeItem?.terminate_days);
      setValue("tax", listEditeItem?.tax);
      setValue("accept_change", listEditeItem?.accept_change);
      setValue("visible_pdv", listEditeItem?.visible_pdv);
      setValue("is_active", listEditeItem?.is_active ? false : true);

      setValue(
        "chart_account_id",
        objChartAccount.find(
          (item: any) => item.value === listEditeItem?.chart_account_id,
        ),
      );
    } else {
      resetItens();
      setValue("is_active", false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      style={{ fontSize: "12px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{listEditeItem ? "Editar " : "Adicionar "} Forma de Pagamento</h3>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <S.Wrapper>
            <InputHook
              register={register}
              errors={inputError}
              label="Tipo"
              name="entry_type"
              defaultValue={"Despesa"}
              type="text"
              disabled={true}
            />

            <SelectHook
              register={register}
              control={control}
              errors={inputError}
              label="Plano"
              name="chart_account_id"
              placeholder="Plano de conta"
              options={objChartAccount}
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Descrição"
              name="description"
              placeholder="Descrição"
              type="text"
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Dias"
              name="terminate_days"
              placeholder="Dias para baixa"
              type="number"
            />

            <InputHook
              register={register}
              errors={inputError}
              label="Taxa"
              name="tax"
              placeholder="Taxa"
              type="number"
              step="0.01"
            />
          </S.Wrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" type="submit">
            {listEditeItem ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
