import { useEffect, useState } from "react";
import { IFormRepresentative } from "../../../../../Pages/dashboard/Admin/CadRepresentative";
import { StateForm } from "../../../../../interfaces/state-form";
import Loading from "../../../../Loading/Loading";
import LayoutHookForm from "../../../../reactHookForm/LayoutForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputForm } from "./inputForm";
import * as Yup from "yup";
import { representativeSchema } from "../../../../../schemas/representativeSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Services } from "../../../../../Services/newService";
import { toast } from "react-toastify";
import { notifyError } from "../../../../notify/notify-component";

type FormData = Yup.InferType<typeof representativeSchema>;

export function FormRepresentative({
  title,
  representative,
  editando,
  OnAddNewRepresentative,
  selectOptions,
}: IFormRepresentative) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState,
  } = useForm<FormData>({ resolver: yupResolver(representativeSchema) });
  const [userActived, setUserActived] = useState<boolean>(
    Object.keys(representative).length > 0
      ? !representative.status
        ? false
        : true
      : true,
  );

  const [stateForm, setStateForm] = useState<StateForm>({
    state: "default",
    message: "",
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setStateForm({ state: "loading" });

      if (!editando) {
        const createUser = {
          email: data.email,
          password: "a123#", // remover futuramente
          password_confirmation: "a123#", // remover futuramente
          name: data.name,
        };

        const user = await Services.user(createUser, setStateForm);
        if (!user) return;

        const createProfile = {
          user_id: user?.data?.data?.id?.toString(),
          name: "Representative",
        };

        const profile = await Services.profile(createProfile, setStateForm);
        if (!profile) return;

        const newData = {
          ...data,
          user_id: user?.data?.data?.id?.toString(),
          status: userActived ? 1 : 0,
        };

        const representative = await Services.representative(
          newData,
          setStateForm,
        );

        if (!representative) return;
        setTimeout(() => {
          setStateForm({
            state: "send",
            message: "Representante cadastrado.",
          });
        }, 1000);
        handleCancel();
      } else {
        const newData = {
          ...data,
          status: userActived ? 1 : 0,
        };

        const representativeEdited = await Services.representativePut(
          newData,
          setStateForm,
        );

        if (!representativeEdited) return;
        setTimeout(() => {
          setStateForm({
            state: "send",
            message: "Representante Editado.",
          });
        }, 1000);
        handleCancel();
      }
    } catch (err: any) {
      const erroAxios = err?.response?.data?.errors;
      const erroHandler = erroAxios ? Object.values(erroAxios)[0] : err;

      if (typeof erroHandler === "string") {
        const parseError = JSON.parse(erroHandler);

        setStateForm({
          state: "error",
          message:
            parseError.cause[0].message || "api_error_please_contact_support",
        });
        return;
      }
    }
  };

  const handleCancel = () => {
    reset();
    OnAddNewRepresentative(false);
  };

  useEffect(() => {
    const newReset = {
      ...representative,
      phone: representative?.phone ? representative?.phone : "",
    };
    reset(newReset);
  }, []);

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      notifyError("Por favor preencher todos os campos obrigatórios");
    }
  }, [formState.errors]);

  const inputError = [formState.errors];

  return (
    <>
      <LayoutHookForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        title={title}
        isEditable={editando}
        onClickCancel={handleCancel}
        formState={formState}
        stateForm={stateForm}
      >
        <InputForm
          register={register}
          control={control}
          watch={watch}
          selectOptions={selectOptions}
          setValue={setValue}
          setError={setError}
          errors={inputError}
          setStateForm={setStateForm}
          reset={reset}
          data={representative}
          userActived={userActived}
          setUserActived={setUserActived}
        />
      </LayoutHookForm>

      {stateForm.state === "loading" && <Loading />}
    </>
  );
}
