import { createRoot } from "react-dom/client";
import { App } from "./App";
import "./styles/App.scss";
import "./styles/globalStyles.scss";

import { ThemeProvider } from "../src/context/theme-context";

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
);
