import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Format from "../../../../Services/Format";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as S from "./styles";

import DateUtils from "../../../../Services/DateUtils";
import { getTransfers } from "../../../../Services/transfers-service";
import { getStocks } from "../../../../Services/stock-service";
import LayoutFilter from "../../../data/LayoutFilter";
import ReactSelect from "../../../data/reactSelect";
import { InputDefault } from "../../../data/inputDefault";
import LayoutTable from "../../../data/LayoutTable";
import tableStyleDefault from "../../../../Services/tableStyleDefault";
import { IListaTransferenciaEstoque } from "../../../../Pages/Cadastro/CadTransfEstoque";
import { Modal, Table } from "react-bootstrap";
import { Services } from "../../../../Services/newService";
import GetEndPoint from "../../../../Services/GetEndPoint";
import { notify, notifyError } from "../../../notify/notify-component";
import { useAppSelector, type AppDispatch } from "../../../../redux/store";
import CustomLoader from "../../../data/customLoader";
import { FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getStockTransferAsync } from "../../../../redux/features/stockTransfer/services";
import Loading from "../../../Loading/Loading";

export default function ConsultaTransferencias({
  title,
  OnAddNewCli,
  OnAddNewEstoque,
  onEditItem,
}: IListaTransferenciaEstoque) {
  const dispatch = useDispatch<AppDispatch>();
  const { stockTransfer, stateForm } = useAppSelector(
    ({ stockTransferReducer }) => stockTransferReducer.value,
  );
  const storeMe = useAppSelector(({ storeMeReducer }) => storeMeReducer.value);

  const [loading, setLoading] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAjuste, setShowAjuste] = useState<any>();
  const [emitting_date, setEmittingDate] = useState<number>(0);

  const [type, setType] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    Format.firstDate("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState<string>(
    Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"),
  );
  const [origin, setOrigin] = useState<number>();
  const [destination, setDestination] = useState<number>();
  const [status, setStatus] = useState<string>("");

  // Lists
  const [listType, setListType] = useState<Array<any>>([]);
  const [listOrigin, setListOrigin] = useState<Array<any>>([]);
  const [listDestination, setListDestination] = useState<Array<any>>([]);
  const [listStatus, setListStatus] = useState<Array<any>>([]);
  const [arrayMovimentStockEntry, setArrayMovimentStockEntry] = useState<any>(
    [],
  );
  const [arrayMovimentStockWithdrawal, setArrayMovimentStockWithdrawal] =
    useState<any>([]);
  const [store, setStore] = useState<any>([]);

  // Erros
  const [validDateIni, setValidDateIni] = useState<boolean>(false);
  const [validDateEnd, setValidDateEnd] = useState<boolean>(false);

  const [filter, setFilter] = useState<string>("");
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const objectTypes = [
    {
      value: 0,
      label: "Todos",
    },
    {
      value: 1,
      label: "Interna",
    },
    {
      value: 2,
      label: "Externa",
    },
  ];

  const listdate = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Data de emissão" },
  ];

  const isLater = (start: string, end: string) => {
    return start > end;
  };

  const handleDateStart = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setStartDate(date);
    const dateValid = isLater(date, endDate);
    setValidDateIni(dateValid);
    if (dateValid) {
      return;
    }
  };

  const handleDateEnd = async (date: string) => {
    if (!date) return;
    if (date.length !== 10) {
      setValidDateIni(false);
      return;
    }
    setEndDate(date);
    const dateValid = isLater(startDate, date);
    setValidDateEnd(dateValid);

    if (dateValid) {
      return;
    }
  };

  const clearFields = () => {
    setType(0);
    setOrigin(0);
    setDestination(0);
    setStatus("");
    setStartDate(Format.firstDate("YYYY-MM-DD"));
    setEndDate(Format.date(DateUtils.endOfMonth(), "YYYY-MM-DD"));
    setFilter("");
    setListType([]);
    setListOrigin([]);
    setListDestination([]);
    setListStatus([]);
    dispatch(getStockTransferAsync({ store_id: storeMe?.storeActive?.id }));
    setEmittingDate(0);
    fillStocks();
  };

  const createFilter = (filterList: Array<string>): string => {
    let filter = "";
    for (let i = 0; i < filterList.length; i++) {
      if (i >= 1) {
        filter += "&";
      }
      filter += `filter${filterList[i]}`;
    }
    return filter;
  };
  const handleSearchList = () => {
    if (validDateIni || validDateEnd) return;
    const filterList = [];
    if (type) filterList.push(`[stock_transfer_type_id]=${type}`);
    if (origin) filterList.push(`[stock_origin_id]=${origin}`);
    if (destination) filterList.push(`[stock_destination_id]=${destination}`);
    if (emitting_date === 1) {
      filterList.push(`[starts_at]=${startDate}`);
      filterList.push(`[ends_at]=${endDate}`);
    }
    if (filterList.length > 0) {
      const filter = createFilter(filterList);
      if (filter !== "") {
        dispatch(
          getStockTransferAsync({ store_id: storeMe?.storeActive?.id, filter }),
        );
        setFilter(filter);
      }
      return;
    }

    dispatch(getStockTransferAsync({ store_id: storeMe?.storeActive?.id }));
    //notify();
  };

  const columns = [
    {
      name: "Origem",
      selector: (row: any) =>
        row.relationships.stock_origin?.name.toLowerCase(),
    },
    {
      name: "Destino",

      selector: (row: any) =>
        row.relationships?.stock_destination?.name.toLowerCase(),
    },
    {
      name: "Tipo",
      center: true,
      grow: 0.3,
      selector: (row: any) =>
        row.stock_transfer_type_id === 1 ? "Interna" : "Externa",
    },
    {
      name: "Data e Hora",
      center: true,
      grow: 0.4,
      selector: (row: any) =>
        Format.date(DateUtils.toDate(row.created_at), "DD/MM/YYYY HH:mm:ss"),
    },

    {
      name: "Status",
      center: true,
      grow: 0.3,
      cell: (row: any) =>
        row.situation_id === 2 ? (
          <span>Estornado</span>
        ) : (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Estornar</Tooltip>}
          >
            <S.Span onClick={() => handleChargeBack(row?.uuid)}>
              Estornar
            </S.Span>
          </OverlayTrigger>
        ),
      button: true,

      selector: (row: any) =>
        row.situation_id === 2 ? "Estornado" : "Realizado -",
    },

    {
      name: "Ação",
      center: true,
      grow: 0.3,
      cell: (row: any) => (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Visualizar</Tooltip>}
        >
          <span className="">
            <Button
              onClick={() => handleShow(row)}
              variant="contained"
              color="primary"
            >
              <FaEye size={16} color="8e8d8d" />
            </Button>
          </span>
        </OverlayTrigger>
      ),
      button: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Registros por página",
    rangeSeparatorText: "de",
  };

  const handleClose = () => setModalIsOpen(false);

  const handleShow = (row: any) => {
    setShowAjuste(row);
    setModalIsOpen(true);
  };

  const handleAdd = () => {
    OnAddNewCli(true);
  };

  const fillStocks = async () => {
    await getStocks(storeMe?.storeActive?.id).then((response) => {
      const data = response.data.data.sort((a: any, b: any) => a.id - b.id);
      const list = data.map((item: any) => ({
        value: item.id,
        label: item.name,
        store_id: item.store_id,
      }));
      setListOrigin(list);
      setListDestination(list);
    });
  };

  const fillData = async () => {
    const transfers = await getTransfers();
    const type: any = [];

    transfers.data.data.map((res: any) => {
      type.push(res.relationships.stock_transfer_type);
    });
    setListType(type);
  };

  const handleChargeBack = async (uuid: string) => {
    setLoading(true);
    try {
      const charge = await Services.stockTransfersChargeBack(uuid);
      if (!charge) return;
      notify("Transferencia de estoque estornado");
      dispatch(
        getStockTransferAsync({ store_id: storeMe?.storeActive?.id, filter }),
      );
    } catch (error: any) {
      notifyError(error);
    }
    setLoading(false);
  };

  const onClickOpenFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    dispatch(
      getStockTransferAsync({ store_id: storeMe?.storeActive?.id, filter }),
    );
    fillData();
    fillStocks();
  }, [dispatch]);

  useEffect(() => {
    if (
      showAjuste?.relationships?.stock_withdrawal_movement &&
      showAjuste?.relationships?.stock_entry_movement
    ) {
      GetEndPoint.movimentStockByUuid(
        setArrayMovimentStockEntry,
        showAjuste?.relationships?.stock_entry_movement?.uuid,
      );
      GetEndPoint.movimentStockByUuid(
        setArrayMovimentStockWithdrawal,
        showAjuste?.relationships?.stock_withdrawal_movement?.uuid,
      );
      GetEndPoint.store(setStore, storeMe?.storeActive?.id);
    }
  }, [showAjuste]);

  const newListOrigin = listOrigin.filter(
    (item) => item.store_id === storeMe?.storeActive?.id,
  );

  const newListDestination = listDestination.filter(
    (item) => item.store_id === storeMe?.storeActive?.id,
  );

  return (
    <>
      <LayoutFilter
        title={title}
        onClickAdd={handleAdd}
        onClickClear={clearFields}
        onClickSearch={handleSearchList}
        onClickOpenFilter={onClickOpenFilter}
        openFilter={openFilter}
      >
        <S.Wrapperr>
          {openFilter && (
            <div className="min-screen">
              <ReactSelect
                label="Origem Est."
                placeholder={"Estoque"}
                options={newListOrigin}
                state={origin}
                setState={setOrigin}
              />

              <ReactSelect
                label="Destino Est."
                placeholder={"Estoque"}
                options={newListDestination}
                state={destination}
                setState={setDestination}
              />

              <ReactSelect
                label="Tipo"
                placeholder={"Tipo"}
                options={objectTypes}
                state={type}
                setState={setType}
              />

              <ReactSelect
                label="Período"
                state={emitting_date}
                options={listdate}
                isInvalid={false}
                setState={setEmittingDate}
              />

              <InputDefault
                label="De"
                type="date"
                disabled={emitting_date === 0}
                state={startDate}
                onChenge={(e: any) => handleDateStart(e.target.value)}
                isInvalid={validDateIni}
              />

              <InputDefault
                label="Até"
                type="date"
                disabled={emitting_date === 0}
                state={endDate}
                onChenge={(e) => handleDateEnd(e.target.value)}
                isInvalid={validDateEnd}
              />
            </div>
          )}

          <div className="max-screen">
            <ReactSelect
              label="Origem Est."
              options={newListOrigin}
              state={origin}
              setState={setOrigin}
            />

            <ReactSelect
              label="Destino Est."
              options={newListDestination}
              state={destination}
              setState={setDestination}
            />

            <ReactSelect
              label="Tipo"
              options={objectTypes}
              state={type}
              setState={setType}
            />

            <ReactSelect
              label="Período"
              state={emitting_date}
              options={listdate}
              isInvalid={false}
              setState={setEmittingDate}
            />

            <InputDefault
              label="De"
              type="date"
              disabled={emitting_date === 0}
              state={startDate}
              onChenge={(e: any) => handleDateStart(e.target.value)}
              isInvalid={validDateIni}
            />

            <InputDefault
              label="Até"
              type="date"
              disabled={emitting_date === 0}
              state={endDate}
              onChenge={(e) => handleDateEnd(e.target.value)}
              isInvalid={validDateEnd}
            />
          </div>
        </S.Wrapperr>
      </LayoutFilter>

      <LayoutTable>
        <DataTable
          className={styles.dataTable}
          columns={columns}
          data={stockTransfer || []}
          striped
          pagination
          paginationDefaultPage={1}
          paginationPerPage={25}
          customStyles={tableStyleDefault}
          paginationComponentOptions={paginationOptions}
          progressPending={stateForm.state === "loading"}
          progressComponent={<CustomLoader />}
          noDataComponent="Nenhuma informação foi encontrada."
        />
      </LayoutTable>

      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "#ced0d4f4" }}>
          <S.Title>
            {"Trasferência" +
              " " +
              showAjuste?.relationships?.stock_transfer_type?.description}
          </S.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f7f7f8f4" }}>
          <S.Wrapper>
            <ul>
              <li>
                <span>Status:</span> {""}
                {showAjuste?.situation_id === 2 ? " Estornado" : "Realizado"}
              </li>
              <li>
                <span>Loja de origem: </span>{" "}
                {
                  store.find(
                    (item: any) =>
                      item.value ===
                      showAjuste?.relationships.stock_origin?.store_id,
                  )?.label
                }
              </li>
              <li>
                <span>Local de Estoque: </span>{" "}
                {showAjuste?.relationships.stock_origin?.name}
              </li>
              <li>
                <span>Loja de destino: </span>{" "}
                {
                  store.find(
                    (item: any) =>
                      item.value ===
                      showAjuste?.relationships.stock_destination?.store_id,
                  )?.label
                }
              </li>
              <li>
                <span>Local de Estoque: </span>{" "}
                {showAjuste?.relationships.stock_destination?.name}
              </li>
              {showAjuste?.note && (
                <li>
                  <span>Observação: </span> {showAjuste?.note}
                </li>
              )}
              {showAjuste?.chargeback_dt && (
                <li>
                  <span>Data de estorno:</span> {showAjuste?.chargeback_dt}
                </li>
              )}
            </ul>
            <Table
              striped
              hover
              bordered
              size="sm"
              style={{ marginTop: "30px" }}
            >
              <thead>
                <S.Thead className="trTable">
                  <th>Nome do Produto</th>
                  <th>Quantidade</th>
                  <th>Unidade de Medida</th>
                </S.Thead>
              </thead>
              <tbody>
                {arrayMovimentStockEntry?.relationships?.products?.map(
                  (item: any, index: any) => {
                    return (
                      <S.Thead key={index}>
                        <td>
                          {item?.relationships?.product?.sale_name
                            ? item?.relationships?.product?.sale_name
                            : ""}
                        </td>
                        <td>{item.qty}</td>
                        <td>
                          {
                            item?.relationships?.product?.relationships
                              ?.sale_measure_unit?.initials
                          }
                        </td>
                      </S.Thead>
                    );
                  },
                )}
              </tbody>
            </Table>
          </S.Wrapper>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#f7f7f8f4" }}></Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
