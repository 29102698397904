import styled from "styled-components";

interface IProps {
  collapsed: boolean;
  pathname: boolean;
}

export const MenuItem = styled.div<IProps>`
  /*   
  &:nth-child(6) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--yellow-900);
  }
  */

  .hover-menu {
    position: absolute;
    min-width: 250px;
    left: 100%; /* Mantém o menu ao lado do item */
    z-index: 1000;
    a,
    .link-modal {
      padding: 0 4px;
    }
    a:hover,
    .link-modal:hover {
      background-color: #5467c4;
      border-radius: 8px;
    }
  }

  .menu-open {
    display: flex;

    svg {
      fill: white;
      font-weight: bold;
    }
  }

  .sidebar-menu {
    background-color: #273371;
    padding: 1rem 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: 0.3rem;
    margin-top: auto;

    span {
      font-weight: bold;
      color: white;
      font-size: 14px;
      display: block;
      margin-top: 0.5rem;
    }

    a,
    .link-modal {
      color: #fff;
      text-decoration: none;
      margin-bottom: 0.4rem;
      display: block;
      font-size: 12px;
      cursor: pointer;
      margin-left: 1rem;

      &:hover,
      hover:link-modal {
        color: #ddd;
      }
    }
  }

  .title-icon {
    display: flex;
    flex-direction: ${({ collapsed }) => (collapsed ? "column" : "row")};
    width: ${({ collapsed }) => (collapsed ? "100px" : "100%")};
    padding: 0.3rem;
    gap: ${({ collapsed }) => (collapsed ? "" : "0.4rem")};
    align-items: center;

    span {
      display: block;
      font-size: ${({ collapsed }) => (collapsed ? " 10px" : " 12px")};
      margin-top: ${({ collapsed }) => (collapsed ? " 0.5rem" : "")};
      font-weight: bold;
    }
    height: ${({ collapsed }) => (collapsed ? "auto" : " 50px")};
    background-color: ${({ pathname, collapsed }) =>
      pathname && collapsed ? "#5467c4" : ""};

    :hover {
      background-color: #273371;
    }
  }

  .menu-open {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 270px;
    padding: 0 0.7rem;
    color: white;
    background-color: ${({ pathname }) => (pathname ? "#5467c4" : "#110f23")};
    border: none;
    :hover {
      background-color: #5467c4;
    }

    svg {
      margin-right: 10px;
    }
  }

  .drop {
    width: 100%;
    border: unset;
    background-color: transparent;
    text-align: left;
    color: #fff;
    height: 28px;
    border-radius: 5px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    > div {
      display: inline-flex;
      align-items: center;
      font-size: 16.5px;
    }
  }

  button + * {
    margin-top: 8px;
  }

  .sub-menu {
    display: flex;
    flex-direction: column;
    margin-left: 41px;
    gap: 11px;

    a,
    .link-modal {
      color: #fff;
      width: fit-content;
      font-size: 15px;
      border-radius: 5px;
      padding: 2px 5px;
      font-size: 13.5px;
      width: 90%;
      font-size: 12px;
      margin-left: 1rem;
      cursor: pointer;
    }

    span {
      font-weight: bold;
      color: white;
      font-size: 13px;
      display: block;
      margin-top: 0.5rem;
    }

    a:hover,
    .link-modal:hover {
      background-color: #273371;
      color: #ddd;
    }
  }
`;
