import { useContext } from "react";
import { Container } from "react-bootstrap";
import { ThemeContext } from "../../../context/theme-context";
import { SelfServiceComponente } from '../../../Components/Configuracao/Apps/selfService';

export interface ISelfServiceComponente {
  title: string;
}

export default function SelfService() {
  const { collapsed } = useContext(ThemeContext);

  return (
    <>
      <Container
        className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
      >
        <SelfServiceComponente
          title={"Autoatendimento"}
        />
      </Container>
    </>
  );
}
