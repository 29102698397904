import { Dispatch, SetStateAction, useContext } from "react";
import { Container } from "react-bootstrap";
import { ThemeContext } from "../../../context/theme-context";
import { ListUserSystem } from "../../../Components/Configuracao/UserSystem/ListUserSystem";

export interface IListUserSystem {
  title: string;
}

export interface IFormUserSystem {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  listEditeItem: any | undefined;
  setListEditeItem: Dispatch<SetStateAction<any | undefined>>;
}

export interface ISelectOptions {}

export default function CadUserSystem() {
  const { collapsed } = useContext(ThemeContext);

  return (
    <Container
      className={collapsed ? "contenteLayoutCollapsed" : "contenteLayout"}
    >
      <ListUserSystem title="Usuários" />
    </Container>
  );
}
