import { defaultThemes } from "react-data-table-component";

const tableStyleDefaultFinancial: any = {
  header: {
    style: {
      height: "50px",
      fontWeight: "bold",
    },
  },
  headRow: {
    style: {
      color: "black",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,
      fontWeight: "bold",
      backgroundColor: "#E5E7EB",
      fontSize: "11px",
      height: "50px",
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
        height: "50px",
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
        textTransform: "capitalize",
        fontSize: "11px",
        height: "50px",
      },
    },
  },
  rows: {
    style: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,
      borderTopStyle: "none",
      fontSize: "11px",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#E0F7FA",
      },
      height: "50px",
    },
  },
  footer: {
    style: {
      backgroundColor: "#E5E7EB",
      color: "black",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,
      textAlign: "center",
      height: "50px",
      fontSize: "11px",
    },
  },
};

export default tableStyleDefaultFinancial;
