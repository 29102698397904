import Globals from "../Globals.json";
import api from "../Services/api";

export const fetchAutocomplete = async (inputValue: string): Promise<any[]> => {
  try {
    const response = await api.get(
      Globals.api.products +
        `?filter[product_name]=${encodeURIComponent(inputValue)}`,
    );
    const data = response?.data?.data;
    return data?.map((item: any) => ({
      label: item.sale_name,
      value: item.id,
    }));
  } catch (error) {
    console.error("Erro ao buscar dados de autocomplete:", error);
    return [];
  }
};
