export class Regex {
  static document(number: string) {
    const numeroSemCaracteres = number.replace(/\D/g, "");

    if (numeroSemCaracteres.length === 11) {
      return numeroSemCaracteres?.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        "$1.$2.$3-$4",
      );
    } else if (numeroSemCaracteres.length === 14) {
      return numeroSemCaracteres?.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5",
      );
    } else {
      return "Número inválido";
    }
  }

  static removeCharacters(numeroFormatado: string): string {
    const numeroSemCaracteres =
      numeroFormatado && numeroFormatado?.replace(/\D/g, "");

    return numeroSemCaracteres;
  }

  static validarNumeroDeCelular(numeroDeCelular: string): boolean {
    const regex: RegExp = /\(\d{2}\) \d{4}-\d{4}/;
    return regex.test(numeroDeCelular);
  }

  static converterDataParaPadraoBR(data: string): string {
    const partes = data.split("-");
    if (partes.length === 3) {
      const [ano, mes, dia] = partes;
      return `${dia}/${mes}/${ano}`;
    } else {
      throw new Error('Formato de data inválido. Use o formato "AAAA-MM-DD".');
    }
  }

  static converterDataParaPadraoUSA(data: string): string {
    const partes = data.split("/");
    if (partes.length === 3) {
      const [dia, mes, ano] = partes;
      if (/^\d{2}$/.test(mes) && /^\d{2}$/.test(dia) && /^\d{4}$/.test(ano)) {
        return `${ano}-${mes}-${dia}`;
      } else {
        throw new Error(
          'Formato de data inválido. Use o formato "MM/DD/AAAA".',
        );
      }
    } else {
      throw new Error('Formato de data inválido. Use o formato "MM/DD/AAAA".');
    }
  }
}
