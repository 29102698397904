import { useContext, useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { MovimentacaoContext } from "../context/movimentacao-bancario-context";
import Format from "../../../../Services/Format";
import styles from "./styles.module.scss";
import { reverseStr } from "../../../../Services/reverseString";
import ActiveInactiv from "../../../data/activeInactive";
import tableStyleDefaultFinancial from "../../../../Services/tableStyleDefaultFinancial";

export default function ListMovimentacaoBancario(props: any) {
  const { listExtratoBancario, startDate, banckAccountId, listBank, endDate } =
    useContext(MovimentacaoContext);

  const newDate = new Date();
  const [banckName, setBanckName] = useState<string>("");
  const [banckAgency, setBanckAgency] = useState<string>("");
  const [banckAgencyDigit, setBanckAgencyDigit] = useState<string>("");
  const [banckAccount, setBanckAccount] = useState<string>("");
  const [banckAccountDigit, setBanckAccountDigit] = useState<string>("");
  const [banckBalance, setBanckBalance] = useState<number>(0);
  const [totalCredito, setTotalCredito] = useState<number>(0);
  const [totalDebito, setTotalDebito] = useState<number>(0);

  let amount = banckBalance;

  const getBalance = () => {
    const amountCredito: any = [];
    const amountDebito: any = [];

    for (let index = 0; index < listExtratoBancario.length; index++) {
      if (
        listExtratoBancario[index].relationships?.paymentType?.entry_type === 1
      ) {
        amountCredito.push({
          amount: listExtratoBancario[index].amount,
        });
      } else {
        amountDebito.push({
          amount: listExtratoBancario[index].amount,
        });
      }
    }

    if (amountCredito.length === 1) {
      setTotalCredito(amountCredito[0].amount);
    } else {
      setTotalCredito(
        amountCredito.reduce((a: any, b: any) => a + b.amount, 0),
      );
    }

    if (amountDebito.length === 1) {
      setTotalDebito(amountDebito[0].amount);
    } else {
      setTotalDebito(amountDebito.reduce((a: any, b: any) => a + b.amount, 0));
    }
  };

  const columns = [
    {
      name: "Data",
      center: true,
      width: "px",
      maxWidth: "120px",
      selector: (row: any) => Format.date(row?.created_at, "DD/MM/YYYY"),
    },
    {
      name: "Número",
      maxWidth: "120px",
      center: true,
      selector: (row: any) => row?.document,
    },

    {
      name: "Tipo",
      maxWidth: "120px",
      center: true,
      selector: (row: any) => {
        if (row?.relationships?.paymentType?.entry_type === 1) {
          return "Receita";
        } else if (row?.relationships?.paymentType?.entry_type === 0) {
          return "Despesa";
        }
      },
    },

    {
      name: "Status",
      maxWidth: "100px",
      center: true,
      selector: (row: any) => {
        if (row.status === 2) {
          return <>Fechado</>;
        } else if (row.status === 1) {
          return <>Aberto</>;
        }
      },
    },
    {
      name: "Descrição",
      maxWidth: "220px",
      selector: (row: any) =>
        row?.description ?? row?.relationships?.paymentType?.description,
    },
    {
      name: "Crédito",
      maxWidth: "130px",
      center: true,
      selector: (row: any) => {
        if (row.credit) {
          return row.credit;
        } else if (row?.relationships?.paymentType?.entry_type === 1) {
          return Format.currency(row?.amount?.toFixed(2));
        } else if (row?.relationships?.paymentType?.entry_type === 0) {
          return "-";
        }
      },
    },

    {
      name: "Débito",
      maxWidth: "130px",
      center: true,
      selector: (row: any) => {
        if (row.debit) {
          return row.debit;
        } else if (row?.relationships?.paymentType?.entry_type === 0) {
          return Format.currency(row?.amount?.toFixed(2));
        } else if (row?.relationships?.paymentType?.entry_type === 1) {
          return "-";
        }
      },
    },

    {
      name: "Saldo",
      maxWidth: "130px",
      center: true,
      selector: (row: any) => {
        if (row.sald) {
          return row.sald;
        } else if (row?.relationships?.paymentType?.entry_type === 1) {
          return "R$ " + (amount += row.amount)?.toFixed(2);
        } else if (row?.relationships?.paymentType?.entry_type === 0) {
          return "R$ " + (amount -= row.amount)?.toFixed(2);
        } else {
          return "-";
        }
      },
    },
  ];

  const footer = (tableList: any) => {
    return {
      created_at: newDate,
      description: "Total",
      credit: (
        <ActiveInactiv status={true} text={Format.currency(totalCredito)} />
      ),
      debit: (
        <ActiveInactiv status={false} text={Format.currency(totalDebito)} />
      ),
      sald: Format.currency(totalCredito - totalDebito + banckBalance),
    };
  };

  const footer1 = (tableList: any) => {
    return {
      description: "Resultado geral",
      credit: (
        <ActiveInactiv status={true} text={Format.currency(totalCredito)} />
      ),
      debit: (
        <ActiveInactiv status={false} text={Format.currency(totalDebito)} />
      ),
      sald: Format.currency(totalCredito - totalDebito),
    };
  };

  const footerData = footer(listExtratoBancario);
  const footerData1 = footer1(listExtratoBancario);

  const columnsData = [
    ...listExtratoBancario,
    { ...footerData, isFooter: true },
    { ...footerData1, isFooter: true },
  ];
  const conditionalRowStyles = [
    {
      when: (tableList: any) =>
        tableList.description === "Total" ||
        tableList.description === "Resultado geral",
      style: {
        backgroundColor: "#E5E7EB",
        fontWeight: "bold",
        fontSize: "12px",
        color: "black",
      },
    },
  ];

  useEffect(() => {
    getBalance();
    setBanckName(
      listBank.find((option: any) => option.id === banckAccountId).bank,
    );
    setBanckAgency(
      listBank.find((option: any) => option.id === banckAccountId).agency,
    );
    setBanckAgencyDigit(
      listBank.find((option: any) => option.id === banckAccountId).agency_digit,
    );
    setBanckAccount(
      listBank.find((option: any) => option.id === banckAccountId).account,
    );
    setBanckAccountDigit(
      listBank.find((option: any) => option.id === banckAccountId)
        .account_digit,
    );
    setBanckBalance(
      listBank.find((option: any) => option.id === banckAccountId).balance,
    );
  }, [listBank, listExtratoBancario]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.title}>
          Movimentação Bancária
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.containerBanck}>
          {banckName ? (
            <div className={styles.bankName} style={{ fontSize: "13px" }}>
              {banckName} -
              <span>
                Agência: {banckAgency}-{banckAgencyDigit}
              </span>
              <span>
                Conta: {banckAccount}-{banckAccountDigit}
              </span>
            </div>
          ) : (
            <div>Não possui banco cadastrado</div>
          )}
          <div style={{ fontSize: "13px" }}>
            Período: {reverseStr(startDate)} até {reverseStr(endDate)}
          </div>

          <div
            style={{
              fontWeight: "bold",
              width: "300px",
              display: "flex",
              gap: "1rem",
            }}
          >
            <span style={{ fontSize: "13px" }}>Saldo Inicial:</span>
            <div style={{ fontWeight: "bold", width: "100px" }}>
              {banckBalance > 0 ? (
                <ActiveInactiv
                  status={true}
                  text={Format.currency(banckBalance)}
                />
              ) : (
                <ActiveInactiv
                  status={false}
                  text={Format.currency(banckBalance)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mx-4 my-4">
          {listExtratoBancario.length > 0 ? (
            <>
              <DataTable
                columns={columns}
                data={columnsData || []}
                striped
                customStyles={tableStyleDefaultFinancial}
                conditionalRowStyles={conditionalRowStyles}
              />
            </>
          ) : (
            <Card.Text className="text-center">
              Nenhum registro encontrado para sua pesquisa.
            </Card.Text>
          )}
        </div>
      </Modal.Body>
    </>
  );
}
