import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { ApiResponse } from "../interface";

export const getStoreMeAsync = createAsyncThunk(
  "store/me",
  async (_, thunkAPI) => {
    try {
      const response = await api.get<ApiResponse>(globals.api.me);

      localStorage.setItem(
        "acitvedStore",
        JSON.stringify(response.data.data.relationships.stores[0]),
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
