import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";
import { sortByCreated } from "../../../../Services/sortByCreated";

export const getUserProfileAsync = createAsyncThunk(
  "user-profile",
  async (activedStoreId: number, thunkAPI) => {
    try {
      const res = await api.get(
        globals.api.permissions + `?paginate=false&store_id=${activedStoreId}`,
      );
      return sortByCreated(res.data.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createUserProfileAsync = createAsyncThunk(
  "create/UserProfile",
  async (data: any, thunkAPI) => {
    try {
      const dataPErmissionRole = {
        store_id: data.storeId,
        role_id: data.role_id,
        permission_names: data.permission_names,
      };
      const resRole = await api.post(
        globals.api.permissionsRoles,
        dataPErmissionRole,
      );
      return resRole.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editeUserProfileAsync = createAsyncThunk(
  "put/UserProfile",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.permissions, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteUserProfileAsync = createAsyncThunk(
  "delete/UserProfile",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.permissions + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
