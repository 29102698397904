
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import InputImg from '../../../../data/inputImg';
import { ISelfServiceProduct } from '../../../../../redux/features/selfService/interface';
import {
  editeProductSelfServiceAsync,
  getProductByCategoryAsync,
} from '../../../../../redux/features/selfService/services';
import { notifyError } from '../../../../notify/notify-component';

interface IProps {
  show: boolean;
  handleClose: () => void;
  selectedProduct: ISelfServiceProduct | undefined
  logo: any;
  setLogo: any;
  dispatch: any;
  currentPage: number;
  filter:string;
  storeMe:any;
}

export function ModalAPP({show, handleClose, selectedProduct, logo, setLogo, dispatch, currentPage, filter, storeMe}:IProps) {
  
  
  const handleChangeImg = async () => {
    try {
      await dispatch(editeProductSelfServiceAsync({uuid: selectedProduct?.uuid, image: logo,}));
      dispatch(getProductByCategoryAsync({ activedStoreId: storeMe?.storeActive?.id,
        page: currentPage, filter: filter }));
      handleClose()
    } catch (error) {
      notifyError("Erro ao atualizar imagem");
      console.error(error);
    }
  }
  
  useEffect(() => {
    setLogo(selectedProduct?.image);
  }, [selectedProduct?.image, setLogo]);

  console.log("logo", logo);
  
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Imagem</Modal.Title>
      </Modal.Header>
      <Modal.Body> <InputImg state={logo} setState={setLogo} /></Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} type="button">
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleChangeImg} type="button">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}