import { sortByCreated } from "../../../../Services/sortByCreated";
import { IChartAccount } from "../interface";

export const getEntryType = (response: IChartAccount[], entrytype: number) => {
  const dataFilter = response.filter(
    (expense: any) => expense.entry_type === entrytype,
  );
  const sortedData = sortByCreated(dataFilter);

  const compare = (a: any, b: any) => {
    const getParentId = (item: any) =>
      item.parent_id === 0 ? -1 : item.parent_id;
    return getParentId(a) - getParentId(b) || a.id - b.id;
  };

  const data = sortedData.sort(compare);

  const result: any[] = [];

  for (const item of data) {
    const {
      id,
      description,
      parent_id,
      is_active,
      entry_type,
      store_id,
      uuid,
      created_at,
      updated_at,
    } = item;

    const parent = result.find((p) => p.id === parent_id);

    if (parent) {
      parent.children.push(item);
    } else {
      result.push({
        id,
        description,
        parent_id,
        children: [item],
        is_active,
        entry_type,
        store_id,
        uuid,
        created_at,
        updated_at,
      });
    }
  }
  return sortByCreated(result);
};
