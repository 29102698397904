import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector, type AppDispatch } from "../../../../../redux/store";
import tableStyleDefaulInternal from "../../../../../Services/tableStyleDefaultInternal";
import { paginationOptions } from "../../../../data/PagineteInfo";
import { SelectHook } from "../../../../reactHookForm/SelectHook";
import { useForm } from "react-hook-form";
import type { IProduct } from "../../../../../redux/features/inventary/interface";
import { InputHook } from "../../../../reactHookForm/InputHook";
import * as S from "../../../styled";
import { Btn } from "../../../../data/Btn";
import { addItemTable } from "../../../../../redux/features/stockTransfer/stock-transfer-slice";
import { notifyError } from "../../../../notify/notify-component";
import ModalBootstrap from "../../../../data/Modal";
import { BsTrash } from "react-icons/bs";

interface IProps {
  stockTransfer: any;
}

export function CadProduct({ stockTransfer }: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { products, tableProducts } = useAppSelector(
    ({ stockTransferReducer }) => stockTransferReducer.value,
  );
  const {
    register,
    control,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<any>();
  const inputError = [errors];

  const [id, setId] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const productSelected: IProduct = watch("product_id");
  const [modalConfirmation, setModalConfirmation] = useState<boolean>(false);

  const columns = [
    {
      name: "Produto",
      selector: (row: IProduct) => row?.sale_name,
    },
    {
      name: "Quantidade atual",
      maxWidth: "140px",
      center: true,
      selector: (row: IProduct) => row?.stock_qty,
    },
    {
      name: "Quantidade desejada",
      maxWidth: "150px",
      center: true,
      selector: (row: IProduct) => row?.qty,
    },
    {
      name: "Unidade",
      width: "150px",
      center: true,
      selector: (row: IProduct) => row?.relationships?.sale_measure_unit?.name,
    },
    {
      name: "Ação",
      center: true,

      cell: (row: any) => (
        <div
          className="actions"
          style={{ display: "flex", gap: "15px", marginLeft: "14px" }}
        >
          <FaEdit
            data-toggle="tooltip"
            data-placement="top"
            title="Editar Endereço"
            className="action"
            onClick={() => handleEdit(row)}
            size={17}
            color="#50cd89"
          />
          <BsTrash
            size={16}
            data-toggle="tooltip"
            data-placement="top"
            title="Deletar Endereço"
            className="action"
            onClick={() => handleModalId(row.id)}
            style={{ marginRight: 15 }}
            color="#0095e8"
          />
        </div>
      ),
      button: true,
    },
  ];

  const handleTableProduct = () => {
    if (!watch("product_id").value)
      return setError("product_id", {
        type: "custom",
        message: "Por favor selecione um produto.",
      });
    if (!watch("qty"))
      return setError("qty", {
        type: "custom",
        message: "Por favor informe a quantidade desejada.",
      });

    const product_id = watch("product_id")?.id;
    const qty = watch("qty");
    const getProduct = products.find(
      (item: IProduct) => item.id === product_id,
    );
    const newData = {
      ...getProduct,
      product_id,
      qty: Number(qty),
    };
    const existIntable = tableProducts.find((item) => item.id === product_id);
    if (existIntable) {
      notifyError("Produto já cadastrado.");
      return;
    }
    dispatch(addItemTable([...tableProducts, newData]));

    setValue("product_id", {});
    setError("product_id", {
      type: "custom",
      message: "",
    });
    setValue("qty", "");
    setError("qty", {
      type: "custom",
      message: "",
    });
    reset();
  };

  const handleEdit = (data: any) => {
    setIsEdit(true);
    setValue("product_id", { ...data, value: data.id, label: data.sale_name });

    setValue("qty", Number(data.qty));

    const removeItem = tableProducts.filter(
      (item: any) => item?.id !== data?.id,
    );
    dispatch(addItemTable([...removeItem]));
  };

  const handleDelete = async (id: string) => {
    const newData = tableProducts.filter((item: any) => item?.id !== id);
    dispatch(addItemTable(newData));
  };

  const handleInputProduct = () => {
    //setValue("qty", "");
    setValue(
      "unid",
      productSelected?.relationships?.sale_measure_unit.initials,
    );
    setValue("stock_qty", productSelected?.stock_qty);
  };

  const clearInputProduct = () => {
    setValue("product_id", "");
    setValue("qty", "");
    setValue("unid", "");
    setValue("stock_qty", "");
  };

  const handleModalId = (id: string) => {
    setId(id);
    setModalConfirmation(true);
  };

  const handleDeleteTransfer = () => {
    setModalConfirmation(false);
    handleDelete(id);
  };

  useEffect(() => {
    handleInputProduct();
  }, [productSelected]);
  useEffect(() => {
    clearInputProduct();
  }, [stockTransfer]);

  return (
    <>
      <ModalBootstrap
        state={modalConfirmation}
        setState={setModalConfirmation}
        text="Certeza que deseja excluir o produto?"
        handleAction={handleDeleteTransfer}
      />

      <S.WrapperTable>
        <h3> Adicionar Itens </h3>
        <form>
          <S.Wrapper>
            <SelectHook
              register={register}
              control={control}
              label="Produto"
              defaultValue={[]}
              errors={inputError}
              name="product_id"
              placeholder="Produto"
              options={products.map((item: IProduct) => ({
                ...item,
                value: item.id,
                label: item.sale_name,
              }))}
            />
            <InputHook
              register={register}
              errors={inputError}
              label="Unid. medida"
              name="unid"
              placeholder="Unidade de medida"
              type="string"
              disabled={true}
            />
            <InputHook
              register={register}
              errors={inputError}
              label="Qtd. Atual"
              name="stock_qty"
              placeholder="Quantidade Atual"
              type="string"
              disabled={true}
            />
            <InputHook
              register={register}
              errors={inputError}
              label="Qtd. Desejada"
              name="qty"
              placeholder="Quantidade Desejada"
              type="number"
              min="0"
            />
          </S.Wrapper>
          <S.WrapperBtn className="wrapper-btn">
            <Btn
              text={"Adicionar"}
              variante="primary"
              type="button"
              onClick={handleTableProduct}
            />
          </S.WrapperBtn>
        </form>
        <div style={{ margin: "0 2rem", overflowX: "auto" }}>
          <DataTable
            columns={columns}
            data={tableProducts || []}
            striped
            customStyles={tableStyleDefaulInternal}
            paginationComponentOptions={paginationOptions}
            noDataComponent="Nenhum produto adicionado."
          />
        </div>
      </S.WrapperTable>
    </>
  );
}
