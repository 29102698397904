import { useContext } from "react";
import { Row, Form, Col } from "react-bootstrap";
import { CategoryContext } from "../../../../context/category";

export function Printers() {
  const { xdFollowItem, setXdFollowItem, xdPrinters, setXdPrinters } =
    useContext(CategoryContext);

  return (
    <div className="px-4">
      <Row className="mb-4 px-2">
        <Col className="spaceBetween">
          <Form.Switch
            label="Segue Produto Principal"
            onChange={(e: any) => setXdFollowItem(e.target.checked)}
            checked={xdFollowItem}
          />
        </Col>
      </Row>

      <Row className="mb-2 px-2">
        {xdPrinters?.split(",").map((item: any, index: any) => {
          return (
            <Col id="idSwitch" md="4" className="mb-2" key={index}>
              <Form.Switch
                label={`Local de impressão  ${index + 1}`}
                onChange={(e: any) => {
                  const newArray = [...xdPrinters?.split(",")];
                  newArray[index] = e.target.checked ? "1" : "0";
                  setXdPrinters(newArray.join(","));
                }}
                checked={item === "0" ? false : true}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
