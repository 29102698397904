import * as Yup from "yup";

export const bankAccountSchema = Yup.object().shape({
  description: Yup.string().required("Por favor, insira a descrição."),
  agency: Yup.number().required("Por favor, insira a agência."),
  agency_digit: Yup.number().required("Por favor, insira o digito da agência."),
  account: Yup.number().required("Por favor, insira a conta."),
  account_digit: Yup.number().required("Por favor, insira o digito da conta."),
  balance: Yup.number().required("Por favor, insira o saldo."),
  bank: Yup.mixed().test(
    "is-string",
    "Por favor, selecione o selecione o banco",
    function (value) {
      if (!value) {
        return false;
      }

      if (typeof value === "object" && value.value) {
        return true;
      }
      return false;
    },
  ),
});
