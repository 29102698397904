import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const createProfileAsync = createAsyncThunk(
  "create/profile",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.taxProfile, data);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const editeProfileAsync = createAsyncThunk(
  "put/profile",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(globals.api.taxProfile + `/${data?.uuid}`, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

