import { Col, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import { ReactNode } from "react";
import { Btn } from '../../../../data/Btn';


interface ILayout {
  children: ReactNode;
  title?: string;
  onClickAdd?: () => void;
  onClickClear?: () => void;
  onClickAddAlternative?: () => void;
  altBtnText?: string;
  textAdd?: string;
  onClickOpenFilter?: any;
  openFilter?: boolean;
}

export default function LayoutHookFilter({
  children,
  title,
  onClickAdd,
  onClickClear,
  textAdd,
  altBtnText,
  onClickAddAlternative,
  onClickOpenFilter,
  openFilter,
}: ILayout) {
  return (
    <div className={styles.container}>
      <Row>
        <Col className={styles.titleGroup}>
          <h3>{title}</h3>
          <div style={{ display: "flex", gap: "1rem" }}>
            {onClickOpenFilter && (
              <div className={styles.btnFilter}>
                <Btn
                  text="Filtro"
                  type="button"
                  variante="info"
                  icon={"arrow"}
                  onClick={onClickOpenFilter}
                />
              </div>
            )}
            {onClickAddAlternative && (
              <Btn
                text={altBtnText ? altBtnText : ""}
                type="button"
                variante="info"
                icon={"plus"}
                onClick={onClickAddAlternative}
              />
            )}
            {onClickAdd && (
              <Btn
                text={textAdd ? textAdd : "Adicionar"}
                type="button"
                variante="primary"
                icon={"plus"}
                onClick={onClickAdd}
              />
            )}
          </div>
        </Col>
      </Row>
      {children}
      <Row className="mt-2">
        <Col className={styles.btnGourp}>
          <div className={styles.btn}>
            <Btn
              text="Limpar"
              type="button"
              variante="secondary"
              icon="trash"
              onClick={onClickClear}
            />
            <Btn text="Buscar" type="submit" variante="success" icon="search" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
