import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Services/api";
import globals from "../../../../Globals.json";

export const getChartAccountAsync = createAsyncThunk(
  "chartaccount",
  async (storeActivedId: number, thunkAPI) => {
    try {
      const response = await api.get(
        globals.api.chartAccounts +
          `?paginate=false&store_id=${storeActivedId}`,
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createChartAccountsync = createAsyncThunk(
  "create/chartaccount",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.post(globals.api.chartAccounts, data);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateChartAccountsync = createAsyncThunk(
  "update/chartaccount",
  async (data: any, thunkAPI) => {
    try {
      const res = await api.put(
        globals.api.chartAccounts + `/${data.uuid}`,
        data,
      );

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteChartAccountsync = createAsyncThunk(
  "delete/chartaccount",
  async (uuid: string, thunkAPI) => {
    try {
      const res = await api.delete(globals.api.chartAccounts + `/${uuid}`);

      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
