import { useEffect, useState } from "react";
import Select from "react-select";

import * as S from "./styles";

import {
  msgStyles,
  NoOptionsMessage,
} from "../../NoOptionsMessage/no-options-message-component";
import { Controller } from "react-hook-form";

interface IProps {
  label: string;
  options: any;
  isClearable?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange?: any;
  name: string;
  readOnly?: boolean;
  register: any;
  control: any;
  defaultValue?: any;
  errors?: any;
  setValue?: any;
  onchenge?: any;
  label2?:string
}

export function SelectHook({
  options,
  isClearable,
  placeholder,
  label,
  isDisabled,
  name,
  control,
  defaultValue,
  errors,
  setValue,
  onchenge,
                             label2
}: IProps) {
  const errorToDisplay = errors
    ? Object?.keys(errors[0])?.find((key) => key === name)
    : "";

  useEffect(() => {
    if (defaultValue) {
      control.setValue && control.setValue(name, defaultValue);
    }
  }, [defaultValue, name, control]);

  return (
    <div>
      <div style={{fontSize: "10px", textAlign:"end", paddingBottom:"0.2rem", minHeight: "20px"}}>{label2}</div>
      <label>
        <div className="label">{label}</div>
        <S.Input>
          <S.Wrapper>
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <Select
                  className="select"
                  {...field}
                  isClearable={isClearable}
                  placeholder={
                    <div className="placeholderSelect">{placeholder}</div>
                  }
                  defaultValue={defaultValue}
                  options={options}
                  isDisabled={isDisabled}
                  components={{ NoOptionsMessage }}
                  styles={{
                    noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
                    control: (base) => ({
                      ...base,
                      border: errorToDisplay
                        ? "1px solid #F1416C"
                        : "1px solid var(--color-input-border)",
                      boxShadow: "var(--color-input-shadow)",
                      borderRadius: 4,
                      height: 18,
                      display: "flex",
                      alignContent: "center",
                      minHeight: 24.5,
                      textAlign: "left",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary50: "#e4e6ef",
                      primary25: "#e4e6ef",
                      primary75: "#e4e6ef",
                      primary: "#5d98f1e8",
                    },
                  })}
                />
              )}
            />
          </S.Wrapper>
          {errorToDisplay && (
            <S.Error>{errors[0][errorToDisplay]?.message}</S.Error>
          )}
        </S.Input>
      </label>
    </div>
  );
}
